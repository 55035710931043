import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect} from 'react';
import Service from "../../service/service";
import Loader from '../Skeleton/Loader';
import StudentsTab from './Studentstab';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getstudentevents } from '../../store/action';
import Quizservice from '../Quiz/quizservice';


function StudentHome() {
  const dispatch = useDispatch();
  const [programs, setPrograms] = React.useState([]);
  const [assignmentResult, setAssignmentResult] = React.useState([]);
  const [quizResult, setQuizResult] = React.useState([]);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
        const programIds = userDetails.program_ids;
        const programData = [];

        for (const programId of programIds) {
          const response = await new Service().getProgramDetailsById(programId);
          programData.push(response.data);
        }

        setPrograms(programData);
      } catch (error) {
        new Service().handleErrResponse();
        sessionStorage.removeItem("userDetails");
        // Redirect to the login page or handle the error as required
      }
    };

    fetchPrograms();

    const userDetails = sessionStorage.getItem("userDetails");
    const email = JSON.parse(userDetails).data.email;

    new Quizservice()
      .getcompletedassignment(email)
      .then((response) => {
        setAssignmentResult(response.data);
      })
      .catch((error) => {
        console.error("Error fetching assignment results:", error);
      });

    new Quizservice().getstudentquizres(email)
      .then((response) => {
        setQuizResult(response);
      })
      .catch((error) => {
        console.error("Error fetching quiz results:", error);
      });

    dispatch(getstudentevents(email));
  }, [dispatch]);

  const events = useSelector((state) => state.Stulist.studentevents);

  if ((programs === undefined) || (quizResult === undefined) || (assignmentResult === undefined) || events === undefined) {
    return <Loader />;
  }

  


 


  return (
    <Box component="div" sx={{ mt: 1, mb: 3 }}>
      <Grid container columns={12} spacing={3} >
           <Grid item xs={12} sm={6} md={3}>
    
                <Card sx = {{p:1,border:"1px solid #d4dbf3",cursor:"pointer", width:"100%", minHeight:100,

          ':hover': {
            bgcolor: '#fafbfd',
           },
            '&:active': {
                boxShadow: '#d4dbf3',
                // backgroundColor: '#0062cc',
                // borderColor: '#f95877',
              },
              '&:focus': {
                boxShadow: '#d4dbf3',
              },
              
              
              }} elevation = {2}>
               
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                    <IconButton sx = {{mr:.5,ml:-.5}} ><img  src="/assets/images/student/pr5.svg" alt="PR5 Icon" width = {"40px"} height = {"40px"} />  </IconButton>

                    <Stack direction = "column" spacing = {0} sx = {{mt:.5}}>
                    <Typography  sx = {{fontWeight:500,
                    fontSize: "17px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
    
    }}>Enrolled Programs</Typography>
                    <Typography sx = {{fontWeight:800,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins",
                    ml:.5
    
    }}>{programs.length}</Typography>
     

                  </Stack>
                  </Box>

                </Card>
                </Grid>
               <Grid item xs={12} sm={6} md={3}>

                
              
                <Card sx = {{p:1,border:"1px solid #d4dbf3",cursor:"pointer",width:"100%", minHeight:100,

          ':hover': {
            bgcolor: '#fafbfd',
           },
            '&:active': {
                boxShadow: '#d4dbf3',
                // backgroundColor: '#0062cc',
                // borderColor: '#f95877',
              },
              '&:focus': {
                boxShadow: '#d4dbf3',
              },
              
              
              }} elevation = {2}>
               
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                    <IconButton sx = {{mr:.5,ml:-.5}} ><img  src="/assets/images/student/pr4.svg" alt="PR5 Icon" width = {"40px"} height = {"40px"} />  </IconButton>

                    <Stack direction = "column" spacing = {0} sx = {{mt:.5}}>
                    <Typography  sx = {{fontWeight:500,
                    fontSize: "17px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
    
    }}>Completed Quiz</Typography>
                    <Typography sx = {{fontWeight:800,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins",
                    ml:.5
    
    }}>{quizResult.length}</Typography>
     

                  </Stack>
                  </Box>

                </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
             
                <Card sx = {{p:1,border:"1px solid #d4dbf3",cursor:"pointer",width:"100%", minHeight:100,

          ':hover': {
            bgcolor: '#fafbfd',
           },
            '&:active': {
                boxShadow: '#d4dbf3',
                // backgroundColor: '#0062cc',
                // borderColor: '#f95877',
              },
              '&:focus': {
                boxShadow: '#d4dbf3',
              },
              
              
              }} elevation = {2}>
               
                  <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'flex-start'}}>
                    <IconButton sx = {{mr:.5,ml:-.5}} ><img  src="/assets/images/programs/pr2.svg" alt="PR5 Icon" width = {"40px"} height = {"40px"} />  </IconButton>

                    <Stack direction = "column" spacing = {0} sx = {{mt:.5}}>
                    <Typography  sx = {{fontWeight:500,
                    fontSize: "17px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins",
    
    }}>Completed Assignment</Typography>
                    <Typography sx = {{fontWeight:800,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins",
                    
                    
    
    }}>{assignmentResult.length}</Typography>
     

                  </Stack>
                  </Box>

                </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>

                
             
              
                <Card sx = {{p:1,border:"1px solid #d4dbf3",cursor:"pointer",width:"100%", height: 100,

          ':hover': {
            bgcolor: '#fafbfd',
           },
            '&:active': {
                boxShadow: '#d4dbf3',
                // backgroundColor: '#0062cc',
                // borderColor: '#f95877',
              },
              '&:focus': {
                boxShadow: '#d4dbf3',
              },
              
              
              }} elevation = {2}>
               
                  <Box sx={{ display: 'flex', flexDirection: 'row',alignItems: 'flex-start' }}>
                    <IconButton sx = {{mr:.5,ml:-.5}} ><img  src="/assets/images/programs/cds.svg" alt="PR5 Icon" width = {"40px"} height = {"40px"} />  </IconButton>

                    <Stack direction = "column" spacing = {0} sx = {{mt:.5}}>
                    <Typography  sx = {{fontWeight:500,
                    fontSize: "17px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
    
    }}>Events</Typography>
                    <Typography sx = {{fontWeight:800,
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#1f252b",
                    fontFamily: "Poppins",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ml:.5
    
    }}>{events.length}</Typography>
     

                  </Stack>
                  </Box>

                </Card>
                </Grid>

                
              

            {/* ))} */}
           
            <StudentsTab/>
            </Grid>
        </Box>

        

   
  
  )
}


// const mapStateToProps = (state) => {
//   return{carddata:state.instructordashboard}
// }
// const page = "/instructor"
export default StudentHome;