import React from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { QUIZDB_URL } from "../constants/global";


export default function TextBoxQuiz({ questNo,quickCheckId,quiz,timeEnded}) {


  const [open, setOpen] = React.useState(false);
  const [answer, setAnswer] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);


  const answerId =  quiz.answer[0].answerId
  const questionId =  quiz.id
  console.log("short answer answer",answer)



  const handleChange = (event) => {
    setAnswer(event.target.value);
    setOpenAlert(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpenAlert(false);

  };


  const createQuizMark = () => {

    if (!answer) {
      setOpenAlert(true);
      }

    
else{

    setOpen(true);

    var data = new FormData();
    data.append('quickCheckId', quickCheckId);
    data.append('questionId', questionId);
    data.append('answerId', answerId);
    data.append('answer', answer);
    // data.append('correct',correct);
    
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: QUIZDB_URL+'/quizMarks',
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });

  };
}



  return (
    <div>
      <Grid container columns={12}>
        <Grid item sm={1} md={1} lg={1}>
          <Typography variant="h6">Q.{questNo}</Typography>
          {/* Question no */}
        </Grid>

        <Grid item sm={11} md={11} lg={11}>
          {/* Question content*/}
          <Box sx={{ p: 3, mb: 3, borderRadius: 2, bgcolor: "#f6f8ff" }}>
            <Stack direction="column" spacing={1}>
              <Typography variant="body1">{quiz.description}</Typography>
              <Typography variant="body1">{quiz.question}</Typography>
              <Typography variant="body2">Type your answer here:</Typography>
              {quiz.image? (<Box
                component="img"
                sx={{

                  pt:2,

                  height: 233,
                  width: 350,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                alt="The house from the offer."
                src={quiz.image}
                />) :null}

            <Typography variant="body1" sx = {{color:'text.secondary'}}>{quiz.anstext}</Typography>
            <Box
            component="form"
            sx={{
                '& > :not(style)': {  width: '60ch' },
                input: { bgcolor: 'white' },
            }}
            noValidate
            autoComplete="off"
            >
            <TextField id="outlined-basic"  variant="outlined" disabled = {timeEnded | open}
  
            onChange = {handleChange}
         
            />

            </Box>

            <Button variant = "contained"  disabled = {timeEnded | open}

             sx={{maxWidth: '70px', maxHeight: '35px', minWidth: '70px', minHeight: '35px',
             
             bgcolor:"#f95877" ,color: 'white',':hover': {
              bgcolor: '#f95877',
              color: 'white', }
             }}
             onClick = {createQuizMark}
             
             >
              
              <Typography variant = "body2"  sx= {{fontSize:18,textTransform: 'none',color:"white"}}>Check</Typography>
              
              </Button>
            </Stack>

          </Box>

           {/* alert */}
           <Box >
            <Snackbar anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                  }} open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                      <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
                          Please Choose The Answer!
                      </Alert>
                  </Snackbar>
          </Box>

        </Grid>
      </Grid>
    </div>
  );
}
