import React from "react";
import { Grid } from "@mui/material";
import Config from "../../components/Global";
import ViewHeader from "./ViewHeader";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom'
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import { AITADB_URL } from "../constants/global";

export default function PdfViewer() {
  const location = useLocation();
  const [pdffile,setpdffile] = React.useState(null);
  const { coursename } = location.state
  const {imageid} = location.state
  console.log("file",pdffile)
  
  useEffect(() => {
    if (location.state !== undefined) {
      const { assetid } = location.state
      
  
    async function fetchPdfData() {
      // Make a request to your server to fetch the PDF data from MySQL
      const response = await fetch(`${AITADB_URL}/sections/asset/${assetid}`, {headers :{
              'Authorization': `Bearer ${Config.getToken()}`
            }});
      const data = await response.arrayBuffer();
      setpdffile(data);
    }

    fetchPdfData();
  }
  }, [location.state]);
  

  
        
          // .catch((err: AxiosError) => {
          //   // process error
          // });
  
  
    
  

    return (
      <main>
        <AdminNavbar menu="mycourse" navbar="" />
      <div >
        <ViewHeader coursename = {coursename} imageid={imageid}/>
        <Grid container columns={12} spacing={1} sx = {{mt:5,mb:5,pl:16}}>
        <Grid item md={1} lg={1}></Grid>
        <Grid item md={10} lg={10}>
          {pdffile  &&
          <iframe
            title="file"
            type="application/pdf"
            width="100%"
            height="600px"
            style={{"overflow": "auto"}}
            src={`data:application/pdf;base64,${btoa(
              String.fromCharCode.apply(null, new Uint8Array(pdffile))
            )}`}
            
            >
         </iframe>}
        </Grid>
        </Grid>
        
        
      </div>
      </main>
    );
  }