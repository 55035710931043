import React from "react";
import { Link } from "react-router-dom";
import "../../components/Header/Header.scss";
import "../../styles/Link.scss";
import "./AdminHeader.scss";
import { FaUserCircle } from 'react-icons/fa';
class AdminHeader extends React.Component {
    constructor(props) {
        super(props);
        this.onLogout = () => {
            sessionStorage.removeItem("userDetails");
            sessionStorage.removeItem("token");
            this.props.history.push("/");
        };
        this.props = props;
    }
    render() {
        return (React.createElement("header", { className: "" },
            React.createElement("div", { className: "logo-part" },
                React.createElement("div", { className: "container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 col-xl-12 d-flex flex-column flex-md-row justify-content-between align-items-md-center" },
                            React.createElement("div", { className: "header-logo d-flex justify-content-between align-items-center" },
                                React.createElement(Link, { className: "header-logo__img", to: "#" },
                                    React.createElement("img", { src: "assets/images/header/company-logo1.svg" })),
                                React.createElement("div", { className: "sidenav-btn sidenav-btn--dashboard d-inline-block d-md-none" },
                                    React.createElement("span", { className: "sidenav-btn__item" }),
                                    React.createElement("span", { className: "sidenav-btn__item" }),
                                    React.createElement("span", { className: "sidenav-btn__item" }))),
                            React.createElement("div", { className: "header-search d-inline-flex align-items-center", style: { paddingRight: "14px" } },
                                sessionStorage.getItem("userDetails") &&
                                    React.createElement("ul", { className: "dashboard-head-links mb-0 d-none d-md-flex align-items-center flex-wrap clearfix" },
                                        React.createElement("li", { className: "menu-part-list__item float-md-left" },
                                            React.createElement(FaUserCircle, { className: "userProfileIcon" }),
                                            React.createElement("div", { className: "dropdown-nav" },
                                                React.createElement("ul", { className: "menu-dropdown mb-0" },
                                                    React.createElement(Link, { to: "/myprofile" },
                                                        React.createElement("li", { className: "menu-dropdown__item" }, "My Profile")),
                                                    React.createElement("li", { style: { marginTop: '.75rem' }, className: "menu-dropdown__item" }, "Change Password"),
                                                    React.createElement("li", { className: "menu-dropdown__item", onClick: this.onLogout }, "Logout"))))),
                                React.createElement("div", { className: "sidenav-btn sidenav-btn--dashboard d-none d-md-inline-block ml-4 d-lg-none" },
                                    React.createElement("span", { className: "sidenav-btn__item" }),
                                    React.createElement("span", { className: "sidenav-btn__item" }),
                                    React.createElement("span", { className: "sidenav-btn__item" })))))))));
    }
}
export default AdminHeader;
