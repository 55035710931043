import { LogLevel } from "@azure/msal-browser";

const AzureActiveDirectoryAppClientId = "596cbf6a-8bdd-4218-bb6b-cd1ed94d44fc";

export const MSAL_CONFIG = {
  auth: {
    clientId: AzureActiveDirectoryAppClientId,
    authority: "https://login.microsoftonline.com/97c9d77f-a9d1-49bd-a12a-9d2548e8fb73"
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            console.log(message);
            break;
        }
      }
    }
  }
};
