import React, { useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from '@mui/material/Pagination';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import "../../pages/Dashboard/Dashboard.scss";
import Service from "../../service/service";
import Breadcrums from "../Breadcrums/Breadcrums";
import Quizservice from "../Quiz/quizservice";
import { QUIZDB_URL } from "../constants/global";

function Assignment() {
  const history = useHistory();
  const location = useLocation();

  const [courseId, setCourseId] = React.useState();


  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [assignmentdata, setAssignmentdata] = React.useState([]);

  const [contentdata, setcontentdata] = React.useState([]);
  const [assignmentStatus, setAssignmentStatus] = React.useState();
  const [assignmentid, setAssignmentId] = React.useState();
  const [workSpace, setWorkSpace] = React.useState();
  const [fileName, setFileName] = React.useState();
  const [fileLink, setFileLink] = React.useState();
  const[emptyTextAlert,setEmptyTextAlert] = React.useState(false);
  const[Loading,setLoading] = React.useState(false);
  const[alert,setAlert] = React.useState(false);
  const[errorData,setErrorData] = React.useState('');
  const[errorAlert,setErrorAlert] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(5);
  const [filteredData, setFilteredData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isGDriveStorage, setIsGDriveStorage] = React.useState(false);


  


  var userDetails  = sessionStorage.getItem("userDetails");
  const email = JSON.parse(userDetails).data.email

  const breadcrums = [
    {
      page: "HOME",
      route: "/dashboard"
    },
    {
      page: "My Courses",
      route: "/Courses"
    },
    {
      page: "Course Preview",
      route: "/coursepreview"
    },
    {
      page: "Assignment",
      route: "/assignment"
    }
    
  ]

  // const breadcrums = ["Home", "Assignment"];



  
 
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const AssignmentStatusHandler = () => {

    if (location.state !== undefined) {
    const formData = new FormData();
    formData.append('assignmentid', assignmentid);
    const { courseid } = location.state;
    formData.append('courseid', courseid);
    formData.append('user_emailid', email);
    const params = new URLSearchParams(formData);
    fetch(`${QUIZDB_URL}/checkassignment?${params}`)
    .then(response => response.json())
    .then(data => setAssignmentStatus(data['result']))
    .catch(error => console.error(error));
    }

  }


  

  useEffect(() => {
    setLoading(true);
    if (location.state !== undefined) {
      
      const { courseid } = location.state;
      setCourseId(courseid)
      // new Quizservice().getassignment(courseitem.id).then((response) => {
      new Quizservice().getassignment(courseid).then((response) => {
        setAssignmentdata(response.data);
        // setAssignmentId(response.data[0].id);
        setLoading(false);

      });
      // AssignmentStatusHandler()
 
    }
  }, [location.state]);

  useEffect(() => {
    filterData();
  }, [assignmentdata, searchTerm]);

  // Get the paginated data based on the current page
  // const paginatedData = assignmentdata.slice(indexOfFirstItem, indexOfLastItem);
  const paginatedData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterData = () => {
    const filtered = assignmentdata.filter((data) =>
      data.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset current page to 1 when search term changes
  };

  const handleWorkSpaceText = (event) => {
    setWorkSpace(event.target.value);
  };
  const handleFileNameText = (event) => {
    setFileName(event.target.value);
  };

  const handleFileLinkText = (event) => {
    setFileLink(event.target.value);
  };
  

  const SubmitAssignmentHandler = () => {

    if (!fileName){
      setEmptyTextAlert(true)
    }

    else{

        setLoading(true);

        // Create an object of formData
        const formData = new FormData();
        if (isGDriveStorage) {
            if (!fileLink){
              setEmptyTextAlert(true)
            }
            else{
            
            formData.append('workspace_name', 'Google');
            formData.append('storage_type', "Google Drive");
            formData.append('drive_link', fileLink);
          }
        }
        else{
              if (!workSpace){
                setEmptyTextAlert(true)
              }
              else{
              formData.append('workspace_name',workSpace);
              formData.append('storage_type', "Azure");
              formData.append('drive_link', ' ');
              }
          }
        
        formData.append('assignmentid', assignmentid);
        formData.append('courseid', courseId);
        formData.append('user_emailid', email);
        formData.append('file_name', fileName);
        formData.append('score', '0');

        
        
        new Quizservice().submitAssignment(formData).then(
        
            (response) => {
                        console.log("response",response);
                        AssignmentStatusHandler()

                        setLoading(false);
                        setOpen(false);
                        setAlert(true);
                        setIsGDriveStorage(false)

            }
        ).catch((error) => {
                        setErrorData(error.response.data.error)
                        setErrorAlert(true);
                        setLoading(false);
                        console.log("failed to Submit the Assignment")
                        });

    }

   


};
  

  const handleClickcodeboard = () => {
          
    window.open('https://code.aitahub.com/', '_blank');
  };

  const handleChange = (panel, id) => (event, isExpanded) => {
     setLoading(true);
    setAssignmentId(id)
    

    if (location.state !== undefined) {
      const formData = new FormData();
      formData.append('assignmentid', id);
      const { courseid } = location.state;
      formData.append('courseid', courseid);
      formData.append('user_emailid', email);
      const params = new URLSearchParams(formData);
      fetch(`${QUIZDB_URL}/checkassignment?${params}`)
      .then(response => response.json())
      .then(data => setAssignmentStatus(data['result']))
      .catch(error => console.error(error));
      }

    new Service()
      .getcontentfileById(id)
      .then((response) => {
        console.log("response", response);
        var result = response.data.split("\n");

        // newArray.push(result)

        setcontentdata(result);
        setExpanded(isExpanded ? panel : false);
        setLoading(false);
      
      })
      .catch((res) => {
        if ((res + "").includes("401")) history.push("/");
      });
  };

  const handleOpenDialog = () => {
    setWorkSpace();
    setFileName();
    setFileLink();
    setIsGDriveStorage(false)
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  
  const handleChangemethod = (event) => {
    if(event.target.value === 'a'){
    setIsGDriveStorage(true);
    }else{
      setIsGDriveStorage(false);
    }
};
  

  return (
    <main>
      <AdminNavbar menu="mycourse" navbar="" />

      <div
        className="container"
        style={{
          paddingBottom: "26px",
          paddingTop: "15px",
          paddingLeft: "54px",
        }}
      >
        <div className="row">
          {/* main grid */}
          <Grid
            container
            minWidth="fit-content"
            display="flex-start"
            sx={{ pt: "2rem" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                marginLeft: "1rem",
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                },
              }}
            >
              <Typography
                variant="button"
                sx={{ lineHeight: 1.5, fontSize: "1.55rem", fontWeight: 400 }}
              >
                Assignment
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                marginLeft: "1rem",
                mb: 2,
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                },
              }}
            >
              <Breadcrums pages={breadcrums} courseid= {courseId} />
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  mb: 2,
                  mt:1,
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "left",
                    lg: "left",
                  },
                }}
              >
                <Button
                  sx={{ 
                    ml: 2,
                    
                  }}
                  onClick={handleClickcodeboard}
                >
                  Playground
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  mb: 2,
                  mt:1,
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "right",
                    lg: "right",
                  },
                }}
              >
                <input type="text" placeholder="Search by name..." value={searchTerm} onChange={handleSearchChange} />
              </Grid>
            </Grid>

            
            {assignmentdata && paginatedData &&
              paginatedData.map((data, id) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    marginLeft: "1rem",
                    mb: 0,
                  }}
                  key={id}
                >
                  <div>
                    <Accordion
                      expanded={expanded === id}
                      onChange={handleChange(id, data.id)}
                      sx={{ mb: 3, mt: 1 }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          variant="caption"
                          sx={{ color: "text.secondary", fontWeight: 700 }}
                        >
                          {data.name}
                        </Typography>
                      </AccordionSummary>

                      {contentdata &&
                        contentdata !== "" &&
                        contentdata.map((subheader, index) => (
                          <AccordionDetails key={index}>
                            {subheader !== "" &&
                              subheader !== '"' &&
                              subheader !== "\r" && (
                                <Stack
                                  justifyContent="space-between"
                                  alignItems="center"
                                  direction="row"
                                  spacing={2}
                                >
                                  <li>
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "#afb1b3", fontWeight: 800 }}
                                    >
                                      {subheader}
                                    </Typography>
                                  </li>
                                </Stack>
                              )}
                          </AccordionDetails>
                        ))}
                  <Grid container sx = {{mt:1,mb:2}}>
                      <Grid align="right" item xs={6} sm={6} md={6} lg={6} >
                        <Link
                        to={{ pathname: '/coursepreview', state: { courseid:courseId } }}
                          style={{ textDecoration: "none", color: "#ffffff" }}
                        >
                          <Button sx={{ mr: 1 }}>Cancel</Button>
                        </Link>
                      </Grid>
                      <Grid align="left" item xs={6} sm={6} md={6} lg={6} >
                        <Button sx={{ 
                            ml:1,
                            color: assignmentStatus ? 'gray':'', 
                            backgroundColor: assignmentStatus ? 'lightgray' : '',
                            '&:hover': {
                              backgroundColor: assignmentStatus ? 'lightgray' : '',
                            },
                          }}
                        
                          onClick={handleOpenDialog} disabled = {assignmentStatus}>
                          {assignmentStatus?"Completed":"Complete"}
                        </Button>
                      </Grid>

                    </Grid>
                    </Accordion>
                  </div>
                </Grid>
              ))}


          </Grid>

          <Dialog
            PaperProps={{
              style: {
                minWidth: '800px',
                // minHeight: '200px',
              },
            }}
            open={open}
            onClose={handleCloseDialog}
            id="content"
          >
            <DialogTitle>Assignment</DialogTitle>
            <DialogContent dividers>
              <Box
                // sx={{
                //   flexGrow: 1,
                //   pl: 8,
                //   pr: 8,
                //   pt: 4,
                //   pb: 4,
                //   m: 0,
                //   mb: 1,

                // }}
              >
                <Grid container spacing={1} columns={12} sx={{ p:1,mt:0,mb:4}}>
                  <Grid item  xs={12} sm={12} md={12} lg={12} alignItems="center" 
                  sx = {{display:'flex',p:1}}>
                    <Grid item>
                        <Typography  >
                                Paste file link from external storage?
                        </Typography>
                        </Grid>
                    <Grid item>

                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue="b"
                                checked={isGDriveStorage}
                                onChange={handleChangemethod}
                            >
                                <FormControlLabel  value="a" control={<Radio />} label="Yes" labelPlacement="start" />
                                <FormControlLabel value="b" control={<Radio />} label="No" labelPlacement="start" />
                                
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                </Grid>

                            

                  {
                  isGDriveStorage ? 
                  <>
                   <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography sx={{ mt: 2 }}>File Link : </Typography>
                  </Grid>

                  <Grid item xs={9} sm={9} md={9} lg={9} sx = {{mb:2}}>
                    <TextField fullWidth value = {fileLink} onChange={handleFileLinkText} />
                  </Grid> 
                  </>
                  :
                  <>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography sx={{ mt: 2 }}>Workspace Name : </Typography>
                  </Grid>

                  <Grid item xs={9} sm={9} md={9} lg={9} sx = {{mb:2}}>
                    <TextField fullWidth value = {workSpace} onChange={handleWorkSpaceText} />
                  </Grid> 

                  </>
                 
                  }
                  
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography sx={{ mt: 2 }}>File Name : </Typography>
                  </Grid>

                  <Grid item xs={9} sm={9} md={9} lg={9}>
                    <TextField fullWidth value = {fileName} onChange={handleFileNameText} />
                  </Grid>
                 
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <>
                <Button onClick = {SubmitAssignmentHandler}>Save</Button>
                <Button onClick={handleCloseDialog}>Cancel</Button>
              </>
            </DialogActions>
          </Dialog>


          <Dialog 
                        color="success"
                        PaperProps={{
                            style: {
                           
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={Loading} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                        <CircularProgress sx={{justifyContent:'center',alignItem:'center',ml:'2rem'}} />
                        <h4 style={{color:"#fff"}}>Loading...</h4>
                        </DialogContent>
                        </Dialog>

          <Snackbar anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }} open={alert} autoHideDuration={6000} onClose={(e) => setAlert(false)}>
                            <Alert onClose={(e) => setAlert(false)} severity = "success" >
                                Assignment Submitted Successfully!
                            </Alert>
                        </Snackbar>

                        <Snackbar anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }} open={emptyTextAlert} autoHideDuration={6000} onClose={(e) => setEmptyTextAlert(false)}>
                            <Alert onClose={(e) => setEmptyTextAlert(false)} severity = "warning" >
                                Field's can't be Empty!
                            </Alert>
                        </Snackbar>

                        <Snackbar anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }} open={errorAlert} autoHideDuration={6000} onClose={(e) => setErrorAlert(false)}>
                            <Alert onClose={(e) => setErrorAlert(false)} severity = "error" >
                                {errorData}
                            </Alert>
                        </Snackbar>

        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Pagination
              count={Math.ceil(filteredData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
        </div>
      </div>
    </main>
  );
}

export default Assignment;