import React from "react";
import "./Header.scss";
import "../../styles/Link.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { PublicClientApplication, BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser";
import { MSAL_CONFIG } from "../../azure/azure-authentication-config";
import Config from "../Global";
import Service from "../../service/service";
import { FaUserCircle } from 'react-icons/fa';
export default class Header extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    constructor(props) {
        super(props);
        this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
        this.accessTokenRequest = {
            scopes: ["user.read"],
            account: undefined
        };
        this.state = {
            active: "hide",
            activeNavBar: "hide"
        };
        this.onLogout = () => {
            sessionStorage.removeItem("userDetails");
            sessionStorage.removeItem("loggedIn");
            sessionStorage.removeItem("token");
            this.props.history.push("/");
        };
        this.onAccessToken = this.onAccessToken.bind(this);
    }
    setActive(value) {
        this.setState({ active: value });
        this.setState(this.state);
    }
    setNavBarActive(value) {
        this.setState({ activeNavBar: value });
        this.setState(this.state);
        if (this.state.activeNavBar === "show") {
            window.scrollTo(0, 0);
        }
    }
    onAccessToken(accessTokenResponse) {
        Config.setToken(accessTokenResponse);
        new Service().getUserByEmail(Config.getClaims().email).then((res) => {
            var userDetails = { data: res.data, program_ids: [] };
            res.data.program_list.forEach((ele) => { userDetails.program_ids.push(ele.id); });
            sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
            sessionStorage.setItem("loggedIn", 'true');
            this.props.history.push(userDetails.program_ids.length > 0 ? "/courses" : "programs");
        });
    }
    showLogin() {
        this.myMSALObj.acquireTokenSilent(this.accessTokenRequest).then(this.onAccessToken).catch((error) => {
            console.log("Silent failed : " + JSON.stringify(error));
            if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
                this.myMSALObj.acquireTokenPopup(this.accessTokenRequest).then(this.onAccessToken).catch(function (error) {
                    console.log(error);
                });
            }
        });
    }
    getProgramGroups() {
        return new Service().getCategories();
    }
    onSelectProgramCategory(event) {
        console.log(event.target.value);
    }
    render() {
        return (React.createElement("span", null,
            React.createElement("header", { className: "" },
                React.createElement("div", null,
                    React.createElement("div", { className: "logo-part" },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-12 d-flex  flex-md-row justify-content-between align-items-md-center", style: { width: "100%" } },
                                    React.createElement("div", { className: "header-logo d-flex justify-content-between align-items-center" },
                                        React.createElement(Link, { className: "header-logo__img", to: "/" },
                                            React.createElement("img", { src: "assets/images/header/company-logo1.svg" }))),
                                    React.createElement("div", { className: "header-search d-inline-flex align-items-center" },
                                        React.createElement("div", { className: "header-search__input d-none d-md-inline-flex align-items-center ml-auto" },
                                            React.createElement("img", { className: "img-fluid mr-2", src: "assets/images/header/header-search.svg" }),
                                            React.createElement("input", { type: "search", placeholder: "Search Popular Careers & Programs" })),
                                        (sessionStorage.getItem("userDetails") == null) &&
                                            React.createElement("div", null,
                                                React.createElement("div", { className: "btn btn--skew d-none d-md-inline-block ml-3", onClick: () => this.showLogin() }, "Login"),
                                                React.createElement(Link, { className: "btn btn--skew d-none d-md-inline-block ml-3", to: "/signup" }, "Signup")),
                                        React.createElement(Link, { className: "btn btn--skewBrd d-none d-md-inline-block ml-3 c-color", to: "/programs" },
                                            "Programs",
                                            React.createElement("img", { className: "img-brd", src: "assets/images/header/brd.png" })),
                                        sessionStorage.getItem("userDetails") != null &&
                                            React.createElement(React.Fragment, null,
                                                React.createElement(Link, { className: "btn btn--skew active-skew d-none d-md-inline-block ml-3 ", to: "/courses" },
                                                    "My Courses",
                                                    React.createElement("img", { className: "img-brd", src: "assets/images/header/brd.png" })),
                                                React.createElement("ul", { className: "dashboard-head-links mb-0 d-none d-md-flex align-items-center flex-wrap clearfix" },
                                                    React.createElement("li", { className: "menu-part-list__item float-md-left" },
                                                        React.createElement(FaUserCircle, { className: "userProfileIcon" }),
                                                        React.createElement("div", { className: "dropdown-nav" },
                                                            React.createElement("ul", { className: "menu-dropdown mb-0" },
                                                                React.createElement("li", { className: "menu-dropdown__item" }, "My Profile"),
                                                                React.createElement("li", { className: "menu-dropdown__item" }, "Change Password"),
                                                                React.createElement("li", { className: "menu-dropdown__item", onClick: this.onLogout }, "Logout")))))),
                                        React.createElement("div", { className: classNames("sidenav-btn d-inline-block", { "active": this.state.activeNavBar === "show" }), onMouseEnter: () => this.setActive("show"), onClick: () => { this.state.activeNavBar === "hide" ? this.setNavBarActive("show") : this.setNavBarActive("hide"); } },
                                            React.createElement("span", { className: "sidenav-btn__item" }),
                                            React.createElement("span", { className: "sidenav-btn__item" }),
                                            React.createElement("span", { className: "sidenav-btn__item" })),
                                        this.state.active === "show" &&
                                            React.createElement("div", { className: "desktop-menu open" },
                                                React.createElement("div", { className: "close-events float-right ml-auto", onClick: () => this.setActive("hide") }),
                                                React.createElement("ul", { className: "desktop-menu-list" },
                                                    React.createElement("li", { className: "desktop-menu-list__item" },
                                                        React.createElement(Link, { className: "menu-text fnt-24 fw-700", to: "/community" }, "AI Community"),
                                                        React.createElement("div", { className: "menu-side-nav" },
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/community" }, "Train & Hire"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/community" }, "Impact"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/community" }, "Initiatives"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/partners" }, "Partners"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/community" }, "Scholarships"))),
                                                    React.createElement("li", { className: "desktop-menu-list__item" },
                                                        React.createElement(Link, { className: "menu-text fnt-24 fw-700", to: "/partners" }, "Institutions and Partners"),
                                                        React.createElement("div", { className: "menu-side-nav" },
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/partners" }, "Digitech Skills Lab"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/partners" }, "Programs"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/partners" }, "Success Stories"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/partners" }, "Partners"))),
                                                    React.createElement("li", { className: "desktop-menu-list__item" },
                                                        React.createElement(Link, { className: "menu-text fnt-24 fw-700", to: "/about" }, "About AitaHub"),
                                                        React.createElement("div", { className: "menu-side-nav" },
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/about" }, "AI Board"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/about/" }, "Mentors"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/about/" }, "Why Aitahub"),
                                                            React.createElement(Link, { className: "menu-side-nav__link fnt-20 fw-700", to: "/about/" }, "Our Impact")))))))))))),
            React.createElement("div", { className: classNames("d-md-flex menu-part", { "d-none": this.state.activeNavBar !== "show" }), style: { top: "78px" } },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row justify-content-end", style: { alignItems: "center" } },
                        React.createElement("div", { className: "explore-programs col-2 btn btn--skew d-none d-md-inline-block" },
                            React.createElement("ul", { style: { padding: "0px", margin: "0px" } },
                                React.createElement("li", { className: "menu-part-list__item float-md-left" },
                                    React.createElement(Link, { to: "/student", className: "dropdown-head custom-head", style: { pointerEvents: "none" } }, "Explore Programs"),
                                    React.createElement("div", { className: "dropdown-nav" },
                                        React.createElement("ul", { className: "menu-dropdown mb-0", style: { height: "400px", overflowY: "scroll" } }, this.getProgramGroups().map((category, index) => (React.createElement("li", { className: "menu-dropdown__item", key: index },
                                            React.createElement(Link, { to: { pathname: "/programs", state: { selectedCategory: category } } }, category.name))))))))),
                        React.createElement("div", { className: "col-10 text-md-right" },
                            React.createElement("ul", { className: "menu-part-list clearfix d-none d-md-inline-flex flex-column flex-md-row mb-0 ml-auto" },
                                React.createElement("li", { className: "menu-part-list__item float-md-left" },
                                    React.createElement(Link, { to: "/student", className: "dropdown-head" }, "Students"),
                                    React.createElement("div", { className: "dropdown-nav" },
                                        React.createElement("ul", { className: "menu-dropdown mb-0" },
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/student" }, "Careers")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/student" }, "Programs")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/student" }, "Placement")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/student" }, "Skills")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/student" }, "Career Support"))))),
                                React.createElement("li", { className: "menu-part-list__item float-md-left" },
                                    React.createElement(Link, { to: "/professional", className: "dropdown-head" }, "Professionals"),
                                    React.createElement("div", { className: "dropdown-nav" },
                                        React.createElement("ul", { className: "menu-dropdown mb-0" },
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/professional" }, "Careers")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/professional" }, "Specialists")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/professional" }, "Programs")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/professional" }, "Placement")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/professional" }, "Skills")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/professional" }, "Career Support"))))),
                                React.createElement("li", { className: "menu-part-list__item float-md-left" },
                                    React.createElement(Link, { to: "/companies", className: "dropdown-head" }, "Companies"),
                                    React.createElement("div", { className: "dropdown-nav" },
                                        React.createElement("ul", { className: "menu-dropdown mb-0" },
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/companies" }, "Hire AI Talents")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/companies" }, "Talent Management")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/companies" }, "Start-up Solutions")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/companies" }, "DigiTech Solutions")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/companies" }, "R&D 5.0"))))),
                                React.createElement("li", { className: "menu-part-list__item float-md-left" },
                                    React.createElement(Link, { to: "/aitahack", className: "dropdown-head" }, "Aitahack"),
                                    React.createElement("div", { className: "dropdown-nav" },
                                        React.createElement("ul", { className: "menu-dropdown mb-0" },
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/aitahack" }, "Aitians")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/aitahack" }, "Aitathons")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/aitahack" }, "Data Hack")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/aitahack" }, "AI Practice")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/aitahack" }, "Blog")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/aitahack" }, "Tools & Resources"))))),
                                React.createElement("li", { className: "menu-part-list__item float-md-left" },
                                    React.createElement(Link, { to: "/engine", className: "dropdown-head" }, "Aitaengine"),
                                    React.createElement("div", { className: "dropdown-nav" },
                                        React.createElement("ul", { className: "menu-dropdown mb-0" },
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/engine" }, "Job Readiness Bot")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/engine" }, "All Tools")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/engine" }, "Hiring Tools"))))),
                                React.createElement("li", { className: "menu-part-list__item float-md-left" },
                                    React.createElement(Link, { to: "/contact-us", className: "dropdown-head" }, "Support"),
                                    React.createElement("div", { className: "dropdown-nav" },
                                        React.createElement("ul", { className: "menu-dropdown mb-0" },
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/contact-us" }, "Learn")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/contact-us" }, "Aita Help")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/contact-us" }, "Career Support")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/contact-us" }, "Scholarships")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/contact-us" }, "Hire")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/contact-us" }, "Train")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/contact-us" }, "Pledge")),
                                            React.createElement("li", { className: "menu-dropdown__item" },
                                                React.createElement(Link, { to: "/contact-us" }, "Partner")))))),
                            React.createElement("div", { className: "learn d-md-none" },
                                React.createElement("div", { className: "header-search__input  d-inline-flex align-items-center ml-auto" },
                                    React.createElement("img", { className: "img-fluid mr-2", src: "assets/images/header/header-search.svg" }),
                                    React.createElement("input", { type: "search", placeholder: "Search Carrers" })),
                                React.createElement("ul", { className: "menu-part-list px-3 pt-5" },
                                    React.createElement("li", { className: "menu-part-list__item pl-0", onClick: () => this.setNavBarActive("hide") },
                                        React.createElement(Link, { className: "dropdown-head", to: "/student" }, "Student")),
                                    React.createElement("li", { className: "menu-part-list__item pl-0", onClick: () => this.setNavBarActive("hide") },
                                        React.createElement(Link, { className: "dropdown-head", to: "/professional" }, "Professional")),
                                    React.createElement("li", { className: "menu-part-list__item pl-0", onClick: () => this.setNavBarActive("hide") },
                                        React.createElement(Link, { className: "dropdown-head", to: "/companies" }, "Companies")),
                                    React.createElement("li", { className: "menu-part-list__item pl-0", onClick: () => this.setNavBarActive("hide") },
                                        React.createElement(Link, { className: "dropdown-head", to: "/aitahack" }, "Aitahack")),
                                    React.createElement("li", { className: "menu-part-list__item pl-0", onClick: () => this.setNavBarActive("hide") },
                                        React.createElement(Link, { className: "dropdown-head", to: "/engine" }, "Aitaengine")),
                                    React.createElement("li", { className: "menu-part-list__item pl-0", onClick: () => this.setNavBarActive("hide") },
                                        React.createElement(Link, { className: "dropdown-head", to: "/contact-us" }, "Contact Us")),
                                    React.createElement("li", { className: "menu-part-list__item pl-0", onClick: () => this.setNavBarActive("hide") },
                                        React.createElement(Link, { className: "dropdown-head", to: "/about" }, "About Aitahub"))),
                                React.createElement("div", { style: { float: "left" } },
                                    React.createElement(Link, { className: "btn btn--skewBrd d-inline-block mt-4 position-relative", to: "/signup" },
                                        "Signup",
                                        React.createElement("img", { className: "img-brd", src: "assets/images/header/brd2.png" })),
                                    React.createElement("div", { className: "btn btn--skewBrd d-inline-block mt-4 position-relative", onClick: () => this.showLogin() },
                                        "Login",
                                        React.createElement("img", { className: "img-brd", src: "assets/images/header/brd2.png" }))))))))));
    }
}