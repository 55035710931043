import { Grid, Typography } from '@mui/material';
import Breadcrums from '../Breadcrums/Breadcrums';
import MailLeftBar from './MailLeftBar';
import AdminNavbar from '../../components/Dashboard/AdminNavbar';



const breadcrums = 
[
  {
    page: "HOME",
    route: "/dashboard"
  },
  {
    page: "mail",
    route: "/mail"
  },
  
]

export default function index () {


    return(
      <main>
      <AdminNavbar menu="mail" navbar="" />
        {/* <div className="container" style={{paddingTop:"30px",paddingLeft:'76px'}} */}
        {/* > */}
            {/* <div style={{marginBottom:20}}>
            <MailBreadcrumb/>
            </div> */}
            <Grid  container columns={12} sx  = {{pl:'9rem',mt:6}}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{fontWeight:400,fontSize: '1.55rem'}}
                  >
                    Mail
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    mb: 2,
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                  <Breadcrums pages={breadcrums} />
                </Grid>

            </Grid>
            <MailLeftBar/>

           
        {/* </div> */}
        </main>
    )
}