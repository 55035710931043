import React from "react";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
// import Programs from "../../components/programs/programs";
import Programs from "../../JScomponents/Programs/ProgramTiles";
import Service from "../../service/service";
class UserPrograms extends React.Component {
    constructor(props) {
        super(props);
        this.programs = [];
        this.isReadyToRender = false;
        this.headerStyleProp = { paddingTop: "3rem", paddingLeft: '2rem', height: '100%' };
        this.props = props;
        this.getUserNonRegPrograms();
    }
    getUserNonRegPrograms() {
        const sessionData = sessionStorage.getItem("userDetails");
        if (sessionData != null) {
            const userDetails = JSON.parse(sessionData).data;
            new Service().getUserNonRegPrograms(userDetails.id).then((res) => {
                this.programs = res.data;
                this.isReadyToRender = true;
                this.setState(this.programs);
            }).catch((err) => {
            });
        }
        else {
            this.props.history.push("/");
        }
    }
    render() {
        return (React.createElement("main", null,
            React.createElement(AdminNavbar, { menu: "myprograms", navbar: "" }),
            this.isReadyToRender &&
                React.createElement(Programs, Object.assign({}, this.props, { userNonRegPrograms: this.programs, headerStyleProp: this.headerStyleProp }))));
    }
}
export default UserPrograms;