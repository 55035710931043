import {Accordion, AccordionDetails, AccordionSummary,Divider, Grid, Stack, Typography} from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import Loader from '../Skeleton/Loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Service from "../../service/service";
import AudioPlayer from './AudioPlayer';
import { useHistory } from 'react-router-dom';



function SectionView({coursesection,courseitem,imageid}) {

    const history = useHistory();
    const [expanded, setExpanded] = React.useState(false);
    const [sectiondata, setsectiondata] = React.useState([]);
    console.log("sectionfile",sectiondata)
    // console.log("mediumarray",mediumarray)

    const handleChange = (panel,id) => (event, isExpanded) => {
      new Service().getsubsectionforcourse(id).then(
        (response) => {
          // console.log("splittedarray",result)
          
          setsectiondata(response);
          setExpanded(isExpanded ? panel : false);
          

            
        }
      ).catch((res)=>{
              if((res+'').includes("401"))
                  history.push("/")
      });

      
    };


    console.log("coursesection",coursesection)
  

    if (!coursesection) {
        return <Loader/>;
      }


  return (

    <div>
    {coursesection  && coursesection.map((header,id) => (
        <div key = {id}>
    <Accordion expanded={expanded === id} onChange={handleChange(id,header.id)} 
    sx = {{mb:3,mt:3}}
    >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
            <Typography variant = "caption" sx={{ color: 'text.secondary',fontWeight:700 }}>{header.title}</Typography>
            </AccordionSummary>

            {sectiondata && sectiondata.data && sectiondata.data.map((subheader,index) =>(


            
              <div key = {id}>
              <AccordionDetails  >
              {subheader !== '' &&
              <Stack
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              spacing={2}
              >


              {subheader.mimeType === 'application/pdf' ?
              <li>

                
              <Link  to={{ pathname: '/pdfviewer', 
              state: { 
                assetid:subheader.assestid ,
                coursename:courseitem.title,
                imageid:imageid
               } 
              }}
              style={{ textDecoration: "none" }}>
              <Typography variant = "caption" sx = {{color: '#afb1b3',fontWeight:800}}>
              {subheader.name}
              </Typography> 
              </Link>
              </li>

              :null
              }

              {subheader.mimeType === 'audio/mpeg' ?


                <Grid container >  
                
                <Grid item  align="left" sm = {.5} md = {.5} lg = {.5}>
                <li></li>
                </Grid>
                <Grid item  align="left" sm = {10} md = {10} lg = {10}>
                
                  <Typography  variant = "caption" sx = {{color: '#afb1b3',fontWeight:700}}>
                                    {subheader.name}
                  </Typography>
                  </Grid>

                  <Grid item  align="right" sm = {1.5} md = {1.5} lg = {1.5}>
                  <AudioPlayer   assetid = {subheader.assestid}/>

                  </Grid>
                
                </Grid>

                

              :null}

              {subheader.mimeType === 'text/plain' ?
              <li>
              <Link  to={{ pathname: '/textviewer', 
              state: { 
                assetid:subheader.assestid ,
                subsectionName:subheader.name ,
                coursename:courseitem.title,
                imageid:imageid
              }
                  }}  
              style={{ textDecoration: "none" }}>
                
              <Typography variant = "caption" sx = {{color: '#afb1b3',fontWeight:800}}>
              {subheader.name}
              </Typography> 
              </Link>
              </li>

              :null

              }

              {subheader.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ?
              <li>
              <Link  to={{ pathname: '/wordviewer', 
              state: { 
                assetid:subheader.assestid ,
                subsectionName:subheader.name ,
                coursename:courseitem.title,
                imageid:imageid
              }
                  }}  
              style={{ textDecoration: "none" }}>
                
              <Typography variant = "caption" sx = {{color: '#afb1b3',fontWeight:800}}>
              {subheader.name}
              </Typography> 
              </Link>
              </li>

              :null

              }

              {subheader.mimeType === 'video/mp4' ?
              <li>
              <Link  to={{ pathname: '/videoplayer', 
              state: { 
                assetid:subheader.assestid ,
                subsectionName:subheader.name ,
                coursename:courseitem.title,
                description:courseitem.description,
                imageid:imageid,
                duration:courseitem.duration
              }
                  }}  
              style={{ textDecoration: "none" }}>
                
              <Typography variant = "caption" sx = {{color: '#afb1b3',fontWeight:800}}>
              {subheader.name}
              </Typography> 
              </Link>
              </li>

              :null

              }



              </Stack>}
              </AccordionDetails>
              <Divider/>
              </div>



            
        ))}
                    
        </Accordion>
        </div>

        ))}
        </div>
    

  )
}


// const mapStateToProps = (state) => {
//     return ( {coursecontent: state.coursecontent}) ;
//   };
  
// export default connect(mapStateToProps,fetchCourseContent("/contentcourse"))(ContentView);
export default SectionView;