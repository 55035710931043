import React, {useState} from 'react'
import Scheduler from "react-mui-scheduler";
import {Grid} from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {getstudentevents} from '../../store/action';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Breadcrums from "../Breadcrums/Breadcrums";
import { createTheme } from '@mui/material/styles';
import AdminNavbar from '../../components/Dashboard/AdminNavbar';
import "../../pages/Dashboard/Dashboard.scss"

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
              
              // Name of the slot
              root: {
                // Some CSS
                backgroundColor:"#fff" ,
                // backgroundColor:"#fff",
                color: 'white',
                fontFamily:'Poppins',
                fotntWeight:550
                
                
              },
              
              
            },
          },
          
          MuiTable: {
            styleOverrides: {
                root: {
                    
                    backgroundColor:'#fff'
                    
                    
                },

                
                fontSize:'4rem',
                
                }
                },
          MuiToolbar: {
            styleOverrides: {
                root: {
                    
                    backgroundColor:'#fff',
                    padding:'.5rem'

                    
                    
                },

                
                
                
                }

          },
          MuiToggleButton : {
            styleOverrides : {
                root : {
                    fontWeight:600
                }
            }
          },
          MuiFormLabel : {
            styleOverrides : {
                root : {
                    fontWeight:550
                }
            }
          },
          MuiTypography: {
            styleOverrides: {
                root: {
                    
                    
                    color:'#000000',
                    fontSize:'1rem',
                    fontWeight:400
                    
                },
                

                
               
                
                }

          }
                
                
}})

function Eventscheduler() {
  const dispatch = useDispatch();

  // const student_id = '673dd1e8-2835-49d8-b544-9ceae724dee1'
  var userDetails  = sessionStorage.getItem("userDetails");
  const email = JSON.parse(userDetails).data.email;
  console.log("scheduler",email)


  useEffect(() => {
    dispatch(getstudentevents(email));
    
    }, [email,dispatch]);
  const events = useSelector((state) => state.Stulist.studentevents);
  console.log("events",events)
  const breadcrums = [
    {
      page: "HOME",
      route: "/dashboard"
    },
    {
      page: "Events",
      route: "/events"
    }
    
  ]
  
  const [itemdetails,setItem] = useState([]);
  console.log("eventsdetails",itemdetails)
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  
  const [state] = useState({
    options: {
      transitionMode: "zoom", // or fade
      startWeekOn: "mon",     // or sun
      defaultMode: "month",    // or week | day | timeline
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: '100%',
      maxHeight: '100%',
      
    },
    alertProps: {
      open: true,
      color: "success",          // info | success | warning | error
      severity: "info",       // info | success | warning | error
      message: "Check here for your Schedule Programs" ,
    //   backgroundcolor:"#f95877",
      showActionButton: true,
      showNotification: true,
      delay: 1500
    },
    toolbarProps: {
      showSearchBar: false,
      showSwitchModeButtons: true,
      showDatePicker: true,
      
    }
  })
  
//   const events = [
//     {
//       "id": "event-1",
//       "label": "Angular course",
//       "url":"/coursepreview",
//       "groupLabel": "Premnath",
//       "user": "Premnath",
//       "color": "#099ce5",
//       "startHour": "14:00 PM",
//       "endHour": "16:00 PM",
//       "date": "2022-12-24",
//       "createdAt": new Date(),
//       "createdBy": "Venkatesh"
//     },
//     {
//       "id": "event-2",
//       "label": "ReactJS course",
//       "groupLabel": "Premnath",
//       "user": "Premnath",
//       "color": "#099ce5",
//       "startHour": "09:00 AM",
//       "endHour": "10:00 AM",
//       "date": "2022-12-22",
//       "createdAt": new Date(),
//       "createdBy": "Venkatesh"
//     },
//     {
//       "id": "event-3",
//       "label": "ReactJS Quiz",
//       "groupLabel": "Premnath",
//       "user": "Premnath",
//       "url":"/quiz",
//       "color": "#263686",
//       "startHour": "13:00 PM",
//       "endHour": "14:00 PM",
//       "date": "2022-12-22",
//       "createdAt": new Date(),
//       "createdBy": "Venkatesh"
//     },
//     {
//       id: "event-4",
//       label: "Python Course",
//       groupLabel: "Jebaraj",
//       user: "Jebaraj",
//       color: "#f28f6a",
//       startHour: "08:00 AM",
//       endHour: "09:00 AM",
//       date: "2022-12-25",
//       createdAt: new Date(),
//       createdBy: "Jegadeesan"
//     }
//   ]
  
  const handleCellClick = (event, row, day) => {
    // Do something...
    console.log(row);
  }
  
  const handleEventClick = (event, item) => {
    // Do something...
    console.log(item);
    setAnchorEl(event.currentTarget);
    
    setItem(item);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  const handleEventsChange = (item) => {
    // Do something...
    console.log("handleeventchange"+ item)
  }
  
  const handleAlertCloseButtonClicked = (item) => {
    // Do something...
  }
  
  return (

    <main>
       <AdminNavbar menu="Calender" navbar="" />

       <div className="container" style={{paddingBottom:"26px",paddingTop:"15px",paddingLeft:'54px'}}>
                    <div className="row">
    <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Paper sx={{ border: '1px solid grey',borderRadius:'8px' ,bgcolor: 'background.paper',maxWidth:"304px" }}>
                  
                        <List sx={{p:'0px',m:'0px'}}>
                        
                        <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                        <div style={{display:'flex' ,flexDirection:'row'}}>
                            <Typography minWidth="100px" sx={{textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Title</Typography>
                            <Typography sx={{fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{itemdetails.label}</Typography>
                        </div>
                        </ListItemText>
                        <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                        <div style={{display:'flex' ,flexDirection:'row'}}>
                            <Typography minWidth="100px" sx={{textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Date</Typography>
                            <Typography sx={{fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{itemdetails.date}</Typography>
                            </div>
                        </ListItemText>
                        <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                        <div style={{display:'flex' ,flexDirection:'row'}}>
                        <Typography minWidth="100px"sx={{textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Time</Typography>
                            <Typography sx={{fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{itemdetails.startHour}</Typography>
                        </div>
                        </ListItemText>
                        <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                        <div style={{display:'flex' ,flexDirection:'row'}}>
                        <Typography minWidth="100px" sx={{textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Instructor</Typography>
                            <Typography sx={{fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{itemdetails.createdBy}</Typography>
                        </div>
                        </ListItemText>
                        <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                        <div style={{display:'flex' ,flexDirection:'row'}}>
                        <Typography minWidth="100px" sx={{textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Description</Typography>
                            <Typography sx={{display:'block',wordWrap: 'break-word',fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{itemdetails.description}</Typography>
                        </div>
                        </ListItemText>

                        
                    
                    
                    
                    
                    </List>
                    <Box 
                      
                      sx={{display: 'flex',justifyContent:'center',p:'.5rem'}}
                    >   <Link to={{ pathname: '/coursepreview', state: { courseid:itemdetails.courseid } }}style={{textDecoration:"none"}} >
                        <Button sx={{backgroundColor:'#f95877',color:"#fff"}} size="small" >
                            start
                        </Button>
                        </Link>
                    </Box>

                  </Paper>
             
          
         </ Popover>
        <Grid
          container
          
          minWidth="fit-content"
          display = "flex-start"

          sx={{ pt:'2rem' }}
      >

              {/* <Grid sx={{marginLeft:'1rem'}} lg={12} md={12} sm={12} xs={12}>
                  <Card sx={{ border: '1px solid transparent', width: '100%', bgcolor: "rgb(255, 255, 255)", color: 'rgb(18, 24, 40)', borderRadius: '2px' }}>
                      <CardHeader

                          sx={{ padingTop: '32px', paddingBottom: '22px', borderBottom: '1px solid rgb(230, 232, 240)', paddingLeft: '24px', paddingRight: '32px', alignItem: "center" }}
                          titleTypographyProps={{ fontSize: '1.25rem', fontWeight: 700, fontFamily: 'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"' }}
                          title="Events" />
                  </Card>
              </Grid> */}
              <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                    marginLeft:'1rem',
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                    }}
                >
                    <Typography
                    variant="button"
                    sx={{ lineHeight: 1.5, fontSize: "1.55rem", fontWeight: 400}}
                    >
                    Events
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                    marginLeft:'1rem',
                    mb: 2,
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                    }}
                >
                    <Breadcrums pages={breadcrums} />
              </Grid>
              <Grid sx={{ marginLeft:'1rem', fontWeight: 400 }} item lg={12} md={12} sm={12} xs={12}>
                  {events && events !== undefined ?
                  <ThemeProvider theme={theme}>
                      <Scheduler
                          locale="en"
                          events={events}
                          legacyStyle={false}
                          options={state?.options}
                          // alertProps={state?.alertProps}
                          toolbarProps={state?.toolbarProps}
                          onEventsChange={handleEventsChange}
                          onCellClick={handleCellClick}
                          onTaskClick= {handleEventClick}
                          onAlertCloseButtonClicked={handleAlertCloseButtonClicked} />
                          </ThemeProvider> : <h4 style={{marginLeft:'2rem',marginTop:'2rem'}}>No events to show</h4>}
              </Grid>
              
          </Grid>
          </div>
          </div>
    </main>
          
  )
}

export default Eventscheduler;