import axios from "axios";
import { QUIZDB_URL } from "../constants/global";
export default class Quizservice {
    async getQuizans(quizid) {
        const response = await axios({
            url: QUIZDB_URL + '/questAnsByQuiz/' + quizid,
            method: 'GET',
        });
        console.log("response", response);
        return response.data.data;
    }
    async getQuiz(courseid) {
        const response = await axios({
            url: QUIZDB_URL + '/quiz/' + courseid,
            method: 'GET',
        });
        console.log("response", response);
        return response.data.data;
    }
    async getstudentquizres(emailid) {
        const response = await axios({
            url: QUIZDB_URL + '/stuDashboardQuizResult/' + emailid,
            method: 'GET',
        });
        console.log("response", response);
        return response.data.data;
    }
    async getassignment(courseid) {
        const response = await axios({
            url: QUIZDB_URL + '/assignment/' + courseid,
            method: 'GET',
        });
        return response.data;
    }
    async assignmentStatus(data) {
        const response = await axios({
            url: QUIZDB_URL + '/checkassignment',
            method: 'GET',
            data: data,
            headers: { "Content-Type": "application/json" }
        });
        return response.data;
    }
    async submitAssignment(data) {
        const response = await axios({

            url: QUIZDB_URL + '/assignment',
            method: 'POST',
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        });
        return response.data;
    }
    async getcompletedassignment(emailid) {
        const response = await axios({
            url: QUIZDB_URL + '/completedassignment/' + emailid,
            method: 'GET',
        });
        return response.data;
    }
}