import React from "react";
import "./Products.scss";
import { Link } from "react-router-dom";
class Products extends React.Component {
    render() {
        return (React.createElement("section", { className: "sec-pd section-home-product" },
            React.createElement("div", { className: "product-spin" },
                React.createElement("div", { className: "product-img-part" },
                    React.createElement("div", { className: "product-img-part__down" },
                        React.createElement("img", { className: "img-fluid", src: "assets/images/home/circle-bottom.png" })),
                    React.createElement("div", { className: "product-img-part__up" },
                        React.createElement("img", { className: "img-fluid", src: "assets/images/home/Flotting.svg" })))),
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 col-md-6" },
                        React.createElement("div", { className: "sec-head w-100" },
                            React.createElement("h5", { className: "fnt-20 fw-700 text-5d5d mb-0 mb-md-2" }, "Our Product"),
                            React.createElement("h2", { className: "fnt-32 fw-700 text-1818 mb-2 mb-md-3" }, "Transform on World\u2019s First Job Readiness Engine"),
                            React.createElement("p", { className: "fnt-18 fnt-500 text-1818 text-justify" }, "The Job Readiness Engine Designed to Ready Students and Working Professionals from Diverse Background for High Skilled Jobs"),
                            React.createElement(Link, { to: "/engine", className: "btn btn--skew mt-3 d-inline-block" }, "Try a Demo")),
                        React.createElement("div", { className: "work-bench" },
                            React.createElement("h6", { className: "fnt-24 text-1818 fw-700 col-lg-10 px-0" }, "Ultimate AI Workbench for Experiential Learning"),
                            React.createElement("ul", { className: "work-bench-step d-flex flex-wrap", style: { padding: 0 } },
                                React.createElement("li", { className: "work-bench-step__item odd-list" },
                                    React.createElement("div", { className: "work-step" },
                                        React.createElement("span", { className: "text-1818 fw-600" }, "Tool Kit"))),
                                React.createElement("li", { className: "work-bench-step__item odd-list" },
                                    React.createElement("div", { className: "work-step" },
                                        React.createElement("span", { className: "text-1818 fw-600" }, "Business Problem"))),
                                React.createElement("li", { className: "work-bench-step__item odd-list" },
                                    React.createElement("div", { className: "work-step" },
                                        React.createElement("span", { className: "text-1818 fw-600" }, "Data Acquisition"))),
                                React.createElement("li", { className: "work-bench-step__item even-list" },
                                    React.createElement("div", { className: "work-step" },
                                        React.createElement("span", { className: "text-1818 fw-600" }, "Feature Engineering"))),
                                React.createElement("li", { className: "work-bench-step__item even-list" },
                                    React.createElement("div", { className: "work-step" },
                                        React.createElement("span", { className: "text-1818 fw-600" }, "Transform Data"))),
                                React.createElement("li", { className: "work-bench-step__item even-list" },
                                    React.createElement("div", { className: "work-step" },
                                        React.createElement("span", { className: "text-1818 fw-600" }, "Data Architecture"))),
                                React.createElement("li", { className: "work-bench-step__item odd-list" },
                                    React.createElement("div", { className: "work-step" },
                                        React.createElement("span", { className: "text-1818 fw-600" }, "Machine Learning Models"))),
                                React.createElement("li", { className: "work-bench-step__item odd-list" },
                                    React.createElement("div", { className: "work-step" },
                                        React.createElement("span", { className: "text-1818 fw-600" }, "Train, Test, Deploy Models"))),
                                React.createElement("li", { className: "work-bench-step__item odd-list" },
                                    React.createElement("div", { className: "work-step" },
                                        React.createElement("span", { className: "text-1818 fw-600" }, "Advanced Use Cases")))))),
                    React.createElement("div", { className: "col-12 col-md-6" },
                        React.createElement("div", { className: "product-spin product-spin--mobile" },
                            React.createElement("div", { className: "product-img-part" },
                                React.createElement("div", { className: "product-img-part__down" },
                                    React.createElement("img", { className: "img-fluid", src: "assets/images/home/circle-bottom.png" })),
                                React.createElement("div", { className: "product-img-part__up" },
                                    React.createElement("img", { className: "img-fluid", src: "assets/images/home/Flotting.svg" })))))))));
    }
}
export default Products;