import React, { useState, useEffect } from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Box,
  Grid,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import Moment from "moment";
import { Link } from "react-router-dom";
import Quizservice from "../Quiz/quizservice";
import FilterListIcon from "@mui/icons-material/FilterList";
import Pagination from "@mui/material/Pagination";

const AssignmentResultTable = () => {
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOption, setFilterOption] = useState("");
  const [assignmentResult, setAssignmentResult] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 6; // Adjusted rows per page

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    const email = JSON.parse(userDetails).data.email;

    new Quizservice()
      .getcompletedassignment(email)
      .then((response) => {
        setAssignmentResult(response.data);
      })
      .catch((error) => {
        console.error("Error fetching assignment results:", error);
      });
  }, []);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterOption = (option) => {
    setFilterOption(option);
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const filteredData = assignmentResult.filter((assignment) =>
  assignment.name.toLowerCase().includes(searchText.toLowerCase())
  ).sort((a, b) =>
    Moment(a.createdate).diff(b.createdate) || a.timestamp - b.timestamp
  );

  if (filterOption === "newer") {
    filteredData.sort((a, b) =>
      Moment(b.createdate).diff(a.createdate) || b.timestamp - a.timestamp
    );
  } else if (filterOption === "older") {
    filteredData.sort((a, b) =>
      Moment(a.createdate).diff(b.createdate) || a.timestamp - b.timestamp
    );
  }

  const pageCount = Math.ceil(filteredData.length / rowsPerPage);
  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const uniqueRows = paginatedData.reduce((accumulator, assignment) => {
    const key = `${assignment.name}-${Moment(assignment.createdate).format(
      "YYYY-MM-DD"
    )}-${assignment.timestamp}`;
    const existingAssignment = accumulator.find((row) => {
      const existingKey = `${row.name}-${Moment(row.createdate).format(
        "YYYY-MM-DD"
      )}-${row.timestamp}`;
      return key === existingKey;
    });
    if (!existingAssignment) {
      accumulator.push(assignment);
    }
    return accumulator;
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <TextField
            label="Search by Title"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
          />

          <Button
            variant="outlined"
            onClick={handleFilterClick}
            endIcon={<FilterListIcon />}
          >
            Filter
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem onClick={() => handleFilterOption("newer")}>
              Newer
            </MenuItem>
            <MenuItem onClick={() => handleFilterOption("older")}>
              Older
            </MenuItem>
          </Menu>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: ".9375rem",
                      fontWeight: 600,
                      letterSpacing: "2px",
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    Title
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: ".9375rem",
                      fontWeight: 600,
                      letterSpacing: "2px",
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    Date
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: ".9375rem",
                      fontWeight: 600,
                      letterSpacing: "2px",
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    Score
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: ".9375rem",
                      fontWeight: 600,
                      letterSpacing: "2px",
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uniqueRows.length > 0 ? (
                uniqueRows.map((assignment) => (
                  <TableRow key={assignment.assignmentid}>
                    <TableCell>{assignment.name}</TableCell>
                    <TableCell align="center">
                      {Moment(assignment.createdate).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </TableCell>
                    <TableCell align="center">{assignment.grade}</TableCell>
                    <TableCell align="center">
                      <Link
                        to={{
                          pathname: "/assignment",
                          state: { courseid: assignment.courseid },
                        }}
                        style={{ textDecoration: "none", color: "#ffffff" }}
                      >
                        <Button
                          variant="contained"
                          startIcon={<PlaylistAddCheckIcon />}
                        >
                          <Typography variant="button">RETAKE</Typography>
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    align="center"
                    style={{ padding: "1rem" }}
                  >
                    <Typography variant="body1">
                      No completed assignments to show.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Pagination
            count={pageCount}
            page={page + 1}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AssignmentResultTable;
