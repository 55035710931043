// const AITADB_URL ='http://localhost:8082'
// const QUIZDB_URL ='http://127.0.0.1:5000'
// const JSONDB_URL ='http://localhost:8000' 

const AITADB_URL ='https://uatapi.aitahub.com'
const QUIZDB_URL ='https://uatevent.aitahub.com'
const JSONDB_URL ='https://data.aitahub.com'

export {
    
    AITADB_URL,
    QUIZDB_URL,
    JSONDB_URL,
    
}
