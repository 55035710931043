import React from "react";
import "../../styles/Link.scss";
import "./Companies.scss";
import { Link as Dom } from "react-scroll";
import classNames from "classnames";
import { Link } from "react-router-dom";
class Companies extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            active: "skills"
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    activate(value) {
        this.setState({ active: value });
    }
    render() {
        return (React.createElement("main", null,
            React.createElement("section", { className: "sec-pd section-student-banner section-student-banner--company", style: { backgroundImage: "url('assets/images/company/company-bg.jpg')" } },
                React.createElement("img", { className: "img-fluid company-banner-img", src: "assets/images/company/company-bg.jpg" }),
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row content-visibility align-items-center" },
                        React.createElement("div", { className: "col-12 col-md-6" },
                            React.createElement("div", { className: "banner-content" },
                                React.createElement("h6", { className: "banner-content__title fw-700 text-5d5d" }, "Skill, Hire, Manage & Grow the Talent"),
                                React.createElement("h1", { className: "banner-content__head fw-700 text-1818" }, "Hub for High Skilled Talents, Solutions")),
                            React.createElement("div", { className: "banner-info" },
                                React.createElement("h6", { className: "banner-info__title text-1615 fw-600" }, "Acquire Skills, Solutions, R&D for 5.0 Products"),
                                React.createElement("p", { className: "banner-info__disc fnt-18 text-1818 fw-400 text-justify" }, "Aitahub is creating one hub for companies to acquire most in demand skills and to hire diverse, high skilled talents for AI powered Jobs - Data Engineers to Data Scientist, Business Analyst to Marketing Manager.")),
                            React.createElement("h6", { className: "banner-info__title text-1615 fw-600 mb-3 mb-md-4" }, "Partner with Us "),
                            React.createElement("div", { className: "banner-btn" },
                                React.createElement(Link, { className: "d-inline-block home-slider-btn__item btn btn--skew", to: "/partners" }, "Skill and Hire"),
                                React.createElement(Link, { className: "d-inline-block home-slider-btn__item btn btn--skewBrd", to: "/contact-us" },
                                    "Contact Us",
                                    React.createElement("img", { className: "img-brd", src: "assets/images/header/brd2.png" }))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("ul", { className: "row company-image-cards mb-0" },
                                React.createElement("li", { className: "text-md-center col-12 col-md-4 company-image-cards__list" },
                                    React.createElement(Link, { className: "d-block", to: "#" },
                                        React.createElement("h6", { className: "text-black fnt-24 fw-600" }, "Skill & Hire Program"),
                                        React.createElement("img", { className: "img-fluid radius12", src: "assets/images/company/upskill1.jpg" }))),
                                React.createElement("li", { className: "text-md-center col-12 col-md-4 company-image-cards__list" },
                                    React.createElement(Link, { className: "d-block", to: "#" },
                                        React.createElement("h6", { className: "text-black fnt-24 fw-600" }, "On the Job Upskilling"),
                                        React.createElement("img", { className: "img-fluid radius12", src: "assets/images/company/upskill2.jpg" }))),
                                React.createElement("li", { className: "text-md-center col-12 col-md-4 company-image-cards__list" },
                                    React.createElement(Link, { className: "d-block", to: "#" },
                                        React.createElement("h6", { className: "text-black fnt-24 fw-600" }, "Startup Talent Solutions"),
                                        React.createElement("img", { className: "img-fluid radius12", src: "assets/images/company/upskill3.jpg" })))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row js-custom-tabs" },
                        React.createElement("div", { className: "col-12 col-md-4 col-lg-3" },
                            React.createElement("ul", { className: "sub-tab-list sticky-element", style: { paddingLeft: "0px" } },
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "skills" }) },
                                    React.createElement(Dom, { to: "skills", offset: -100, onClick: () => this.activate("skills") }, "Skills and Hire AI Talents")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "hire" }) },
                                    React.createElement(Dom, { to: "hire", offset: -100, onClick: () => this.activate("hire") }, "Hiring Partners")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "why" }) },
                                    React.createElement(Dom, { to: "why", offset: -100, onClick: () => this.activate("why") }, "Why Aitahub")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "process" }) },
                                    React.createElement(Dom, { to: "process", offset: -100, onClick: () => this.activate("process") }, "Process")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "module" }) },
                                    React.createElement(Dom, { to: "module", offset: -100, onClick: () => this.activate("module") }, "Module & Tools")))),
                        React.createElement("div", { className: "col-12 col-md-9 col-lg-9" },
                            React.createElement("ul", { className: "sub-tab-content-list tab-pad-left" },
                                React.createElement("li", { className: "sub-tab-content-list__item active" },
                                    React.createElement("div", { className: "random-divs" },
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "skills", className: "Blueline-border border-0 bg-6f8f radius-0" },
                                                React.createElement("div", { className: "col-12 col-md-11 px-0" },
                                                    React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-4 pb-lg-1" }, "Top AI Based Skills Designed for You"),
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Specialists Careers"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-3 mb-md-4 mb-lg-5", style: { paddingLeft: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets\\images\\programs/p2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Deep Learning")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p3.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "NLP")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Neural Networks")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/cds.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Computer Vision")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/cds.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "High End Analytics"))))),
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Tech Jobs"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-3 mb-md-4 mb-lg-5", style: { paddingLeft: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/p1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analyst")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/p2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Engineer")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/p3.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning Engineer")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/p5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Scientist")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/cds.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Citizen Data Scientist"))))),
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Business Jobs"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-0", style: { paddingLeft: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Business Analyst")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Management Consultant")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/ca.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Chartered Accountant")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr4.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Investment Banker")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Marketing Manager")))))))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "hire", className: "Blueline-border p-0" },
                                                React.createElement("img", { className: "radius-12 radius-btm-0 img-fluid", src: "assets/images/company/comps.png" }),
                                                React.createElement("div", { className: "pad-div" },
                                                    React.createElement("h4", { className: "fnt-32 text-1615 fw-600" }, "Top AI Companies"),
                                                    React.createElement("h5", { className: "fnt-20 text-1615 fw-500 mb-3 mb-md-4" }, "Trust Us"),
                                                    React.createElement(Link, { className: "d-inline-block btn btn--skew", to: "/contact-us" }, "Contact Us")))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "why", className: "Blueline-border border-0 bg-6f8f radius-0" },
                                                React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-4 pb-xl-2" }, "Aithub Advantage"),
                                                React.createElement("ul", { className: "regular-card-list row mb-0" },
                                                    React.createElement("li", { className: "regular-card-list__item col-12 col-md-6 col-lg-4 col-xl-3" },
                                                        React.createElement("div", { className: "card-div" },
                                                            React.createElement("p", { className: "fnt-16" }, "Talents Ready for Jobs from Day One"))),
                                                    React.createElement("li", { className: "regular-card-list__item col-12 col-md-6 col-lg-4 col-xl-3" },
                                                        React.createElement("div", { className: "card-div" },
                                                            React.createElement("p", { className: "fnt-16" }, "Cut 6 months of Skilling New Entrant"))),
                                                    React.createElement("li", { className: "regular-card-list__item col-12 col-md-6 col-lg-4 col-xl-3" },
                                                        React.createElement("div", { className: "card-div" },
                                                            React.createElement("p", { className: "fnt-16" }, "All Capable, Skilled, Job Ready, Diverse Talents in One Place"))),
                                                    React.createElement("li", { className: "regular-card-list__item col-12 col-md-6 col-lg-4 col-xl-3" },
                                                        React.createElement("div", { className: "card-div" },
                                                            React.createElement("p", { className: "fnt-16" }, "Programs Made by and made for Your Skills Requirement"))),
                                                    React.createElement("li", { className: "regular-card-list__item col-12 col-md-6 col-lg-4 col-xl-3" },
                                                        React.createElement("div", { className: "card-div" },
                                                            React.createElement("p", { className: "fnt-16" }, "Provide ML led Hiring Tool:  Performance Analytics, Skill Dashboard, Project Repository, and AitaDtQ-Certification Metrics"))),
                                                    React.createElement("li", { className: "regular-card-list__item col-12 col-md-6 col-lg-4 col-xl-3" },
                                                        React.createElement("div", { className: "card-div" },
                                                            React.createElement("p", { className: "fnt-16" }, "Give Problems and Hire on Outcomes in Aitathons"))),
                                                    React.createElement("li", { className: "regular-card-list__item col-12 col-md-6 col-lg-4 col-xl-3" },
                                                        React.createElement("div", { className: "card-div" },
                                                            React.createElement("p", { className: "fnt-16" }, "Customized Training Solutions for Skilling & Grow Talent "))),
                                                    React.createElement("li", { className: "regular-card-list__item col-12 col-md-6 col-lg-4 col-xl-3" },
                                                        React.createElement("div", { className: "card-div" },
                                                            React.createElement("p", { className: "fnt-16" }, "Helps Companies in Digital Transformation with R&D")))))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "process", className: "Blueline-border" },
                                                React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-4 pb-xl-2" }, "Talents Learnt Skills in the Job Environment"),
                                                React.createElement("div", { className: "points-color" },
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Skill Gap Assessment, Program Development"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Job Based Modules, Skill Based Content"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Campus Experience in Skill Lab: High Academic Credentials"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Expert Instructors, Mentors from the Industry"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Perform Job Tasks on World\u2019s First Job Readiness Engine"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Learning with Ultimate AI workbench"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Work and Specialized with 100+ Tools, Data Science Use Cases"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Learn by Doing and built Professional Projects Portfolio"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Collaborate Learning on Aita Hack Platform"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Built in Business and Soft Skills")))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "module", className: "Blueline-border" },
                                                React.createElement("div", { className: "skil-content" },
                                                    React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3" }, "100 + Tools, Platform, AI + Data Science Use Cases"),
                                                    React.createElement("p", { className: "fw-500" }, "Specialist Data Science Modules for Each Business")),
                                                React.createElement("ul", { className: "skill-icons-list mb-0 d-flex flex-wrap clearfix" },
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill1.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill2.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill3.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill4.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill5.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill6.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill7.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill8.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill9.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill10.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill11.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill12.png" })))))))))))))));
    }
}
export default Companies;