import * as React from "react";
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import Breadcrums from "../Breadcrums/Breadcrums";
import Divider from '@mui/material/Divider';
import Service from "../../service/service";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReactPaginate from "react-paginate";
import SearchFilter from "../../components/SearchFilter";
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { BlockUI } from 'primereact/blockui';
import { Button, Grid,Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { Panel } from 'primereact/panel';



class Programs extends React.Component {
  selectedProgramList  = [];
  programList = [];
  currentProgramList =[];
  categories  = [];
  userData = {};
  perPage  = 5;
  datasOnPage = [];
  breadcrums = [
    {
      page: "HOME",
      route: "/dashboard"
    },
    {
      page: "Programs",
      route: "/userprograms"
    }
    
  ]
  // breadcrums = ["Home","Programs"];
  props;
  blockedPanel = true;
  headerStyleProp = {};
  selectedCategory={};
  state ={
    Indexvalue : Number
  }
  state  = {
    existingUser : false
  }
  state ={
    placement: 'right'
  }
  constructor(props) {
    super(props);

    this.props = props;
    this.state = { anchorEl: null, open: false };
    var userDetails = sessionStorage.getItem("userDetails") || undefined;
    
    if(userDetails){
      this.state.existingUser = true;
      this.userData = JSON.parse(userDetails).data;
    }else{
      this.state.existingUser = false;
    }

    if(this.props.userNonRegPrograms !== undefined){
      this.headerStyleProp = this.props.headerStyleProp;
      this.initialize(this.props.userNonRegPrograms);
    }else{
      new Service().getProgramDetails().then(
        (response ) => {
          this.initialize(response.data);
        }
      ).catch((err)=>{
        new Service().handleErrResponse();
        this.props.history.push("/");
      });
      
    }
      this.getProgramGroups();
  }


  initialize(programDetails){
    this.programList = programDetails;
    this.currentProgramList = [...this.programList];
    this.selectedProgramList = this.programList;
    this.datasOnPage = [...this.selectedProgramList].slice(0,this.perPage);
    console.log(this.datasOnPage);
    this.blockedPanel = false;
    if(this.props.location !== undefined && this.props.location.state !== undefined){
      if(this.props.location.state.selectedCategory !== undefined){
        this.selectedCategory = this.props.location.state.selectedCategory;
        this.onSelectCategory(this.props.location.state.selectedCategory);
      }
    }     
    this.setState(this.selectedProgramList);
  }
  
  getImageAsset(assetId) {
    return new Service().getImageAsset(assetId);
  };

  getAssetPDF(assetId){
    return new Service().getDocumentPDF(assetId);
  }

  getProgramGroups() {
    this.categories = new Service().getCategories();
    this.selectedCategory = this.categories[0];
  }
  

  handlepopper = (newPlacement,index,event) => {
    this.state.anchorEl
      ? this.setState({ anchorEl: null })
      : this.setState({ anchorEl: event.currentTarget });
    this.setState({ Indexvalue: index });
    console.log(this.state.Indexvalue)
    if(this.state.open === false){
    this.setState((prevState) => ({
        open: this.state.placement !== newPlacement || !prevState.open,
    }));
    }else{
      this.setState({
        open: false
      });
    }
    this.setState({ placement: newPlacement });
    console.log(this.state.open)
    
  }
  onSelectCategory=(event)=>{
    this.selectedProgramList = [];
    this.programList.forEach((element) => {
      const selectedCategory = event.target ? event.target.value : event.value;
      if(element.category === selectedCategory && selectedCategory !== "All_PROGRAMS"){
        this.selectedProgramList.push(element);
      }else if(selectedCategory === "All_PROGRAMS"){
        this.selectedProgramList = this.programList;
      }
    });
    this.datasOnPage = [...this.selectedProgramList].slice(0,this.perPage);
    this.setState(this.datasOnPage);
  }
  handlePageClick = (data) => {
    let selected = data.selected + 1;
    this.datasOnPage = [...this.selectedProgramList].slice(this.perPage*data.selected,this.perPage*selected);
    this.setState(this.datasOnPage);
  };

  onSearch=(event)=>{
    this.currentProgramList = SearchFilter(this.programList,event.target.value);
    this.datasOnPage = [...this.currentProgramList].slice(0,this.perPage);
    this.setState(this.datasOnPage);
  }

  onApplyProgram(selectedProgram){
    if(this.state.existingUser){
      if(selectedProgram.price === 0 || selectedProgram.offer_price === 0){
        this.enrollForFreeProgram(this.userData,selectedProgram);
      }else{
        this.props.history.push({
          pathname : "/payment",
          state: {
            user_details : this.userData,
            program : selectedProgram
          }
        });
      }
    }else{
      this.props.history.push({pathname:"/applycourse",state:selectedProgram});
    }
  }

  enrollForFreeProgram(user,selectedProgram){
    let program_list = [];
    user.program_list.forEach((ele)=>{
        program_list.push(ele.id);
    });
    program_list.push(selectedProgram.id);
    user.program_list = program_list;
    new Service().updateUser(user).then((res)=>{
      new Service().getUserByEmail(user.email).then((res)=>{
          var userDetails   = {data : res.data,program_ids : []};
          res.data.program_list.forEach((ele)=>{userDetails.program_ids.push(ele.id)});
          sessionStorage.setItem("userDetails",JSON.stringify(userDetails));
          this.props.history.push("/courses"); 
        });
    });
  }

  render() {
    // const open = this.state.anchorEl === null ? false : true;
    console.log(this.state.placement)
  return (
        <>
      <section className="sec-pd section-home-product" style={this.headerStyleProp}>
        <div style={{paddingLeft:'36px'}}>
        <div className="container"> 
            <Typography sx={{fontWeight:400,fontSize: '1.55rem',ml:'2rem'}}>Programs</Typography>
            <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                    marginLeft:'2rem',
                    marginBottom:1,
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                    }}
                >
                    <Breadcrums pages={this.breadcrums} />
                    <Divider sx={{mt:1}} />
              </Grid>
              
            <div className="row">
          
             <div className="header">
                <div className="col-12 col-md-4 col-lg-4 dropDownContainer" style={{backgroundColor:'white'}}>
                  <Typography sx={{fontWeight:300,fontSize: '1.25rem',marginBottom:'.5rem'}}>Categories</Typography>
                    <select className="dropDownElem" onChange={this.onSelectCategory} defaultValue={this.selectedCategory}>
                      {this.categories.map((category,index) => {
                        return <option key={index} value={category.value} selected={category.value === this.selectedCategory.value}>{category.name}</option>
                      })}
                    </select>
                </div>
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText onChange={this.onSearch} style={{paddingTop:"5px",paddingBottom:"5px",cursor:"text"}} placeholder="Search" />
                </span>
              </div>
            </div>
            <Popper  open={this.state.open}  anchorEl={this.state.anchorEl} placement={this.state.placement} transition >
                      {({ TransitionProps }) => (
                      
                        <Fade {...TransitionProps} timeout={50}>
                          <Card sx={{marginLeft:"auto",height:"auto",borderRadius:"0.5rem",width:"auto",padding:"1rem",maxWidth:"304px"}} display ="flex">
                            <Grid container sx={{width:'100%'}}  columns={12}  >
                              <Grid item lg={2} md={2} sm={2} xs={2}  sx={{paddingLeft:1,paddingTop:1}}  >
                                <img   src={this.getImageAsset(this.datasOnPage[this.state.Indexvalue].thumbnail_asset_id)} alt="google"  style = {{borderRadius:"0.25rem",height:'40px',width:'40px',aspectRatio:"auto 40 / 40",objectFit:'cover'}} />

                              </Grid>
                              <Grid item lg={10} md={10} sm={10} xs={10}    >
                                <Typography variant="h7" component="div" sx={{ fontSize: '1rem',fontFamily:"Exo 2,Helvetica Neue,Arial,sans-serif",color:"#303956",fontWeight:'500',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                                  {this.datasOnPage[this.state.Indexvalue].title} 
                                </Typography>
                                <Typography variant="h7" component="div" sx={{ fontSize: '11px',fontWeight:'bold',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                                  Test user
                                </Typography>

                              </Grid>
                            </Grid>
                            <Grid container   columns={12}  >
                              <Grid item lg={12} md={12} sm={12} xs={12}    >
                                <Typography variant="h7" component="div" sx={{color:"rgba(39,44,51,.7)", fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",fontSize: '.8125rem',fontWeight:'400',flexGrow: 1 ,paddingLeft:1,paddingTop:"1rem",paddingBottom:"1rem"}}>
                                  {this.datasOnPage[this.state.Indexvalue].description} 
                                </Typography>
                                

                              </Grid>
                            </Grid>

                            
                            
                              <Grid spacing={1} container columns={12}  >
                                <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                                <OpenInNewIcon fontSize="small" sx={{marginLeft:1}} />
                                </Grid>
                                <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                                <a style={{color:'black'}} className="brochure" href={this.getAssetPDF(this.datasOnPage[this.state.Indexvalue].brochure_asset_id)} target="_blank" rel="noopener noreferrer">View Brochure</a>
                                </Grid>
                                {/* <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                                <OpenInNewIcon fontSize="small" sx={{marginLeft:1}} />
                                </Grid>
                                <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                                <a style={{color:'black'}} className="demo" href="/"  target="_blank">Free Demo</a>
                                </Grid> */}
                                <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                                <OpenInNewIcon fontSize="small" sx={{marginLeft:1}} />
                                </Grid>
                                {/* <a className="apply-here" href="/"  target="_blank">Apply Here Now</a> */}
                                <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                                <a style={{color:'black'}} className="advice" href={this.getAssetPDF(this.datasOnPage[this.state.Indexvalue].program_advice_asset_id)}  target="_blank" rel="noopener noreferrer">Program Advice</a>
                                </Grid>
            
                              </Grid>
                              
                            <div style={{marginTop:'1rem'}}>
                            <Grid container columns={12}  >
                                  <Grid sx={{mt:'.5rem'}} align="left"  item lg={6} md={6} sm={6} xs={6}  >
                                  <div className="price-details">
                                    <span className={this.datasOnPage[this.state.Indexvalue].price !== 0 ? 'price' : 'offer-price'}>{this.datasOnPage[this.state.Indexvalue].price === 0 ? 'free' : '₹'+this.datasOnPage[this.state.Indexvalue].price}</span>
                                      {
                                          this.datasOnPage[this.state.Indexvalue].price !== 0 &&
                                      <span style={{backgroundColor:'#f95877',fontSize: '.8225rem'}} className="offer-price">{this.datasOnPage[this.state.Indexvalue].offer_price === 0 ? 'free' : '₹'+this.datasOnPage[this.state.Indexvalue].offer_price}</span>
                                      }
                                    
                                  </div>
                                  </Grid>
                                  
                                
                              <Grid align="right" item lg={6} md={6} sm={6} xs={6}>
                                        <Button size='large' onClick={()=>this.onApplyProgram(this.datasOnPage[this.state.Indexvalue])}  sx={{bgcolor:'#f95877',textAlign:'center',padding:"8px",color:"white",fontSize:".8025rem",fontWeight:"600",fontFamily:"Exo 2,Helvetica Neue,Arial,sans-serif",display:"inline"}}>Apply here</Button>
                              </Grid>
                              {/* <Grid align="right" item lg={6} md={6} sm={6} xs={6} >
                              <Box display="flex"  sx={{mr:1}}>
                              <AttachMoneyIcon   fontSize="24px" sx={{mr:.5,ml:1}} />  


                              <Typography variant = "caption">{users[Indexvalue].price}</Typography>
                              </Box>
                              </Grid>
                              <Grid align="left" item lg={6} md={6} sm={6} xs={6} >
                              <Box display="flex"  sx={{ml:1}}>
                              <AutoStoriesIcon fontSize="24px" sx={{mr:.5,ml:1}} />  


                              <Typography variant = "caption">Course {users[Indexvalue].course_list.length}</Typography>
                                </Box>
                              </Grid> */}
                              
                              
                              
                            
                            </Grid>
                            </div>
                          </Card>
                        </Fade>
                      
                      )}
            </Popper>
          
          <div style={{marginLeft:'2rem'}}>
          <Grid  container spacing={3} columns={12}  >
            {this.datasOnPage && this.datasOnPage.map((item,index) => (
              <Grid item lg={3} md={4} sm={6} xs={6}  sx={{marginTop:2}}  >
                <Card onClick={event => this.handlepopper('right',index,event)} sx={{borderRadius:"0.5rem",height:'260px'}} display ="block">
                  <img   src={this.getImageAsset(item.thumbnail_asset_id)} alt="google"  style = {{height:'128px',overflow:'hidden',position:'relative',width:'100%',objectFit:'fit-content'}} />
                  <Grid container spacing={1} sx={{maxHeight:"105px",minHeight:"105px"}} columns={12}  >
                    <Grid align="left" sx={{MarginTop:3}} item lg={8} md={8} sm={8} xs={8}  >
                      <Typography  component="div" sx={{ fontSize: '1rem',color:'#303956',fontFamily:'Exo 2,Helvetica Neue,Arial,sans-serif',fontWeight:'620',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                        {item.title} 
                      </Typography>
                      <Typography variant="h7" component="div" sx={{ fontSize: '.8125rem',color:"#272c33",fontWeight:'400',fontHeight:'1.5',fontFamily:'Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                        Test user
                      </Typography>
                      <Typography  sx={{fontSize: '.8125rem',fontWeight:'400',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",flexGrow: 1 ,paddingLeft:2,paddingTop:1}} >
                         {item.category}
                      </Typography>
                    </Grid>
                    {/* <Grid align="center" item lg={12} md={12} sm={12} xs={12}>
                          <Button size='large' onClick={()=>this.onApplyProgram(item)}  sx={{bgcolor:'#f95877',textAlign:'center',padding:"9px",color:"white",fontSize:".7025rem",fontWeight:"600",fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",display:"inline",mb:'.75rem'}}>Apply here</Button>
                    </Grid> */}
                      
                  
                    
                    {/* <Grid align="right" sx={{paddingTop:0}} item lg={4} md={4} sm={4} xs={4}  >
                      <IconButton
                              aria-label="more"
                              id="long-button"
                              
                              aria-haspopup="true"
                              onClick={() => handleIconClick()}
                            >
                              {clicked ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
                      </IconButton>
                      
                    </Grid> */}
                  </Grid>
                  <Divider />
                  <div style={{paddingTop:'.5rem',paddingBottom:'.5rem',display:'flex',flexDirection:'row',justifyContent:'space-around',alignContent:'center'}}>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  
                      
                        <CurrencyRupeeIcon sx={{fontSize:'22px'}} />
                      
                        
                        
                    
                      <Typography  sx={{fontSize: '.9225rem',fontWeight:'500',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}} >
                          {item.offer_price}
                      </Typography>

                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                      
                              <AutoStoriesIcon  sx={{fontSize:'22px'}} />
                      

                    
                      <Typography   sx={{ml:'4px',fontSize: '.8205rem',fontWeight:'400',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}>
                      Course {item.course_list.length}
                      </Typography>

                   
                  </div>
                  </div>
                  
                    

                  
                </Card>
                
              </Grid>
              
              
            ))}
            
          </Grid>
          </div>
          <Grid container spacing={3} columns={12}>
              <Grid item lg={12} md={12} sm={12} xs={12} align="left" sx={{ marginTop: 10 }}>
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={Math.ceil(this.currentProgramList.length/this.perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'activeElement'}
              // containerClassName={'pagination'}
                />
              </Grid>

            </Grid>
            
        </div>
        </div>
        
        
        <BlockUI blocked={this.blockedPanel} fullScreen>
            <Panel>
              <div style={{textAlign:"center"}}>
                <ProgressSpinner hidden={!this.blockedPanel}/>
              </div>
            </Panel>
        </BlockUI>
        </section>
        
        </>
          


     

    
    
        
        
      
   
      
    
  );
};
}

export default Programs;