import React from 'react';
import { Grid } from '@mui/material';
import UserTimeline from './Studenttimeline';

const EventSection = ({ email }) => {
  return (
    <Grid container spacing={1} sx={{ mt: 3 }}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <UserTimeline email={email} />
      </Grid>
    </Grid>
  );
};

export default EventSection;
