import React from "react";
import "../../styles/Link.scss";
import classNames from "classnames";
import { Link as Dom } from "react-scroll";
import { Link } from "react-router-dom";
class Contact extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            requiredSupport: 'hide',
            requireValue: '',
            domainValue: '',
            domain: 'hide',
            name: '',
            mailId: '',
            contact: '',
            description: '',
            validEmail: ''
        };
        this.ValidateEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        this.onValueChange = (event) => {
            this.setState({ [event.target.id]: event.target.value });
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    emailCheck() {
        if (this.ValidateEmail.test(this.state.mailId)) {
            this.setState({ validEmail: 'true' });
            return 1;
        }
        else {
            this.setState({ validEmail: 'false' });
            return 0;
        }
    }
    ;
    showRequiredSupport() {
        this.setState({ requiredSupport: 'show' });
    }
    showDomain() {
        this.setState({ domain: 'show' });
    }
    assignDomainValue(value) {
        this.setState({ domainValue: value, domain: 'hide' });
    }
    assignRequriedValue(value) {
        this.setState({ requireValue: value, requiredSupport: 'hide' });
    }
    sendMail() {
        if (this.emailCheck()) {
            console.log(this.state);
        }
    }
    render() {
        return (React.createElement("main", null,
            React.createElement("section", { className: "sec-pd pt-lg-5" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h2", { className: "fnt-32 text-1818 fw-700 mb-4 mb-lg-5" }, "AitaHub Help and Support")),
                        React.createElement("div", { className: "col-12 col-md-4 col-lg-3" },
                            React.createElement("div", { className: "career-tabs sticky-element" },
                                React.createElement("ul", { className: "nav nav-pills mb-0 vertical-tab vertical-tab--contact", role: "tablist" },
                                    React.createElement("li", { className: "nav-item px-0" },
                                        React.createElement(Dom, { className: "nav-link active text-18 fw-600", to: "students-tab1", "data-toggle": "pill", role: "tab", "aria-controls": "students", "aria-selected": "true" }, "Students")),
                                    React.createElement("li", { className: "nav-item px-0 disbale" },
                                        React.createElement(Dom, { className: "nav-link text-18 fw-600", to: "Professionals-tab1", "data-toggle": "pill", role: "tab", "aria-controls": "Professionals", "aria-selected": "false" }, "Professionals")),
                                    React.createElement("li", { className: "nav-item px-0 disbale" },
                                        React.createElement(Dom, { className: "nav-link text-18 fw-600", to: "Corporates-tab1", "data-toggle": "pill", role: "tab", "aria-controls": "Corporates", "aria-selected": "false" }, "Companies")),
                                    React.createElement("li", { className: "nav-item px-0 disbale" },
                                        React.createElement(Dom, { className: "nav-link text-18 fw-600", to: "Corporates-tab", "data-toggle": "pill", role: "tab", "aria-controls": "Institutions", "aria-selected": "false" }, "Institutions")),
                                    React.createElement("li", { className: "nav-item px-0 disbale" },
                                        React.createElement(Dom, { className: "nav-link text-18 fw-600", to: "Institues-tab", "data-toggle": "pill", role: "tab", "aria-controls": "Other", "aria-selected": "false" }, "Other"))))),
                        React.createElement("div", { className: "col-12 col-md-8 col-lg-9" },
                            React.createElement("div", { className: "tab-content career-tabs__content responsive-tab-content pt-md-0" },
                                React.createElement("div", { className: "tab-pane show active fade responsive-tab-content__list", id: "students1", role: "tabpanel", "aria-labelledby": "students-tab1" },
                                    React.createElement("div", { className: "contact-option mb-3 mb-lg-4 d-flex flex-wrap clearfix" },
                                        React.createElement("div", { className: "contact-option__item float-left d-flex" },
                                            React.createElement("div", { className: "option-img" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/contact/call.svg" })),
                                            React.createElement("div", { className: "option-info" },
                                                React.createElement("h4", { className: "text-1216 fnt-20 fw-600" }, "Call us"),
                                                React.createElement(Link, { className: "text-1818 fnt-18 fw-400 link", to: "tel:+91 78925 45778" }, "+91 78925 45778"))),
                                        React.createElement("div", { className: "contact-option__item float-left d-flex" },
                                            React.createElement("div", { className: "option-img" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/contact/mail.svg" })),
                                            React.createElement("div", { className: "option-info" },
                                                React.createElement("h4", { className: "text-1216 fnt-20 fw-600" }, "Email"),
                                                React.createElement(Link, { className: "text-1818 fnt-18 fw-400 link", to: "mailto:contact@aitahub.com" }, "contact@aitahub.com")))),
                                    React.createElement("div", { className: "contact-option mb-3 mb-lg-4" },
                                        React.createElement("h4", { className: "contact-option__head fnt-20 text1818 fw-600" }, "Write to us"),
                                        React.createElement("form", { id: "conatct-form", autoComplete: "off", "data-parsley-validate": "", method: "post" },
                                            React.createElement("div", { className: "row" },
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "form-required col-md-12 col-lg-6" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("input", { className: "input", type: "text", id: "name", autoComplete: "off", required: true, onChange: this.onValueChange }),
                                                                React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.name !== '' }) }, "Full Name"),
                                                                React.createElement("span", { className: "form-fields__border" }))))),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "form-required col-md-12 col-lg-6" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("input", { className: "input", type: "email", id: "mailId", autoComplete: "off", required: true, onChange: this.onValueChange }),
                                                                React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.mailId !== '' }) }, "Email Address"),
                                                                React.createElement("span", { className: "form-fields__border" }))))),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "form-required col-md-12 col-lg-6" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("input", { className: "input", type: "number", id: "contact", autoComplete: "off", "data-parsley-minlength": "10", "data-parsley-maxlength": "10", "data-parsley-type": "digits", "data-parsley-error-message": "Enter valid 10 digit number", required: true, onChange: this.onValueChange }),
                                                                React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.contact !== '' }) }, "Contact Number"),
                                                                React.createElement("span", { className: "form-fields__border" }))))),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "form-required col-md-12 col-lg-6" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("input", { className: "hidden-input", type: "text", required: true }),
                                                                React.createElement("input", { className: "input-check", type: "checkbox", value: this.state.requireValue, autoComplete: "off", onClick: () => this.showRequiredSupport() }),
                                                                React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.requireValue !== '' }) }, "Require support regarding"),
                                                                React.createElement("span", { className: "form-fields__border" }),
                                                                React.createElement("div", { className: "selected-item" },
                                                                    React.createElement("div", { className: "selected-item__text" }, this.state.requireValue)),
                                                                React.createElement("div", { className: classNames("select-dropdown", { "hideRequireSupport": this.state.requiredSupport === 'hide', "showRequiredSupport": this.state.requiredSupport === 'show' }) },
                                                                    React.createElement("div", { className: "select-dropdown__item" },
                                                                        React.createElement("ul", { className: "select-list scroll-container position-relative mb-0 ps" },
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignRequriedValue("Computer Science") },
                                                                                React.createElement("label", null,
                                                                                    "Computer Science",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Computer Science", "data-parsley-multiple": "technology-name" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignRequriedValue("Science") },
                                                                                React.createElement("label", null,
                                                                                    "Science",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Science", "data-parsley-multiple": "technology-name" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignRequriedValue("Technology") },
                                                                                React.createElement("label", null,
                                                                                    "Technology",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Technology", "data-parsley-multiple": "technology-name" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignRequriedValue("Engineering") },
                                                                                React.createElement("label", null,
                                                                                    "Engineering",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Engineering", "data-parsley-multiple": "technology-name" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignRequriedValue("Mathematics") },
                                                                                React.createElement("label", null,
                                                                                    "Mathematics",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Mathematics", "data-parsley-multiple": "technology-name" }))),
                                                                            React.createElement("div", { className: "ps__rail-x", style: { left: "0px", bottom: "0px" } },
                                                                                React.createElement("div", { className: "ps__thumb-x", tabIndex: 0, style: { left: "0px", width: "0px" } })),
                                                                            React.createElement("div", { className: "ps__rail-y", style: { top: "0px", right: "0px" } },
                                                                                React.createElement("div", { className: "ps__thumb-y", tabIndex: 0, style: { top: "0px", height: "0px" } }))))))),
                                                        React.createElement("div", { className: "form-required col-md-12 col-lg-6" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("input", { className: "hidden-input", type: "text", required: true }),
                                                                React.createElement("input", { className: "input-check", type: "checkbox", autoComplete: "off", onClick: () => this.showDomain() }),
                                                                React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.domainValue !== '' }) }, "Domain"),
                                                                React.createElement("span", { className: "form-fields__border" }),
                                                                React.createElement("div", { className: "selected-item" },
                                                                    React.createElement("div", { className: "selected-item__text" }, this.state.domainValue)),
                                                                React.createElement("div", { className: classNames("select-dropdown", { "hideDomain": this.state.domain === 'hide', "showDomain": this.state.domain === 'show' }) },
                                                                    React.createElement("div", { className: "select-dropdown__item" },
                                                                        React.createElement("ul", { className: "select-list scroll-container position-relative mb-0 ps" },
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignDomainValue("Computer Science") },
                                                                                React.createElement("label", null,
                                                                                    "Computer Science",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "domain-name", value: "Computer Science", "data-parsley-multiple": "domain-name" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignDomainValue("Science") },
                                                                                React.createElement("label", null,
                                                                                    "Science",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "domain-name", value: "Science", "data-parsley-multiple": "domain-name" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignDomainValue("Technology") },
                                                                                React.createElement("label", null,
                                                                                    "Technology",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "domain-name", value: "Technology", "data-parsley-multiple": "domain-name" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignDomainValue("Engineering") },
                                                                                React.createElement("label", null,
                                                                                    "Engineering",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "domain-name", value: "Engineering", "data-parsley-multiple": "domain-name" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignDomainValue("Mathematics") },
                                                                                React.createElement("label", null,
                                                                                    "Mathematics",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "domain-name", value: "Mathematics", "data-parsley-multiple": "domain-name" }))),
                                                                            React.createElement("div", { className: "ps__rail-x", style: { left: "0px", bottom: "0px" } },
                                                                                React.createElement("div", { className: "ps__thumb-x", tabIndex: 0, style: { left: "0px", width: "0px" } })),
                                                                            React.createElement("div", { className: "ps__rail-y", style: { top: "0px", right: "0px" } },
                                                                                React.createElement("div", { className: "ps__thumb-y", tabIndex: 0, style: { top: "0px", height: "0px" } }))))))))),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "form-required col-md-12" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("textarea", { className: "w-100 input", name: "description", rows: 12, cols: 8, placeholder: "Write your message here *" }),
                                                                React.createElement("span", { className: "form-fields__border" }))))),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "form-submission" },
                                                        React.createElement("button", { className: "btn btn--skew", type: "submit", onClick: () => this.sendMail() }, "Send")))))),
                                    React.createElement("div", { className: "contact-option mb-3 mb-lg-4" },
                                        React.createElement("h4", { className: "fnt-20 fw-600 text-1216" }, "Thanks for your interest in AiTAHUB!"),
                                        React.createElement("div", { className: "info" },
                                            React.createElement("h6", { className: "fnt-18 text-1818 fw-500" }, "Jobs"),
                                            React.createElement("p", { className: "fnt-18 text-1818 fw-500" },
                                                "To view open positions at Aitahub, write us at\u00A0",
                                                React.createElement(Link, { style: { textDecoration: "none" }, className: "link text-5877", to: "mailto: contact@aitahub.com" }, "contact@aitahub.com"))),
                                        React.createElement("div", { className: "info" },
                                            React.createElement("h6", { className: "fnt-18 text-1818 fw-500" }, "General Queries"),
                                            React.createElement("p", { className: "fnt-18 text-1818 fw-500" },
                                                "For general questions, comments, and suggestions, get in touch at\u00A0",
                                                React.createElement(Link, { style: { textDecoration: "none" }, className: "link text-5877", to: "mailto: contact@aitahub.com" }, "contact@aitahub.com")))),
                                    React.createElement("div", { className: "contact-option mb-3 mb-lg-4 position-relative p-0" },
                                        React.createElement("div", { className: "visit d-flex" },
                                            React.createElement("div", { className: "visit__item pb-0 m-0" },
                                                React.createElement("h5", { className: "mb-2 mb-md-3" }, "Visit us at"),
                                                React.createElement("address", null,
                                                    React.createElement("p", { className: "mb-1 fnt-18 fw-400 text-1818" }, "#10, 3rd Floor,"),
                                                    React.createElement("p", { className: "mb-1 fnt-18 fw-400 text-1818" }, "Sumukha Towers,"),
                                                    React.createElement("p", { className: "mb-1 fnt-18 fw-400 text-1818" }, "Outer Ring Road, Ittamadu,"),
                                                    React.createElement("p", { className: "mb-1 fnt-18 fw-400 text-1818" }, "BSK III Stage Bengaluru"),
                                                    React.createElement("p", { className: "mb-1 fnt-18 fw-400 text-1818" }, "560 085,"),
                                                    React.createElement("p", { className: "mb-1 fnt-18 fw-400 text-1818" }, "INDIA."))),
                                            React.createElement("div", { className: "visit__item map p-0 m-0" },
                                                React.createElement("div", { id: "map" }))))),
                                React.createElement("div", { className: "tab-pane fade responsive-tab-content__list", id: "Professionals1", role: "tabpanel", "aria-labelledby": "Professionals-tab1" },
                                    React.createElement("p", null, "Professionals")),
                                React.createElement("div", { className: "tab-pane fade responsive-tab-content__list", id: "Corporates1", role: "tabpanel", "aria-labelledby": "Corporates-tab1" },
                                    React.createElement("p", null, "Companies")),
                                React.createElement("div", { className: "tab-pane fade responsive-tab-content__list", id: "Institutions1", role: "tabpanel", "aria-labelledby": "Professionals-tab1" },
                                    React.createElement("p", null, "Institutions")),
                                React.createElement("div", { className: "tab-pane fade responsive-tab-content__list", id: "Other", role: "tabpanel", "aria-labelledby": "Corporates-tab1" },
                                    React.createElement("p", null, "Other")))))))));
    }
}
export default Contact;