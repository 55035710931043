
import React from "react";
import { Link } from "react-router-dom";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import Service from "../../service/service";
import Rating from '@mui/material/Rating';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
// import "./Dashboard/Dashboard.scss";
import Divider from '@mui/material/Divider';
import { FaPlayCircle } from 'react-icons/fa';
import { BlockUI } from 'primereact/blockui';
import { Panel } from 'primereact/panel';
import { Grid,  Typography } from "@mui/material";
import Card from "@mui/material/Card";

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Breadcrums from "../Breadcrums/Breadcrums";
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ProgressSpinner } from 'primereact/progressspinner';
class Courses extends React.Component {
    state  = {};
    props;
    programs = [];
    blockedPanel  = true;
    breadcrums = [
        {
          page: "HOME",
          route: "/dashboard"
        },
        {
          page: "My Courses",
          route: "/Courses"
        }
        
      ]
    // breadcrums = ["Home","Mycourses"];
    state ={
        datavalue : []
    }
    state ={
        placement: 'right'
    }
    state ={
        email:''
    }
    constructor(props) {
        super(props);
        this.props = props;
        this.state = { anchorEl: null, open: false };
        var userDetails  = sessionStorage.getItem("userDetails");
        
        if(userDetails != null){
            this.state.email = JSON.parse(userDetails).data.email;
            new Service().getUserByEmail(JSON.parse(userDetails).data.email).then((res)=>{
                var userDetails  = {data : res.data,program_ids : []};
                
                res.data.program_list.forEach((ele)=>{
                    userDetails.program_ids.push(ele.id);
                    this.getProgramDetails(ele.id);
                });
                sessionStorage.setItem("userDetails",JSON.stringify(userDetails));
              },(error)=>{
                sessionStorage.removeItem("userDetails");
                this.props.history.push("/");
              }).catch(err=>{
                this.blockedPanel = false;
              });
        }else{
            this.props.history.push("/");
        }
    }
    
    getProgramDetails(programId){
        new Service().getProgramDetailsById(programId).then((res)=>{
            this.programs.push(res.data);
            this.programs.forEach((ele)=>{
                ele.course_list.forEach((elem)=>{
                    
                    this.getCourseThumbnail(elem.thumbnail.id);
                });
            });
            this.blockedPanel = false;
            this.setState(this.programs);
        }).catch((err)=>{
            new Service().handleErrResponse();
            this.blockedPanel = false;
            this.props.history.push("/");
        });
    }
    getImageAsset(assetId) {
        return new Service().getImageAsset(assetId);
      };
    getPublishedCourseList(element){
        return element.is_published === true;
    }
    handlepopper = (newPlacement,course,event) => {
        this.state.anchorEl
          ? this.setState({ anchorEl: null })
          : this.setState({ anchorEl: event.currentTarget });
        this.setState({ datavalue: course });
        console.log(this.state.Indexvalue)
        if(this.state.open === false){
        this.setState({ open: ((prevState) => this.state.placement !== newPlacement || !prevState.open) });
        }else{
          this.setState({
            open: false
          });
        }
        this.setState({ placement: newPlacement });
        console.log(this.state.open)
        
    }
    getCourseThumbnail(assetId){
        const imageProps  = new Service().getCourseThumbnail(assetId);
        fetch(imageProps.url, imageProps.option).then((res)=>{
            if(res.status === 200){
                res.blob().then((blob)=>{
                    var imgElement = document.getElementById('thumbnail_'+assetId);
                    imgElement.src = URL.createObjectURL(blob);
                });
            }else if(res.status === 401){
                this.props.history.push("/");
            }
        }).catch((err)=>{
            console.log(err);
        });
    }

    render() {
        console.log("data",this.programs)
        console.log("email",this.state.email)
        return (
            <main>
                <AdminNavbar menu="mycourse" navbar="" />
                <div className="container" style={{paddingTop:"30px",paddingLeft:'36px'}}>
                <Typography sx={{fontWeight:400,fontSize: '1.55rem',mt:'1rem',ml:'2rem'}}>Courses</Typography>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                    marginLeft:'2rem',
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                    }}
                >
                    <Breadcrums pages={this.breadcrums} />
              </Grid>
                <Popper  open={this.state.open}  anchorEl={this.state.anchorEl} placement={this.state.placement} transition >
                      {({ TransitionProps }) => (
                      
                        <Fade {...TransitionProps} timeout={50}>
                          <Card sx={{marginLeft:"auto",height:"auto",borderRadius:"0.5rem",width:"auto",padding:"1rem",maxWidth:"304px"}} display ="flex">
                            <Grid container sx={{width:'100%'}}  columns={12}  >
                              <Grid item lg={2} md={2} sm={2} xs={2}  sx={{paddingLeft:1,paddingTop:1}}  >
                                <img   src={this.getImageAsset(this.state.datavalue.thumbnail.id)} alt=""  style = {{borderRadius:"0.25rem",height:'40px',width:'40px',aspectRatio:"auto 40 / 40",objectFit:'cover'}} />

                              </Grid>
                              <Grid item lg={10} md={10} sm={10} xs={10}    >
                                <Typography variant="h7" component="div" sx={{ fontSize: '1rem',fontFamily:"Exo 2,Helvetica Neue,Arial,sans-serif",color:"#303956",fontWeight:'500',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                                  {this.state.datavalue.title} 
                                </Typography>
                                <Typography variant="h7" component="div" sx={{ fontSize: '11px',fontWeight:'bold',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                                  Test user
                                </Typography>

                              </Grid>
                            </Grid>
                            <Grid container   columns={12}  >
                              <Grid item lg={12} md={12} sm={12} xs={12}    >
                                <Typography variant="h7" component="div" sx={{color:"rgba(39,44,51,.7)", fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",fontSize: '.8125rem',fontWeight:'400',flexGrow: 1 ,paddingLeft:1,paddingTop:"1rem",paddingBottom:"1rem"}}>
                                  {this.state.datavalue.description} 
                                </Typography>
                                

                              </Grid>
                            </Grid>

                            
                            
                              <Grid spacing={1} container columns={12}  >
                                <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                                <OpenInNewIcon fontSize="small" sx={{marginLeft:1}} />
                                </Grid>
                                <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                                <Typography variant="h7" component="div" sx={{color:"rgba(39,44,51,.5)", fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",fontSize: '.8125rem',fontWeight:'400',flexGrow: 1}}>
                                    Goals and Learning objectives are clearly described
                                </Typography>
                                </Grid>
                                <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                                <OpenInNewIcon fontSize="small" sx={{marginLeft:1}} />
                                </Grid>
                                <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                                <Typography variant="h7" component="div" sx={{color:"rgba(39,44,51,.5)", fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",fontSize: '.8125rem',fontWeight:'400',flexGrow: 1}}>
                                    The description is written from a student - centered perspective
                                </Typography>
                                </Grid>
                                {/* <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                                <OpenInNewIcon fontSize="small" sx={{marginLeft:1}} />
                                </Grid> */}
                                {/* <a className="apply-here" href="/"  target="_blank">Apply Here Now</a> */}
                                {/* <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
                                <Typography variant="h7" component="div" sx={{color:"rgba(39,44,51,.5)", fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",fontSize: '.8125rem',fontWeight:'400',flexGrow: 1}}>
                                    There is no repetition of content in different sections
                                </Typography>
                                </Grid> */}
            
                              </Grid>
                              
                        
                            <div style={{marginTop:'1rem'}}>   
                            <Grid container columns={12}  >
                                <Grid align="Left"  item lg={1.5} md={1.5} sm={1.5} xs={1.5}> 
                                <AssessmentIcon fontSize="24px" sx={{marginLeft:1}} />  
                                </Grid>
                                <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5}  >
                                <Typography  component="div" sx={{marginTop:'.20rem',ml:'.20rem' ,fontSize: '.9005rem',fontWeight:'400',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}>
                                    {this.state.datavalue.due_in}
                                </Typography>
                                </Grid>
                                <Grid align="center" item lg={6} md={6} sm={6} xs={6}>
                                                {/* <Button onClick={()=>this.onApplyProgram(item)}  sx={{bgcolor:'#f95877',textAlign:'center',padding:"9px",color:"white",fontSize:".7025rem",fontWeight:"600",fontFamily:"Exo\ 2,Helvetica Neue,Arial,sans-serif",display:"inline",pt:'2px',pb:'2px'}}>Apply here</Button> */}
                                                <Link to={{ pathname: '/coursepreview', state: { courseid:this.state.datavalue.id } }} style={{ textDecoration: "none",color: "#000000"  }}>
                                                <span className="launch-link">
                                                    <FaPlayCircle className="play-course"/>
                                                    <span className="play-text">
                                                        &nbsp;Launch
                                                    </span>
                                                </span> 
                                                </Link>
                                </Grid>
                            </Grid>
                            </div>
                              {/* </Grid> */}
                              {/* <Grid align="right" item lg={6} md={6} sm={6} xs={6} >
                              <Box display="flex"  sx={{mr:1}}>
                              <AttachMoneyIcon   fontSize="24px" sx={{mr:.5,ml:1}} />  


                              <Typography variant = "caption">{users[Indexvalue].price}</Typography>
                              </Box>
                              </Grid>
                              <Grid align="left" item lg={6} md={6} sm={6} xs={6} >
                              <Box display="flex"  sx={{ml:1}}>
                              <AutoStoriesIcon fontSize="24px" sx={{mr:.5,ml:1}} />  


                              <Typography variant = "caption">Course {users[Indexvalue].course_list.length}</Typography>
                                </Box>
                              </Grid> */}
                              
                              
                              
                            
                            
                          </Card>
                        </Fade>
                      
                      )}
                </Popper>
                    <div className="row" style={{marginTop:'.5rem'}}>
                    {
                        this.programs.map((program,index)=>(
                            <div key={index}>
                                <div className="program-title-container" style={{marginLeft:'2rem',marginTop:'1rem'}}>
                                <Typography sx={{fontWeight:400,fontSize: '1.55rem',marginRight:'5px'}}>{program.title}</Typography>
                                    <h6>(Courses : &nbsp;
                                        <span style={{color:"#fa5a78",fontWeight:"bold"}}>{program.course_list.length}</span>)
                                    </h6>
                                </div>
                                <div style={{marginLeft:'2rem',marginTop:'1rem'}}>
                                <Grid  container spacing={3} columns={12}  >
                                   {program.course_list.filter(this.getPublishedCourseList).map((course,index)=>(
                                    <Grid item lg={3} md={4} sm={6} xs={6}  sx={{marginTop:2}}  >
                                        <Card onClick={event => this.handlepopper('right',course,event)} sx={{borderRadius:"0.5rem",maxHeight:'265px',mb:'1rem',borderBottom:1}} display ="block">
                                        <img   src='' id={"thumbnail_"+course.thumbnail.id}  alt=""  style = {{height:'132px',overflow:'hidden',position:'relative',width:'100%',objectFit:'fill'}} />
                                        <Divider />
                                        <Grid container spacing={1} sx={{maxHeight:"110px",minHeight:"110px"}} columns={12}  >
                                            <Grid align="left" sx={{MarginTop:3}} item lg={8} md={8} sm={8} xs={8}  >
                                            <Typography  component="div" sx={{ fontSize: '1rem',color:'#303956',fontFamily:'Exo 2,Helvetica Neue,Arial,sans-serif',fontWeight:'620',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                                                {course.title} 
                                            </Typography>
                                            <Typography variant="h7" component="div" sx={{ fontSize: '.8125rem',color:"#272c33",fontWeight:'400',fontHeight:'1.5',fontFamily:'Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',flexGrow: 1 ,paddingLeft:2,paddingTop:0.6}}>
                                                Test user
                                            </Typography>
                                            </Grid>
                                            <Grid align="left" sx={{MarginTop:3}} item lg={8} md={8} sm={8} xs={8}  >
                                            <Rating
                                                name="simple-controlled"
                                                value={3}
                                                sx={{paddingLeft:1.5}}
                                                size="small"
                                                // onChange={(event, newValue) => {
                                                // setValue(newValue);
                                                // }}
                                            />
                                            </Grid>
                                            
                                        
                                            
                                            {/* <Grid align="right" sx={{paddingTop:0}} item lg={4} md={4} sm={4} xs={4}  >
                                            <IconButton
                                                    aria-label="more"
                                                    id="long-button"
                                                    
                                                    aria-haspopup="true"
                                                    onClick={() => handleIconClick()}
                                                    >
                                                    {clicked ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
                                            </IconButton>
                                            
                                            </Grid> */}
                                        </Grid>
                                        <Divider />
                                        <div style={{paddingTop:'.25rem',paddingBottom:'.25rem',display:'flex',flexDirection:'row',justifyContent:'space-around',alignContent:'center'}}>
                                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        
                                            
                                                <AccessTimeIcon   sx={{fontSize:'22px'}} />
                                            
                                                
                                                
                                            
                                            <Typography  sx={{ml:'4px',fontSize: '.9225rem',fontWeight:'500',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}  >
                                                {course.duration}
                                            </Typography>

                                            </div>
                                            {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                            
                                                    <AutoStoriesTwoToneIcon sx={{fontSize:'22px'}} />
                                            

                                            
                                            <Typography   sx={{ml:'4px',fontSize: '.8205rem',fontWeight:'400',fontFamily:"Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}>
                                                12 Lessons
                                            </Typography>

                                        
                                        </div> */}
                                        </div>
                                        
                                            

                                        
                                        </Card>
                                        
                                    </Grid>
                                    
                                    
                                    ))}
                                    
                                </Grid>
                                </div>
          
                                
                            </div>
                        ))
                    }
                    </div>
                </div>
                <BlockUI blocked={this.blockedPanel} fullScreen>
                    <Panel>
                    <div style={{textAlign:"center"}}>
                        <ProgressSpinner hidden={!this.blockedPanel}/>
                    </div>
                    </Panel>
                </BlockUI>
            </main>
        );
    }
}
export default Courses;
