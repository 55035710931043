
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import './custom-quill.css';

export default function RichtextEditor({ onDataChange }) {
  const [value, setValue] = useState('');
  
  const handleSave = (content) => {
    setValue(content);
    onDataChange(content); // Pass the content to the parent component
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'align',
    'list',
    'link',
    'image',
  ];

  const editorStyle = {
    height: '200px', // Adjust the height as needed
    zIndex: 9999, // Adjust the value as needed
  };

  return (
    <div style={{ height: '200px' }}>
      <ReactQuill
        value={value}
        onChange={handleSave}
        modules={modules}
        formats={formats}
        placeholder="Type a message..."
        theme="snow"
        style={editorStyle}
      />
    </div>
  );
}
