import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useHistory, useLocation } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";


export default function DashboardBreadcrumbs({pages, courseid,courseitem}) {
  const history = useHistory();
  const location = useLocation();

  const handleClick = (event, route) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
    // Navigate to the route, passing the courseid if available
    history.push({ pathname: route, state:{courseid:courseid,courseitem:courseitem} });
  };

  return (
    <div>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {pages.map((breadcrumb, id) => {
            const { page, route } = breadcrumb;

            if (route === location.pathname) {
              return (
                <Typography key={id} variant="button">
                  {page}
                </Typography>
              );
            }

            return (
              <Link
                key={id}
                underline="none"
                to={route}
                style={{ textDecoration: "none", color: "grey" }}
                onClick={(event) => handleClick(event, route)}
              >
                <Typography variant="button">{page}</Typography>
              </Link>
            );
          })}
        </Breadcrumbs>
      </div>
    </div>
  );
}
