import React from "react";
import "./programs.scss";
import Service from "../../service/service";
import ReactPaginate from "react-paginate";
import SearchFilter from "../SearchFilter";
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { BlockUI } from 'primereact/blockui';
import { Panel } from 'primereact/panel';
class Programs extends React.Component {
    constructor(props) {
        super(props);
        this.selectedProgramList = [];
        this.programList = [];
        this.currentProgramList = [];
        this.categories = [];
        this.userData = {};
        this.perPage = 5;
        this.datasOnPage = [];
        this.blockedPanel = true;
        this.headerStyleProp = {};
        this.selectedCategory = {};
        this.state = {
            existingUser: false
        };
        this.onSelectCategory = (event) => {
            this.selectedProgramList = [];
            this.programList.forEach((element) => {
                const selectedCategory = event.target ? event.target.value : event.value;
                if (element.category === selectedCategory && selectedCategory !== "All_PROGRAMS") {
                    this.selectedProgramList.push(element);
                }
                else if (selectedCategory === "All_PROGRAMS") {
                    this.selectedProgramList = this.programList;
                }
            });
            this.datasOnPage = [...this.selectedProgramList].slice(0, this.perPage);
            this.setState(this.datasOnPage);
        };
        this.handlePageClick = (data) => {
            let selected = data.selected + 1;
            this.datasOnPage = [...this.selectedProgramList].slice(this.perPage * data.selected, this.perPage * selected);
            this.setState(this.datasOnPage);
        };
        this.onSearch = (event) => {
            this.currentProgramList = SearchFilter(this.programList, event.target.value);
            this.datasOnPage = [...this.currentProgramList].slice(0, this.perPage);
            this.setState(this.datasOnPage);
        };
        this.props = props;
        var userDetails = sessionStorage.getItem("userDetails") || undefined;
        if (userDetails) {
            this.state.existingUser = true;
            this.userData = JSON.parse(userDetails).data;
        }
        else {
            this.state.existingUser = false;
        }
        if (this.props.userNonRegPrograms !== undefined) {
            this.headerStyleProp = this.props.headerStyleProp;
            this.initialize(this.props.userNonRegPrograms);
        }
        else {
            new Service().getProgramDetails().then((response) => {
                this.initialize(response.data);
            }).catch((err) => {
                new Service().handleErrResponse();
                this.props.history.push("/");
            });
        }
        this.getProgramGroups();
    }
    initialize(programDetails) {
        this.programList = programDetails;
        this.currentProgramList = [...this.programList];
        this.selectedProgramList = this.programList;
        this.datasOnPage = [...this.selectedProgramList].slice(0, this.perPage);
        console.log(this.datasOnPage);
        this.blockedPanel = false;
        if (this.props.location !== undefined && this.props.location.state !== undefined) {
            if (this.props.location.state.selectedCategory !== undefined) {
                this.selectedCategory = this.props.location.state.selectedCategory;
                this.onSelectCategory(this.props.location.state.selectedCategory);
            }
        }
        this.setState(this.selectedProgramList);
    }
    getImageAsset(assetId) {
        return new Service().getImageAsset(assetId);
    }
    ;
    getAssetPDF(assetId) {
        return new Service().getDocumentPDF(assetId);
    }
    getProgramGroups() {
        this.categories = new Service().getCategories();
        this.selectedCategory = this.categories[0];
    }
    onApplyProgram(selectedProgram) {
        if (this.state.existingUser) {
            if (selectedProgram.price === 0 || selectedProgram.offer_price === 0) {
                this.enrollForFreeProgram(this.userData, selectedProgram);
            }
            else {
                this.props.history.push({
                    pathname: "/payment",
                    state: {
                        user_details: this.userData,
                        program: selectedProgram
                    }
                });
            }
        }
        else {
            this.props.history.push({ pathname: "/applycourse", state: selectedProgram });
        }
    }
    enrollForFreeProgram(user, selectedProgram) {
        let program_list = [];
        user.program_list.forEach((ele) => {
            program_list.push(ele.id);
        });
        program_list.push(selectedProgram.id);
        user.program_list = program_list;
        new Service().updateUser(user).then((res) => {
            new Service().getUserByEmail(user.email).then((res) => {
                var userDetails = { data: res.data, program_ids: [] };
                res.data.program_list.forEach((ele) => { userDetails.program_ids.push(ele.id); });
                sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
                this.props.history.push("/courses");
            });
        });
    }
    render() {
        return (React.createElement("section", { className: "sec-pd section-home-product", style: this.headerStyleProp },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "header" },
                        React.createElement("h1", null, "Programs"),
                        React.createElement("span", { className: "p-input-icon-left" },
                            React.createElement("i", { className: "pi pi-search" }),
                            React.createElement(InputText, { onChange: this.onSearch, style: { paddingTop: "5px", paddingBottom: "5px", cursor: "text" }, placeholder: "Search" }))),
                    React.createElement("div", { className: "col-12 col-md-4 col-lg-4 dropDownContainer", style: { backgroundColor: "#f6f8ff" } },
                        React.createElement("div", null,
                            React.createElement("h5", { className: "dropDownHeading" }, "Categories"),
                            React.createElement("select", { className: "dropDownElem", onChange: this.onSelectCategory, defaultValue: this.selectedCategory }, this.categories.map((category, index) => {
                                return React.createElement("option", { key: index, value: category.value, selected: category.value === this.selectedCategory.value }, category.name);
                            })))),
                    React.createElement("div", { className: "col-12 col-md-8 col-lg-9", style: { paddingLeft: "40px" } },
                        this.datasOnPage.map((program, index) => (React.createElement("div", { className: "row card-container", key: index },
                            React.createElement("div", { className: "col-12 col-md-3 col-lg-3 avatar" },
                                React.createElement("img", { className: "thumbnail", src: this.getImageAsset(program.thumbnail_asset_id), alt: "" })),
                            React.createElement("div", { className: "col-12 col-md-9 col-lg-9 program-details" },
                                React.createElement("div", { className: "header-card" },
                                    React.createElement("h6", { style: { textTransform: "capitalize" } }, program.title),
                                    React.createElement("div", { className: "price-details" },
                                        React.createElement("span", { className: program.price !== 0 ? 'price' : 'offer-price' }, program.price === 0 ? 'free' : '₹' + program.price),
                                        program.price !== 0 &&
                                            React.createElement("span", { className: "offer-price" }, program.offer_price === 0 ? 'free' : '₹' + program.offer_price))),
                                React.createElement("div", { className: "description" }, program.description),
                                React.createElement("div", { className: "action-group" },
                                    React.createElement("a", { className: "brochure", href: this.getAssetPDF(program.brochure_asset_id), target: "_blank" }, "View Brochure"),
                                    React.createElement("a", { className: "demo", href: "/", target: "_blank" }, "Free Demo"),
                                    React.createElement("a", { className: "advice", href: this.getAssetPDF(program.program_advice_asset_id), target: "_blank" }, "Program Advice"),
                                    React.createElement("button", { className: "btn btn--skew d-none d-md-inline-block ml-3", onClick: () => this.onApplyProgram(program) }, "Apply Here Now")))))),
                        React.createElement(ReactPaginate, { previousLabel: '<', nextLabel: '>', breakLabel: '...', breakClassName: 'break-me', pageCount: Math.ceil(this.currentProgramList.length / this.perPage), marginPagesDisplayed: 2, pageRangeDisplayed: 3, onPageChange: this.handlePageClick, containerClassName: 'pagination', activeClassName: 'activeElement' })))),
            React.createElement(BlockUI, { blocked: this.blockedPanel, fullScreen: true },
                React.createElement(Panel, null,
                    React.createElement("div", { style: { textAlign: "center" } },
                        React.createElement(ProgressSpinner, { hidden: !this.blockedPanel }))))));
    }
}
export default Programs;