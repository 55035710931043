import { Dialog } from "@material-ui/core";
import { Email as EmailIcon } from "@mui/icons-material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { Alert, Box, Button, Card, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from '@mui/material/TextField';
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import * as React from "react";
import { QUIZDB_URL } from "../../constants/global";
import RichTextEditor from "./RichTextEditor";
import { useEffect } from "react";
import Service from "../../../service/service";
import { useHistory } from 'react-router-dom';

export default function ComposeBox(props) {
  const history = useHistory();

  const [userName, setUserName] = React.useState('');
  const [userMail, setUserMail] = React.useState('');

  const [To, setTo] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [Subject, setSubject] = React.useState('');
  const [richTextMessage, setRichTextMessage] = React.useState('');
  const [emptyTextAlert, setEmptyTextAlert] = React.useState(false);
 

  useEffect(() => {
    new Service()
      .getUserProfile()
      .then((res) => {
        setUserName(res.data.name)
        setUserMail(res.data.emailid)
      })
      .catch((error) => {
        if (error.toString().includes('401')) {
          history.push('/');
        }
      });
  }, [history]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const ComposeClose = () => {
    if (props.open !== undefined) {
      setTo('');
      props.close(false);
    }
    setSubject('');
    setRichTextMessage('');
    setOpen(false);
  };

  const SendMail = () => {
    if (!Subject || !richTextMessage) {
      setEmptyTextAlert(true);
    } else {
      props.Loading(true);

      let data = new FormData();
      data.append('recievermailidlist', To);
      data.append('subject', Subject);
      data.append('message', richTextMessage);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${QUIZDB_URL}/api/messagelist/${userMail}/${userName}`,
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          props.close(false);
          props.alert(true);
          props.Loading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRichTextDataChange = (data) => {
    setRichTextMessage(data);
  };

  return (
    <div>
      {!props.open ? (
        <Tooltip title="Compose">
          <IconButton onClick={handleClickOpen}>
            <EmailIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      <Card elevation={2}>
        <Dialog
          fullWidth
          open={open || props.open}
          sx={{ "& .MuiDialog-paper": { width: "md", maxHeight: "md" } }}
          maxWidth="md"
        >
          <DialogContent>
            <Grid container columns={12}>
              <Grid item md={10}>
                <Typography variant="h6">New Message</Typography>
              </Grid>
              <Grid item md={2}>
                <Stack direction="row" spacing={0} justifyContent="flex-end">
                  <IconButton>
                    <CloseFullscreenIcon />
                  </IconButton>
                  <IconButton onClick={ComposeClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            <Grid container columns={2} spacing={2} sx={{ marginTop: 1 }}>
              {props.open ? (
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    placeholder="To"
                    variant="standard"
                    value={To}
                    onChange={(e) => setTo(e.target.value)}
                  />
                </Grid>
              ) : null}
              <Grid item md={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  placeholder="Subject"
                  value={Subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container columns={12} sx={{ marginTop: 2 }}>
              <Grid item xs={12} lg={12} md={12}>
                <RichTextEditor onDataChange={handleRichTextDataChange} />
              </Grid>
            </Grid>
            <Grid container columns={12} sx={{ marginTop: 8 }}>
              <Grid item md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-start">
                  <Button
                    style={{
                      borderRadius: 8,
                      textTransform: "none",
                    }}
                    variant="contained"
                    onClick={SendMail}
                  >
                    Send
                  </Button>
                  <IconButton>
                    <AddPhotoAlternateIcon />
                  </IconButton>
                  <IconButton>
                    <AttachFileIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
            <Box sx={{ padding: 4 }}></Box>
          </DialogContent>
        </Dialog>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={emptyTextAlert}
        autoHideDuration={6000}
        onClose={() => setEmptyTextAlert(false)}
      >
        <Alert
          onClose={() => setEmptyTextAlert(false)}
          severity="warning"
          style={{ backgroundColor: '#fff4e5' }}
        >
          Fields can't be empty!
        </Alert>
      </Snackbar>
    </div>
  );
}
