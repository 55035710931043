import AddIcon from "@mui/icons-material/Add";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DraftsIcon from "@mui/icons-material/Drafts";
import ForumIcon from "@mui/icons-material/Forum";
import InboxIcon from "@mui/icons-material/Inbox";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import SendIcon from "@mui/icons-material/Send";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Alert, Box, Button, Card, CircularProgress, Dialog, DialogContent, Grid, Snackbar } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import * as React from "react";
import { useEffect } from "react";
import Service from "../../service/service";
import ComposeMail from "./ComposeMail";
import InboxMail from "./InboxMail";
import ViewMail from "./ViewMail";
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { QUIZDB_URL } from "../constants/global";
import axios from "axios";


export default function MailLeftBar() {

  const[unreadMsgCount,setUnreadMsgCount] = React.useState();
  const [ComposeFlag, setComposeFlag] = React.useState(false);
  const [inboxFlag, setInboxFlag] = React.useState(true);
  const [viewMailFlag, setViewMailFlag] = React.useState(false);
  const [sentFlag, setSentFlag] = React.useState(false);
  const [selectedMail, setSelectedMail] = React.useState(null);
  const [user, setUser] = React.useState();  
  const[Loading,setLoading] = React.useState(false);
  console.log("unreadMsgCount",unreadMsgCount)
  // alert
  const[alert,setAlert] = React.useState(false);

  useEffect(() => {
    new Service()
      .getUserProfile()
      .then((res) => {
      setUser(res.data)

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: QUIZDB_URL+'/api/messages/unreadcount/'+res.data.emailid,
        headers: { }
      };
      
      axios.request(config)
      .then((response) => {
        setUnreadMsgCount(response.data.unread_count)
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

    })

  }, [unreadMsgCount]);

  const handleUnreadMessageCount = () =>{
    if (user){

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: QUIZDB_URL+'/api/messages/unreadcount/'+user.emailid,
      // url: QUIZDB_URL+'/api/messages/unreadcount/venky1094@yahoo.com',
      headers: { }
    };
    
    axios.request(config)
    .then((response) => {
      setUnreadMsgCount(response.data.unread_count)
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
    }

  }

  const ComposeHandler = () => {
    setComposeFlag(true);
  };

  const inboxHandler = () =>{
    setViewMailFlag(false)
    setInboxFlag(true)
  }

  const handleViewMail = (mail) => {
    console.log("handleViewMail count",unreadMsgCount)
    setSelectedMail(mail);
    setInboxFlag(false);
    setViewMailFlag(true);
    handleUnreadMessageCount()
  };

  // const changeInboxHandler = () =>{
  //   setInboxFlag(false)
  // }
  console.log(sentFlag);
  const sentHandler = () =>{
    setInboxFlag(false)
    setSentFlag(true)
  }
  return (
    <Box sx  = {{pl:'6rem',mb:2}}>
    <Box sx = {{p:"3rem",mt:"-2rem"}} >

    <Dialog 
                        color="success"
                        PaperProps={{
                            style: {
                           
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={Loading} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                        <CircularProgress sx={{justifyContent:'center',alignItem:'center',ml:'2rem'}} />
                        <h4 style={{color:"#fff"}}>Loading...</h4>
                        </DialogContent>
                        </Dialog>
    <Card elevation={2} sx={{ marginBottom: 5 }}>
      {user && ComposeFlag ? (
        <ComposeMail open={ComposeFlag} close={setComposeFlag} user = {user} Loading = {setLoading} alert = {setAlert}/>
      ) : null}
      <Grid container columns={12}>
        <Grid item md={2.2}>
          <Grid container>
            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              lg={12}
              sx={{
                padding: "21px",
                borderBottom: 1,
                borderRight: 1,
                borderColor: "#e8e6e6",
              }}
            >
              {/* Compose icon */}
              <Stack direction="row" spacing={3} justifyContent="center">
                <Button
                  style={{
                    borderRadius: 8,
                    // backgroundColor: "rgb(0, 171, 85)",
                    padding: "4px 56px",
                    fontSize: "18px",
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={ComposeHandler}
                >
                  <AddIcon sx={{ marginRight: 1}} /> 
                  <Typography variant = "body1" sx ={{color:"white",fontWeight:500}}>Compose</Typography>
                </Button>
              </Stack>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              lg={12}
              sx={{
                borderBottom: 1,
                borderRight: 1,
                borderColor: "#e8e6e6",
              }}
            >
              {/* left menu icon */}

              <List sx = {{ textDecoration: "none", color: "#818e9a" }}>

                <div onClick={inboxHandler}>
                    <ListItem button component="div" key={2}>
                      <ListItemIcon>
                      {unreadMsgCount && unreadMsgCount > 0 ? (
                        <Tooltip title="Unread Messages">
                          <Badge 
                          color="error" 
                          badgeContent={unreadMsgCount}>
                            <InboxIcon  />
                          </Badge>
                        </Tooltip>):(
                        <InboxIcon  />)}

                      </ListItemIcon>
                        <ListItemText primary={"Inbox"} />
                    </ListItem>
                </div>
                {/* </Link> */}

                <div onClick = {sentHandler}>
                <ListItem button key={11} >
                  <ListItemIcon >
                    <SendIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Sent"} />
                </ListItem>
                </div>

                <ListItem button key={3}>
                  <ListItemIcon>
                    <DraftsIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Drafts"} />
                </ListItem>

                <ListItem button key={4}>
                  <ListItemIcon>
                    <DeleteForeverOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Trash"} />
                </ListItem>

                <ListItem button key={5}>
                  <ListItemIcon>
                    <DangerousOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Spam"} />
                </ListItem>

                <ListItem button key={6}>
                  <ListItemIcon>
                    <LabelImportantIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Important"} />
                </ListItem>
                <ListItem button key={7}>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Starred"} />
                </ListItem>

                <ListItem button key={8}>
                  <ListItemIcon>
                    <ShareIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Social"} />
                </ListItem>
                <ListItem button key={9}>
                  <ListItemIcon>
                    <VerifiedUserIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Promotion"} />
                </ListItem>

                <ListItem button key={10}>
                  <ListItemIcon>
                    <ForumIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Forums"} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={9.8}>
          <Grid container>
           { inboxFlag? <InboxMail  handleViewMailFlag = {setViewMailFlag} handleViewMail={handleViewMail}
           handleInboxFlag= {setInboxFlag} handleUnreadMessageCount = {handleUnreadMessageCount}
           /> :  null}
           { viewMailFlag? <ViewMail handleViewMailFlag = {setViewMailFlag} ViewMailData = {selectedMail}
            handleInboxFlag= {setInboxFlag}  
            
            /> :  null}


          </Grid>
        </Grid>
      </Grid>
    </Card>

                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }} open={alert} autoHideDuration={6000} onClose={(e) => setAlert(false)}>
                    <Alert onClose={(e) => setAlert(false)} severity = "success" >
                        Mail Sent Successfully!
                    </Alert>
                </Snackbar>
    </Box >

    </Box>
  );
}
