import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import "../../components/Header/Header.scss";
import "../Home/Home.scss";
import "./Career.scss";
import "../../styles/Link.scss";
export default class Carrer extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            active: 'student'
        };
    }
    setActive(value) {
        this.setState({ active: value });
    }
    render() {
        return (React.createElement("section", { className: "section-home-career sec-pd sec-pd--top position-relative" },
            React.createElement("div", { className: "tab-bg" }),
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "career-head text-center" },
                            React.createElement("h2", { className: "career-head__title fnt-32 text-1818 fw-700" }, "Prepare for Your Dream Job")),
                        React.createElement("div", { className: "career-tabs" },
                            React.createElement("ul", { className: "nav nav-pills mb-0 career-tabs__head responsive-list-tabs col-md-8 col-lg-7 col-xl-6 mx-auto slick-initialized slick-slider", role: "tablist" },
                                React.createElement("div", { className: "slick-list draggable" },
                                    React.createElement("div", { className: "slick-track", style: { opacity: "1", width: "15000px", transform: "translate3d(0px, 0px, 0px)" } },
                                        React.createElement("div", { className: classNames("slick-slide slick-active", { "slick-current": this.state.active === "student" }), "data-slick-index": "0", "aria-hidden": "false", onClick: () => this.setActive("student") },
                                            React.createElement("div", null,
                                                React.createElement("li", { className: "nav-item px-0", style: { width: "100%", display: "inline-block" } },
                                                    React.createElement("button", { className: "nav-link active", id: "students-tab", "data-toggle": "pill", role: "tab", "aria-controls": "students", "aria-selected": "true", tabIndex: 0 }, "Students")))),
                                        React.createElement("div", { className: classNames("slick-slide slick-active", { "slick-current": this.state.active === "Professionals" }), "data-slick-index": "1", "aria-hidden": "false", onClick: () => this.setActive("Professionals") },
                                            React.createElement("div", null,
                                                React.createElement("li", { className: "nav-item px-0", style: { width: "100%", display: "inline-block" } },
                                                    React.createElement("button", { className: "nav-link", id: "Professionals-tab", "data-toggle": "pill", role: "tab", "aria-controls": "Professionals", "aria-selected": "false", tabIndex: 0 }, "Professionals")))),
                                        React.createElement("div", { className: classNames("slick-slide slick-active", { "slick-current": this.state.active === "Corporates" }), "data-slick-index": "2", "aria-hidden": "false", onClick: () => this.setActive("Corporates") },
                                            React.createElement("div", null,
                                                React.createElement("li", { className: "nav-item px-0", style: { width: "100%", display: "inline-block" } },
                                                    React.createElement("button", { className: "nav-link", id: "Corporates-tab", "data-toggle": "pill", role: "tab", "aria-controls": "Corporates", "aria-selected": "false", tabIndex: 0 }, "Companies"))))))),
                            React.createElement("div", { className: "tab-content career-tabs__content responsive-tab-content" },
                                this.state.active === "student" &&
                                    React.createElement("div", { className: "tab-pane show active fade responsive-tab-content__list show", id: "students", role: "tabpanel", "aria-labelledby": "students-tab" },
                                        React.createElement("div", { className: "row" },
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "tab-space flex-wrap clearfix", style: { display: "inline-flex" } },
                                                    React.createElement("div", { className: "tab-space__left float-md-left col-12 col-md-4" },
                                                        React.createElement("h6", { className: "div-titile text-5d5d fw-700" }, "Student Programs"),
                                                        React.createElement("h3", { className: "div-head text-1818 fw-700" }, "Pursue Top AI Powered Careers"),
                                                        React.createElement("div", { className: "point-list" },
                                                            React.createElement("p", { className: "point-list__item div-info fnt-18 text-1818 fw-500 mb-lg-4 text-justify" }, "One Program, Multiple Careers"),
                                                            React.createElement("p", { className: "point-list__item div-info fnt-18 text-1818 fw-500 mb-lg-4 text-justify" }, "Earn 1.5X to 5X Pay"),
                                                            React.createElement("p", { className: "point-list__item div-info fnt-18 text-1818 fw-500 mb-lg-4 text-justify" }, "Data Science Careers for Diverse Education Background")),
                                                        React.createElement(Link, { to: "/student", className: "div-btn btn btn--skew d-none d-md-inline-block mt-lg-3" }, "Choose Your Career")),
                                                    React.createElement("div", { className: "tab-space__right float-md-left col-12 col-md-8" },
                                                        React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-0", style: { padding: 0 } },
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p1.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analyst")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr1.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Business Analyst")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p2.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Data Engineer")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr2.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Management Consultant")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p3.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning Engineer")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/ca.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Chartered Accountant")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p5.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Data Scientist")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr4.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Investment Banker")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/cds.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Citizen Data Scientist")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { to: "#", className: "d-inline-flex align-items-center clearfix" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr5.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Marketing Manager")))))))))),
                                this.state.active === "Professionals" &&
                                    React.createElement("div", { className: "tab-pane show active fade responsive-tab-content__list", id: "Professionals", role: "tabpanel", "aria-labelledby": "Professionals-tab" },
                                        React.createElement("div", { className: "row" },
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "tab-space flex-wrap d-flex clearfix" },
                                                    React.createElement("div", { className: "tab-space__left float-md-left col-12 col-md-4" },
                                                        React.createElement("h6", { className: "div-titile text-5d5d fw-700" }, "For working professionals"),
                                                        React.createElement("h3", { className: "div-head text-1818 fw-700" }, "AI Specialists for Every Industries"),
                                                        React.createElement("div", { className: "point-list" },
                                                            React.createElement("p", { className: "mb-lg-4 div-info fnt-18 text-1818 fw-500 text-justify" },
                                                                "Skill, Upskill, Reskill and Become Specialist | Anytime,",
                                                                React.createElement("br", null),
                                                                " Anywhere, Any level | 1 to 20 to 40 Week")),
                                                        React.createElement(Link, { className: "div-btn btn btn--skew d-none d-md-inline-block mt-lg-3", to: "/professional" }, "Choose Your Career")),
                                                    React.createElement("div", { className: "tab-space__right float-md-left col-12 col-md-8" },
                                                        React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-0", style: { padding: 0 } },
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p1.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analyst")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr1.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Business Analyst")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p2.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Data Engineer")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr2.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Management Consultant")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p3.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning Engineer")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/ca.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Chartered Accountant")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p5.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Data Scientist")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr4.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Investment Banker")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/cds.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Citizen Data Scientist")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr5.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Marketing Manager")))))))))),
                                this.state.active === "Corporates" &&
                                    React.createElement("div", { className: "tab-pane show active fade responsive-tab-content__list", id: "Corporates", role: "tabpanel", "aria-labelledby": "Corporates-tab" },
                                        React.createElement("div", { className: "row" },
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "tab-space flex-wrap d-flex clearfix" },
                                                    React.createElement("div", { className: "tab-space__left float-md-left col-12 col-md-4" },
                                                        React.createElement("h6", { className: "div-titile text-5d5d fw-700" }, "For Companies"),
                                                        React.createElement("h3", { className: "div-head text-1818 fw-700" }, "Hub for High Skilled Talents, Solutions"),
                                                        React.createElement("div", { className: "point-list" },
                                                            React.createElement("p", { className: "mb-lg-4 div-info fnt-18 text-1818 fw-500 text-justify" }, "AitaHub is creating a one stop hiring and skills acquisition hub for companies for AI-powered Jobs")),
                                                        React.createElement(Link, { className: "div-btn btn btn--skew d-none d-md-inline-block mt-lg-3", to: "/companies" }, "Hire Now")),
                                                    React.createElement("div", { className: "tab-space__right float-md-left col-12 col-md-8" },
                                                        React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-0", style: { padding: 0 } },
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p1.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analyst")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr1.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Business Analyst")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p2.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Data Engineer")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr2.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Management Consultant")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p3.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning Engineer")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/ca.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Chartered Accountant")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p5.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Data Scientist")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr4.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Investment Banker")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/cds.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Citizen Data Scientist")))),
                                                            React.createElement("li", { className: "careers-list__item float-left" },
                                                                React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                    React.createElement("div", { className: "careers-icon float-left" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr5.svg" })),
                                                                    React.createElement("div", { className: "careers-title float-left" },
                                                                        React.createElement("h4", { className: "mb-0 fw-500" }, "Marketing Manager")))))))))))))))));
    }
}