import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import React from 'react'
import Loader from '../Skeleton/Loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Service from "../../service/service";
import { useHistory } from 'react-router-dom';


function ContentView({coursecontent}) {

    const history = useHistory();
    const [expanded, setExpanded] = React.useState(false);
    const [contentdata, setcontentdata] = React.useState([]);
    console.log("contentfile",contentdata)
    

    const handleChange = (panel,id) => (event, isExpanded) => {
      new Service().getcontentfileById(id).then(
        (response) => {
          console.log("response",response)
          var result = response.data.split('\n')
          
          
          // newArray.push(result)
          console.log("splittedarray",result)
          
          setcontentdata(result);
          setExpanded(isExpanded ? panel : false);

            
        }
      ).catch((res)=>{
              if((res+'').includes("401"))
                  history.push("/")
      });
      
    };


    console.log("coursecontent",coursecontent)
  //   useEffect(() => {
  //       var newArray = [];
  //       coursecontent.data.forEach((item) => {
  //           console.log("contentid",item.id)
  //           new Service().getcontentfileById(item.id).then(
  //               (response) => {
  //                 console.log("response",response)
  //                 var result = response.data.split('\n')
                  
                  
  //                 // newArray.push(result)
  //                 console.log("splittedarray",result)
                  
  //                 setcontentdata(contentdata => [...contentdata,result] );
        
                    
  //               }
  //             ).catch((res)=>{
  //                     if((res+'').includes("401"))
  //                         this.props.history.push("/")
  //             });
            
  //         })
          
          
      
  // }, []);

    if (!coursecontent) {
        return <Loader/>;
      }


  return (

    <div>
    {coursecontent && coursecontent.map((header,id) => (
        <div key = {id}>
    <Accordion expanded={expanded === id} onChange={handleChange(id,header.id)} 
    sx = {{mb:3,mt:3}}
    >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
            <Typography variant = "caption" sx={{ color: 'text.secondary',fontWeight:700 }}>{header.name}</Typography>
            </AccordionSummary>

            {contentdata && contentdata !== '' && contentdata.map((subheader,index) =>(


            

                <AccordionDetails key = {id} >
                {subheader !== '' && subheader !== "\r" &&
                <Stack
                justifyContent="space-between"
                alignItems="center"
                direction="row"
                spacing={2}
                >
                
                <li>
                <Typography variant = "caption" sx = {{color: '#afb1b3',fontWeight:800}}>
                {subheader}
                </Typography>
                </li>
                </Stack>}
                </AccordionDetails>
                
       

                    
            ))}
            
        </Accordion>
        </div>

        ))}
        </div>
    

  )
}


// const mapStateToProps = (state) => {
//     return ( {coursecontent: state.coursecontent}) ;
//   };
  
// export default connect(mapStateToProps,fetchCourseContent("/contentcourse"))(ContentView);
export default ContentView;