import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { AITADB_URL } from "../constants/global";
import Config from "../../components/Global";


const AudioPlayer = ({ assetid }) => {


  const [audio, setAudio] = useState("");
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const request = new Request(AITADB_URL + "/sections/asset/" + assetid, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Config.getToken()}`,
        "Content-Type": "audio/mpeg",
      },
    });
    fetch(request) 
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        setAudio(
          new Audio(
            URL.createObjectURL(new Blob([arrayBuffer], { type: "audio/mpeg" }))
          )
        )
      )
      .catch((error) => console.error(error));

  }, [assetid]);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    if (audio) {
      playing ? audio.play() : audio.pause();
    }
  }, [playing,audio]);

  useEffect(() => {
    if (audio) {

    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }
  }, [audio]);



  return (
      <Button
      
        variant="contained"
        size="small"
        sx={{
          
          bgcolor: "#f95877",
          color: "white",
          ":hover": {
            bgcolor: "#f95877",
            color: "white",
          },
        }}
        onClick={toggle}
      >
        {playing ? "pause":"Play"}
      </Button>
  );
};

export default AudioPlayer;
