import React, { useState, useEffect } from "react";
import {
  Card,
  CardMedia,
  Grid,
  Stack,
  Typography,
  IconButton,
  Box,
  Pagination,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Service from "../../service/service";
import { useHistory } from "react-router-dom";

const StudentPrograms = () => {
  const history = useHistory();
  const [programs, setPrograms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [programsPerPage] = useState(6); // Three cards per row and two rows per page

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
        const programIds = userDetails.program_ids;
        const programData = [];

        for (const programId of programIds) {
          const response = await new Service().getProgramDetailsById(programId);
          programData.push(response.data);
        }

        setPrograms(programData);
      } catch (error) {
        new Service().handleErrResponse();
        sessionStorage.removeItem("userDetails");
        // Redirect to the login page or handle the error as required
      }
    };

    fetchPrograms();
  }, []);

  // Get current programs for pagination
  const indexOfLastProgram = currentPage * programsPerPage;
  const indexOfFirstProgram = indexOfLastProgram - programsPerPage;
  const currentPrograms = programs.slice(indexOfFirstProgram, indexOfLastProgram);

  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getImageAsset = (assetId) => {
    return new Service().getImageAsset(assetId);
  };

  // Navigate to /courses page on card click
  const handleCardClick = () => {
    history.push(`/courses`);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        {currentPrograms.map((course, id) => (
          <Grid key={id} item xs={12} sm={6} md={4}>
            <Card elevation={1} onClick={() => handleCardClick()}>
              <CardMedia
                component="img"
                height="128"
                image={getImageAsset(course.thumbnail_asset_id)}
                alt=""
                style={{ objectFit: "cover" }}
              />
              <Grid container columns={12} sx={{ pl: 1, pr: 1, maxHeight: '68px', minHeight: '68px' }}>
                <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5}>
                  <Stack spacing={1} sx={{ mt: 1.2 }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        lineHeight: 1.25,
                        fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                      }}
                    >
                      {course.title}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
                  <Stack spacing={1}>
                    <IconButton>
                      <FavoriteBorderIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(programs.length / programsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};

export default StudentPrograms;

