import * as React from 'react';
import Paper from '@mui/material/Paper';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import moment from 'moment';


export default function Detailcard({ user}) {

    console.log("detailscard",user)
    return (
        <Grid container column={12}>
            <Grid  item lg={12} mg={12} sm={12} xs={12}>
            <Paper sx={{ minWidth:'fit-content',width: '100%' ,bgcolor:"rgb(255, 255, 255)",color:'rgb(18, 24, 40)',borderRadius:'8px'}}>
            <CardHeader

                sx={{padingTop:'32px',paddingBottom:'22px',borderBottom:'1px solid rgb(230, 232, 240)',paddingLeft:'24px',paddingRight:'32px',alignItem:"center"}}
                titleTypographyProps={{fontSize:'1.0909rem',fontWeight:700,fontFamily:"'Poppins' !important" }}
                title="Basic Details"
                
            />
            
            <List sx={{p:'0px',m:'0px'}}>
            
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                    <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.12rem',fontWeight:410,fontFamily:"'Poppins' !important"}}>First Name</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:"'Poppins' !important"}}>{user.first_name}</Typography>
                </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                    <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.12rem',fontWeight:410,fontFamily:"'Poppins' !important"}}>Last Name</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:"'Poppins' !important"}}>{user.last_name}</Typography>
                    </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px"sx={{textAlign:'left',fontSize:'1.12rem',fontWeight:410,fontFamily:"'Poppins' !important"}}>Mobile</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:"'Poppins' !important"}}>{user.mobile}</Typography>
                </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.12rem',fontWeight:410,fontFamily:"'Poppins' !important"}}>Email</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:"'Poppins' !important"}}>{user.email}</Typography>
                </div>
                </ListItemText>

                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.12rem',fontWeight:410,fontFamily:"'Poppins' !important"}}>User Role</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:"'Poppins' !important"}}>{user.user_type}</Typography>
                </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.12rem',fontWeight:410,fontFamily:"'Poppins' !important"}}>Registered On</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:"'Poppins' !important"}}>{moment(user.created_on).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                </div>
                </ListItemText>

                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.12rem',fontWeight:410,fontFamily:"'Poppins' !important"}}>College</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:"'Poppins' !important"}}>{user.college_name?user.college_name:"-"}</Typography>
                </div>
                </ListItemText>
                <ListItemText sx={{paddingTop:'12px',paddingBottom:'12px',paddingLeft:'24px',paddingRight:'24px',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                <div style={{display:'flex' ,flexDirection:'row'}}>
                <Typography minWidth="200px" sx={{textAlign:'left',fontSize:'1.12rem',fontWeight:410,fontFamily:"'Poppins' !important"}}>Education</Typography>
                    <Typography sx={{fontSize:'.975rem',fontWeight:400,fontFamily:"'Poppins' !important"}}>{user.current_education?user.current_education:"-"}</Typography>
                </div>
                </ListItemText>

                
            
            
            
            
            </List>

            </Paper>
            
           
        </Grid>
    </Grid>
      
      
    )
}