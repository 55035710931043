import React from "react";
import Service from "../../service/service";
class Payment extends React.Component {
    constructor(props) {
        super(props);
        const state = props.location.state;
        this.proceedPayment(state.user_details, state.program, props);
    }
    proceedPayment(userDetails, program, props) {
        var options = {
            "key": "rzp_test_L0x1YwOM2YeLNF",
            "amount": program.offer_price * 100,
            "currency": "INR",
            "name": "Aitahub",
            "description": "Learning Platform",
            "image": "http://flybooks.in/assets/images/logo.png",
            "order_id": "",
            "handler": function (response) {
                new Service().addPaymentDetails({
                    response: "",
                    user_id: userDetails.id,
                    transaction_amount: program.offer_price,
                    transaction_id: response.razorpay_payment_id,
                    program_id: program.id,
                    status: true
                }).then((res) => {
                    let program_list = [];
                    userDetails.program_list.forEach((ele) => {
                        program_list.push(ele.id);
                    });
                    program_list.push(program.id);
                    userDetails.program_list = program_list;
                    new Service().updateUser(userDetails).then((res) => {
                        if (sessionStorage.getItem("loggedIn"))
                            props.history.push("/courses");
                        else
                            props.history.push("/");
                    }).catch(err => {
                        console.log(err);
                    });
                }, (error) => {
                    console.log(error);
                });
            },
            "prefill": {
                "name": userDetails.first_name + userDetails.last_name,
                "email": userDetails.email,
                "contact": userDetails.mobile
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#FA5A78"
            }
        };
        // this.state.initiatePayment = true;
        // this.setState(this.state);
        const razorpay = window.Razorpay(options);
        razorpay.on("payment.failed", function (response) {
            new Service().addPaymentDetails({
                response: response.error.reason,
                user_id: userDetails.id,
                transaction_amount: program.offer_price,
                transaction_id: response.error.metadata.payment_id,
                program_id: program.id,
                status: false
            }).then((res) => {
                props.history.push("/login");
            });
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });
        razorpay.open();
    }
    render() {
        return (React.createElement(React.Fragment, null));
    }
}
export default Payment;