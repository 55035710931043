import React from "react";
import "../../styles/Link.scss";
import "./Aitahack.scss";
import { Link as Dom } from "react-scroll";
import "../../components/Products/Products.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Slider from "react-slick";
class Aitahack extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            name: '',
            email: '',
            purpose: '',
            who: '',
            dropDown: 'hide',
            dropDownValue: '',
            active: "daily"
        };
        this.settings = {
            slidesToShow: 2,
            infinite: false,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        this.onValueChange = (event) => {
            this.setState({ [event.target.id]: event.target.value });
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    activate(value) {
        this.setState({ active: value });
    }
    showDropdown() {
        this.setState({ dropDown: 'show' });
    }
    assignValue(value) {
        this.setState({ dropDownValue: value, dropDown: 'hide' });
    }
    render() {
        return (React.createElement("span", null,
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 col-md-6" },
                            React.createElement("div", { className: "banner-content" },
                                React.createElement("h6", { className: "banner-content__title fw-700 text-5d5d" }, "Engineer, Model, Code, Predict and Solve AI"),
                                React.createElement("h1", { className: "banner-content__head fw-700 text-1818" }, "Daily Hackathon, Talent -a-thon for AI Community")),
                            React.createElement("div", { className: "banner-info" },
                                React.createElement("h6", { className: "banner-info__title text-1615 fw-600" }, "Compete, Collaborate & Create Decision Intelligence"),
                                React.createElement("div", { className: "point-list" },
                                    React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Opportunity for Every Graduate to Build High Growth Careers"),
                                    React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Cloud Computational Environment"),
                                    React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Use Public Data, Tools and Notebooks"),
                                    React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Leverage Repository of Use Cases, Codes"),
                                    React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Join Aita Hack Events to Solve AI problems"),
                                    React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Share Ideas, R&D in Aita Blogs, Aita R&D"),
                                    React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Join Large network of Aitians, AI Community")))),
                        React.createElement("div", { className: "col12 col-md-6 col-lg-5 offset-lg-1" },
                            React.createElement("form", { id: "enquiry", autoComplete: "off", "data-parsley-validate": "", method: "post" },
                                React.createElement("div", { className: "form-aitahack" },
                                    React.createElement("h4", { className: "mb-0 form-aitahack__head fnt-18 text-1615 fw-700" }, "Join Networks of Global AI Community"),
                                    React.createElement("span", null,
                                        React.createElement("div", { className: "form-aitahack__form scroll-container overflow-x-hidden position-relative ps ps--active-y" },
                                            React.createElement("div", { className: "row" },
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "form-required col-md-12" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("input", { className: "input", type: "text", id: "name", autoComplete: "off", onChange: this.onValueChange }),
                                                                React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.name !== '' }) }, "Name"),
                                                                React.createElement("span", { className: "form-fields__border" }))))),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "form-required col-md-12" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("input", { className: "form-field__input", name: "aitahack", type: "hidden", value: "aitahack" }),
                                                                React.createElement("input", { className: "input", type: "email", id: "email", autoComplete: "off", onChange: this.onValueChange }),
                                                                React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.email !== '' }) }, "Email"),
                                                                React.createElement("span", { className: "form-fields__border" }))))),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "form-required col-md-12" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("input", { className: "hidden-input", type: "text" }),
                                                                React.createElement("input", { className: "input-check", type: "checkbox", autoComplete: "off", onClick: () => this.showDropdown() }),
                                                                React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.dropDownValue !== '' }) }, "Purpose"),
                                                                React.createElement("span", { className: "form-fields__border" }),
                                                                React.createElement("div", { className: "selected-item" },
                                                                    React.createElement("div", { className: "selected-item__text" }, this.state.dropDownValue)),
                                                                React.createElement("div", { className: classNames("select-dropdown", { "hideDropdown": this.state.dropDown === 'hide', "showDropdown": this.state.dropDown === 'show' }) },
                                                                    React.createElement("div", { className: "select-dropdown__item" },
                                                                        React.createElement("ul", { className: "select-list scroll-container position-relative mb-0 ps" },
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Computer Science") },
                                                                                React.createElement("label", null,
                                                                                    "Computer Science",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "purpose", value: "Computer Science", "data-parsley-multiple": "purpose" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Science") },
                                                                                React.createElement("label", null,
                                                                                    "Science",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "purpose", value: "Science", "data-parsley-multiple": "purpose" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Technology") },
                                                                                React.createElement("label", null,
                                                                                    "Technology",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "purpose", value: "Technology", "data-parsley-multiple": "purpose" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Engineering") },
                                                                                React.createElement("label", null,
                                                                                    "Engineering",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "purpose", value: "Engineering", "data-parsley-multiple": "purpose" }))),
                                                                            React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Mathematics") },
                                                                                React.createElement("label", null,
                                                                                    "Mathematics",
                                                                                    React.createElement("input", { className: "input-rad", type: "radio", name: "purpose", value: "Mathematics", "data-parsley-multiple": "purpose" }))),
                                                                            React.createElement("div", { className: "ps__rail-x", style: { left: "0px", bottom: "0px" } },
                                                                                React.createElement("div", { className: "ps__thumb-x", tabIndex: 0, style: { left: "0px", width: "0px" } })),
                                                                            React.createElement("div", { className: "ps__rail-y", style: { top: "0px", right: "0px" } },
                                                                                React.createElement("div", { className: "ps__thumb-y", tabIndex: 0, style: { top: "0px", height: "0px" } }))))))))),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "form-required col-md-12" },
                                                            React.createElement("div", { className: "form-fields" },
                                                                React.createElement("input", { className: "input", type: "text", id: "who", autoComplete: "off", onChange: this.onValueChange }),
                                                                React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.who !== '' }) }, "Who"),
                                                                React.createElement("span", { className: "form-fields__border" })))))))),
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("div", { className: "form-submission w-100" },
                                            React.createElement("button", { className: "btn btn--skew w-100", type: "submit" }, "Register"))))))))),
            React.createElement("section", { className: "sec-pd position-relative" },
                React.createElement("div", { className: "product-spin product-spin--aitahack" },
                    React.createElement("div", { className: "product-img-part" },
                        React.createElement("div", { className: "product-img-part__down" },
                            React.createElement("img", { className: "img-spin2", src: "assets/images/aitahack/spin2.png" })))),
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row js-custom-tabs" },
                        React.createElement("div", { className: "col-12 col-md-4 col-lg-3" },
                            React.createElement("ul", { className: "sub-tab-list  sticky-element", style: { padding: "0px" } },
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "daily" }) },
                                    React.createElement(Dom, { to: "daily", offset: -100, onClick: () => this.activate("daily") }, "Aita Hack Daily")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "competition" }) },
                                    React.createElement(Dom, { to: "competition", offset: -100, onClick: () => this.activate("competition") }, "Aita Hack Competition")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "practice" }) },
                                    React.createElement(Dom, { to: "practice", offset: -100, onClick: () => this.activate("practice") }, "Aita Blogs, Practice")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "hire" }) },
                                    React.createElement(Dom, { to: "hire", offset: -100, onClick: () => this.activate("hire") }, "Aitathons & Hiring Partners")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "impact" }) },
                                    React.createElement(Dom, { to: "impact", offset: -100, onClick: () => this.activate("impact") }, "Aitians, Impact")))),
                        React.createElement("div", { className: "col-12 col-md-8 col-lg-9" },
                            React.createElement("ul", { className: "sub-tab-content-list tab-pad-left", style: { padding: "0px" } },
                                React.createElement("li", { className: "sub-tab-content-list__item active" },
                                    React.createElement("div", { className: "random-divs" },
                                        React.createElement("div", { id: "daily", className: "random-divs__item" },
                                            React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3 mb-lg-4 col-11 px-0" }, "Building Data Ecosystem, AI Workbench"),
                                            React.createElement("img", { className: "img-fluid", style: { position: "relative" }, src: "assets/images/aitahack/code.jpg" })),
                                        React.createElement("div", { className: "random-divs__item", style: { position: "relative" } },
                                            React.createElement("div", { id: "competition", className: "Blueline-border border-0 bg-6f8f radius-0" },
                                                React.createElement("div", { className: "col-12 col-md-11 px-0" },
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Specialists Careers"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-3 mb-md-4 mb-lg-5", style: { padding: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Deep Learning")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc3.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "NLP")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc4.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Neural Networks")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Computer Vision")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc6.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "High End Analytics"))))),
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Tech Jobs"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-3 mb-md-4 mb-lg-5", style: { padding: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analyst")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Engineer")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p3.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning Engineer")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Scientist"))))),
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Skills Series"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-3 mb-md-4 mb-lg-5", style: { padding: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Coding")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Modelling")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk3.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analytics")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk4.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Digital")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Business")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk6.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Personality Skills and Project Management"))))),
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "AI Business Series"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-0", style: { padding: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Business Analyst")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Management Consultant")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/ca.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Chartered Accountant")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr4.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Investment Banker")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Marketing Manager"))))),
                                                    React.createElement(Link, { className: "d-inline-block btn btn--skew mt-lg-4", to: "/aitahack" }, "Join Aitahack 1.0")))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "practice", className: "Blueline-border" },
                                                React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3 mb-lg-4 col-11 px-0" }, "Share Thoughts, Ideas, R&D Insights Use Cases"),
                                                React.createElement("ul", { className: "slider-card2 custom-arrows pt-5 pt-md-0 slick-initialized slick-slider" },
                                                    React.createElement(Slider, Object.assign({}, this.settings),
                                                        React.createElement("div", null,
                                                            React.createElement("li", { className: "slider-card2__item col-12", style: { width: "100%", display: "inline-block" } },
                                                                React.createElement("img", { className: "img-fluid mb-2", src: "assets/images/aitahack/hack1.png" }),
                                                                React.createElement("p", { className: "fw-500 mb-0 mt-1" }, "Top 10 Data Science Careers"))),
                                                        React.createElement("div", null,
                                                            React.createElement("li", { className: "slider-card2__item col-12", style: { width: "100%", display: "inline-block" } },
                                                                React.createElement("img", { className: "img-fluid mb-2", src: "assets/images/aitahack/hack2.png" }),
                                                                React.createElement("p", { className: "fw-500 mb-0 mt-1" }, "Concept of Job Readiness Engine"))),
                                                        React.createElement("div", null,
                                                            React.createElement("li", { className: "slider-card2__item col-12", style: { width: "100%", display: "inline-block" } },
                                                                React.createElement("img", { className: "img-fluid mb-2", src: "assets/images/aitahack/hack1.png" }),
                                                                React.createElement("p", { className: "fw-500 mb-0 mt-1" }, "Ultimate AI Workbench"))))))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "hire", className: "Blueline-border" },
                                                React.createElement("ul", { className: "mb-0 row regular-list" },
                                                    React.createElement("li", { className: "col-12 col-md-6 regular-list__item" },
                                                        React.createElement(Link, { className: "d-block", to: "#" },
                                                            React.createElement("div", { className: "image" },
                                                                React.createElement("img", { className: "img", src: "assets/images/aitahack/hack4.png" })),
                                                            React.createElement("p", { className: "mb-0" }, "Get Hired in Skilled Talents Competition"))),
                                                    React.createElement("li", { className: "col-12 col-md-6 regular-list__item" },
                                                        React.createElement(Link, { className: "d-block", to: "#" },
                                                            React.createElement("div", { className: "image" },
                                                                React.createElement("img", { className: "img", src: "assets/images/aitahack/hack5.png" })),
                                                            React.createElement("p", { className: "mb-0" }, "Incubate Your Life Goals in Career Events")))))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "impact", className: "Blueline-border" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "col-12 col-md-6 col-lg-5" },
                                                        React.createElement("div", { className: "point-list" },
                                                            React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Opportunity for Every Graduate to Build High Growth Careers"),
                                                            React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Top Minds Meet with Business Challenges"),
                                                            React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Use Public Data, Tools and Notebooks"),
                                                            React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Creating Collaborative Talents Ecosystem"),
                                                            React.createElement("p", { className: "point-list__item fnt-14 text-1818 fw-500" }, "Building Intellectual Properties for Talents"))),
                                                    React.createElement("div", { className: "col-12 col-md-6 offset-lg-1" },
                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/aitahack/hack6.png" }))))))),
                                React.createElement("li", { className: "sub-tab-content-list__item" },
                                    React.createElement("p", null, "Data Science Engine")),
                                React.createElement("li", { className: "sub-tab-content-list__item" },
                                    React.createElement("p", null, "Features and Benefits")),
                                React.createElement("li", { className: "sub-tab-content-list__item" },
                                    React.createElement("p", null, "Module & Tools")))))))));
    }
}
export default Aitahack;