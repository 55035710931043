import { combineReducers } from 'redux';


const coursePreviewReducer = (coursePreview = null, action) => {
  if (action.type === 'COURSE_PREVIEW') {
    return action.payload;
  }

  return coursePreview;
};

const fetchQuizReducer = (state = null, action) => {
  if (action.type === 'FETCH_QUIZ') {
    return action.payload;
  }

  return state;
};
const fetchQuizResult = (state = null, action) => {
  if (action.type === 'FETCH_QUIZ_RESULT') {
    return action.payload;
  }

  return state;
};
const fetchQuizDataReducer = (state = null, action) => {
  if (action.type === 'FETCH_QUIZ_DATA') {
    return action.payload;
  }
  else{
  return state;
  }
};

const courseContentReducer = (state = null, action) => {
  if (action.type === 'FETCH_COURSE_CONTENT') {
    return action.payload;
  }

  return state;
};

const fetchLeftmenuReducer = (state = null, action) => {
  if (action.type === 'FETCH_LEFTMENU') {
    return action.payload;
  }

  return state;
};

const fetchStudentCourse = (state = null, action) => {
  if (action.type === 'FETCH_STUDENT_COURSE') {
    return action.payload;
  }

  return state;
};

const initialState = {
  users: null,
};


function TestReducer(state = initialState, action) {
  switch (action.type) {
    case "DO_THIS":
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
}

function TestReducercourses(state = initialState, action) {
  switch (action.type) {
    case "FETCH_COURSES":
      return {
        ...state,
        courses: action.payload,
      };
    case "FETCH_MYCOURSES":
        return {
          ...state,
          mycourses: action.payload,
        };
    case "FETCH_PROGRAM":
        return {
          ...state,
          program: action.payload,
        };
    case "FETCH_PUBLISEDCOURSE":
            return {
              ...state,
              publisedcourse: action.payload,
            };
    case "FETCH_DRAFTCOURSE":
              return {
                ...state,
                draftcourse: action.payload,
              };
    default:
      return state;
  }
}

function TestReducerIcon(state = initialState, action) {
  switch (action.type) {
    case "FETCH_ICON":
      return {
        ...state,
        icons: action.payload,
      };
    default:
      return state;
  }
}

function TestReducerquizbank(state = initialState, action) {
  switch (action.type) {
    case "FETCH_QUE_BANK":
      return {
        ...state,
        quizbank: action.payload,
      };
    case "FETCH_HEADCELL":
      return {
        ...state,
        quizbankheadcell: action.payload,
      };
    default:
      return state;
  }
}

function TestReducerquizbankdetails(state = initialState, action) {
  switch (action.type) {
    case "FETCH_QUIZ_BANK":
      return {
        ...state,
        quizbankdetails: action.payload,
      };
    default:
      return state;
  }
}


function TestReducerpaymentlist(state = initialState, action) {
  switch (action.type) {
    case "FETCH_PAYMENTBANK":
      return {
        ...state,
        paymentlist: action.payload,
      };
    case "FETCH_PAYMENTHEADCELL":
      return {
        ...state,
        paymentlistheadcell: action.payload,
      };
    default:
      return state;
  }
}

function TestReducerstudentdata(state = initialState, action) {
  switch (action.type) {
    case "FETCH_STULIST":
      return {
        ...state,
        stulist: action.payload,
      };
    case "FETCH_STULISTHEADCELL":
      return {
        ...state,
        stulistheadcell: action.payload,
      };
    case "FETCH_STUDETAILS":
      return {
        ...state,
        studentdetails: action.payload,
      };
    case "FETCH_SCHEDULE":
        return {
          ...state,
          studentevents: action.payload,
        };
    default:
      return state;
  }
}

const fetchInstructordashboardReducer = (state = null, action) => {
  if (action.type === 'FETCH_INSTRUCTOR') {
    return action.payload;
  }

  return state;
};

const fetchstuDashboardQuizResult = (state = null, action) => {
  if (action.type === 'stuDashboardQuizResult_Data') {
    return action.payload;
  }

  return state;
};


export default combineReducers({
    Test: TestReducer,
    Icons: TestReducerIcon,
    Tiles:TestReducercourses,
    Paymentlist: TestReducerpaymentlist,
    Stulist:TestReducerstudentdata,
    Quebankdetails:TestReducerquizbankdetails,
    Quizbank:TestReducerquizbank,
    coursePreview: coursePreviewReducer,
    quiz: fetchQuizReducer,
    leftmenu:fetchLeftmenuReducer,
    studentCourse: fetchStudentCourse,
    instructordashboard: fetchInstructordashboardReducer,
    coursecontent:courseContentReducer,
    quizData:fetchQuizDataReducer,
    quizResult:fetchQuizResult,
    stuDashboardQuizResult:fetchstuDashboardQuizResult


});
