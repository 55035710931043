import React from "react";
import "../../components/Header/Header.scss";
import "../../styles/Link.scss";
import "./AdminHeader.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { IconButton } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { Email as EmailIcon } from "@mui/icons-material";
export default class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: "",
            sidenavValue: ''
        };
        this.state.active = this.props.menu;
    }
    getSnapshotBeforeUpdate(nextProps) {
        return { sidenavValue: nextProps.navbar };
    }
    render() {
        return (React.createElement("div", { className: classNames("col-12 col-md-3 col-xl-1 sidenav-dashboard scroll-container ps", { "active": this.state.sidenavValue === "show" }), style: { height: "992.81px",marginTop: "-26px"  } },
            React.createElement("p", { className: "float-right ml-auto fnt-14 fw-600 mb-0 js-close-dashboardNav d-md-none", onClick: () => { this.setState({ sidenavValue: "" }); } }, "close"),
            React.createElement("ul", { className: "sidenav-list" },
            React.createElement("li", { className: classNames("sidenav-list__item", { "active": this.state.active === "mail" }) },
            React.createElement("div", { onClick: () => { this.setState({ active: "mail" }); } },
                React.createElement(Link, { style: { color: "#000000" }, to: "/mail" },
                    React.createElement("div", { className: "list-head disabledropdown" },
                        React.createElement("div", { className: "icon-div" },
                            React.createElement(IconButton, { className: "ic-icon-svg" },
                                React.createElement(EmailIcon, null))),
                        React.createElement("h6", { className: "list-head__title text2424  mb-0", style: { fontSize: "11px", textAlign: "center", paddingTop: "5px",paddingLeft:"16px"} }, "Mail"))))),
                React.createElement("li", { className: classNames("sidenav-list__item", { "active": this.state.active === "dashboard" }) },
                    React.createElement("div", { onClick: () => { this.setState({ active: "dashboard" }); } },
                        React.createElement(Link, { style: { color: "#000000" }, to: "/dashboard" },
                            React.createElement("div", { className: "list-head disabledropdown" },
                                React.createElement("div", { className: "icon-div" },
                                    React.createElement(IconButton, { className: "ic-icon-svg" },
                                        React.createElement(DashboardIcon, null))),
                                React.createElement("h6", { className: "list-head__title text2424  mb-0", style: { fontSize: "11px", textAlign: "center", paddingTop: "5px" } }, "Dashboard"))))),
                React.createElement("li", { className: classNames("sidenav-list__item", { "active": this.state.active === "mycourse" }) },
                    React.createElement("div", { onClick: () => { this.setState({ active: "mycourse" }); } },
                        React.createElement(Link, { style: { color: "#000000" }, to: "/Courses" },
                            React.createElement("div", { className: "list-head disabledropdown" },
                                React.createElement("div", { className: "icon-div" },
                                    React.createElement(IconButton, { className: "ic-icon-svg" },
                                        React.createElement(ViewListIcon, null))),
                                React.createElement("h6", { className: "list-head__title text2424  mb-0", style: { fontSize: "11px", textAlign: "center", paddingTop: "5px" } }, "My Courses"))))),
                React.createElement("li", { className: classNames("sidenav-list__item", { "active": this.state.active === "myprograms" }) },
                    React.createElement("div", { onClick: () => { this.setState({ active: "myprograms" }); } },
                        React.createElement(Link, { style: { color: "#000000" }, to: "/userprograms" },
                            React.createElement("div", { className: "list-head disabledropdown" },
                                React.createElement("div", { className: "icon-div" },
                                    React.createElement(IconButton, { className: "ic-icon-svg" },
                                        React.createElement(LocalLibraryIcon, null))),
                                React.createElement("h6", { className: "list-head__title text2424  mb-0", style: { fontSize: "11px", textAlign: "center", paddingTop: "5px" } }, "Programs"))))),
                // React.createElement("li", { className: classNames("sidenav-list__item", { "active": this.state.active === "lab" }) },
                //     React.createElement("div", { className: "list-head disabledropdown", onClick: () => { this.setState({ active: "lab" }); window.open("https://portal.azure.com"); } },
                //         React.createElement("div", { className: "icon-div" },
                //             React.createElement(IconButton, { className: "ic-icon-svg" },
                //                 React.createElement(PolicyIcon, null))),
                //         React.createElement("h6", { className: "list-head__title text2424  mb-0", style: { fontSize: "11px", textAlign: "center", paddingTop: "5px" } }, "ML Studio"))),
                // React.createElement("li", { className: classNames("sidenav-list__item", { "active": this.state.active === "reports" }) },
                //     React.createElement("div", { className: "list-head  disabledropdown", onClick: () => { this.setState({ active: "reports" }); } },
                //         React.createElement("div", { className: "icon-div" },
                //             React.createElement(IconButton, { className: "ic-icon-svg" },
                //                 React.createElement(AssessmentIcon, null))),
                //         React.createElement("h6", { className: "list-head__title text2424  mb-0", style: { fontSize: "11px", textAlign: "center", paddingTop: "5px" } }, "Reports"))),
                React.createElement("li", { className: classNames("sidenav-list__item", { "active": this.state.active === "Calender" }) },
                    React.createElement("div", { className: "list-head  disabledropdown", onClick: () => { this.setState({ active: "Calender" }); } },
                        React.createElement(Link, { style: { color: "#000000" }, to: "/events" },
                            React.createElement("div", { className: "icon-div" },
                                React.createElement(IconButton, { className: "ic-icon-svg" },
                                    React.createElement(CalendarMonthIcon, null))),
                                    React.createElement("h6", { className: "list-head__title text2424  mb-0", style: { fontSize: "11px", textAlign: "center", paddingTop: "5px" } }, "Calendar")))))));
    }
}


