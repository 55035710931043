import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { Avatar, Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import Rating from '@mui/material/Rating';
import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { useLocation } from 'react-router-dom';
import Config from "../../components/Global";
import ViewHeader from "./ViewHeader";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import { AITADB_URL } from '../constants/global';



const  VideoPlayer = () =>{

  


  const location = useLocation();
  const [assetId,setassetid] = React.useState('');
  const [video,setvideo] = React.useState(null);
  const { coursename } = location.state
  const { description } = location.state
  const {imageid} = location.state
  const {duration} = location.state


  console.log("pdffile",video)
  console.log(assetId);

  useEffect(() => {
    if (location.state !== undefined) {
      const { assetid } = location.state
      setassetid(assetid)
      const request = new Request(AITADB_URL+"/sections/asset/"+assetid,
      {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${Config.getToken()}`,
        },
      }
    );
      fetch(request)
            .then((response) => response.blob())
            .then((blob) => {              
              const file = window.URL.createObjectURL(blob);
              setvideo(file)
            })
        
      }

  }, [location.state]);
  



  

  return (
    <main>
    <AdminNavbar menu="mycourse" navbar="" />
    <Box sx={{mb:10,pl:12}}>
      <ViewHeader coursename = {coursename} imageid={imageid}/>

      <Grid container columns={12} spacing={1} >
        <Grid item md={1} lg={1}></Grid>

        <Grid item md={10} lg={10} > {/* video content Grid */}
          
          <Grid container columns = {12}>


            <Grid item sm={12}  md={12} lg={12} 
    
            >
              <Box
              // sx = {{
              //   border: 2,
              //   borderRadius:2,
              //   p:1,

              //   borderColor: "#D3D3D3",
              
              // }}
              >

              <ReactPlayer
             
                width="1036.16px"
                height="542.83px"
                // width="100%"
                // height="100%"
                url={video}
                controls
              />
              </Box>
            </Grid>

          <Grid container  sx = {{mt:"32px",mb:"32px"}}>
              <Grid item sm = {9} md = {9} lg = {9} >{/*video below content grid */}

                <Typography variant = "h1" color='#000000' sx = {{fontSize:"2.527rem",fontWeight:"700",lineHeight:"1.25"}}>
                {coursename}
                </Typography>
                <Typography variant = "subtitle1" color = '#00000080' sx = {{mt:1,fontSize:"1.3rem"}}>
                {description}</Typography>

                </Grid>
                  
                <Grid item sm = {3} md = {3} lg = {3}  >
                      <Stack   direction = "row"   justifyContent="flex-end">

                      <Typography variant = "h1" color='#00000070' sx = {{fontSize:"2.827rem",fontWeight:"700",lineHeight:"1.25"}} >
                        50:13
                    </Typography>
                    </Stack>
                </Grid>

                <Grid item sm = {3} md = {3} lg = {3} sx = {{mt:3}} >
                  <Button variant = "contained" 
                  sx={{ bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', } }}>
                    <Typography variant = "button">Resume Lesson</Typography>
                    </Button>


               </Grid>
                <Grid item sm = {9} md = {9} lg = {9}  ></Grid>
          </Grid>
            


          </Grid>
        </Grid>

        <Grid item sm={1}  md={1} lg={1}></Grid>
      </Grid>



{/* preofessor detail card */}
      <Card elevation = {1}>
<Box sx = {{p:1}}>
<Grid container columns = {12}  alignItems = "center">
    <Grid item sm = {1} md ={1} lg = {1}></Grid>

        <Grid item sm = {2} md ={2} lg = {2} sx = {{ml:0}} >
            <Grid container columns = {4} >

            <Grid item sm = {1} md ={1} lg = {1}>

                    <Stack direction = "row" spacing = {2}>
                    
                    <Avatar alt="Remy Sharp" src="https://randomuser.me/api/portraits/men/62.jpg" />
                    </Stack>

                    </Grid>
                    <Grid item sm = {3} md ={3} lg = {3}>
                    <Stack direction = "column" spacing = {0}>
                    
                    <Typography variant = "body1" sx = {{fontWeight:700}}>Elijah Murray</Typography>
                    <Typography variant = "caption">Instructor</Typography>

                    </Stack>

                </Grid>
        
            </Grid>
            
    </Grid>
        <Grid item sm = {1} md ={1} lg = {1}>
        <Stack direction = "row" spacing = {1} >

            <AccessTimeIcon/>
            <Typography>{duration}</Typography>
            </Stack>
        </Grid>
        <Grid item xs = {12} sm = {12} md ={1} lg = {1}>
        <Stack direction = "row" spacing = {1} alignContent="center">
        <InsertChartIcon/>
        <Typography>Beginner</Typography>
        </Stack>


        </Grid>


        <Grid item sm = {5} md ={5} lg = {5}></Grid>

        <Grid item sm = {12} md ={1} lg = {1}>

        <Stack direction = "column" spacing = {1} alignItems="center">
        <Rating name="size-small" defaultValue={'4'} size="medium" readOnly />
        <Typography >20 ratings</Typography>
        </Stack>
        </Grid>
    
        <Grid item sm = {1} md ={1} lg = {1}></Grid>

    </Grid>
    </Box>
    </Card>
    </Box>
    </main>
  );
}



export default VideoPlayer;