import Config from "../../components/Global";
import axios from "axios";
import { JSONDB_URL,QUIZDB_URL,AITADB_URL } from "../../JScomponents/constants/global";
export const coursePreview = () => async dispatch => {
    await axios.get(JSONDB_URL+'/previewcourses/1')
    .then( response => {
      dispatch({ type: 'COURSE_PREVIEW',payload: response.data }) 
  })
  .catch(error => {
    // dispatch(errorHandler())
      console.log(error);
  });
  
  };

export const fetchQuiz = (page) => async dispatch => {
    await axios.get(JSONDB_URL+page)
    .then( response => {
      dispatch({ type: 'FETCH_QUIZ',payload: response.data }) 
  })
  .catch(error => {
    // dispatch(errorHandler())
      console.log(error);
  });
};

// fetchQuizdata from db
export const fetchQuizData = (page) => async dispatch => {
  await fetch(QUIZDB_URL+page)
  .then( response => {
    console.log("response",response)
    dispatch({ type: 'FETCH_QUIZ_DATA',payload: response.data }) 
})
.catch(error => {
  // dispatch(errorHandler())
    console.log(error);
});
};


export const fetchCourseContent = (page) => async dispatch => {
  await axios.get(JSONDB_URL+page)
  .then( response => {
    dispatch({ type: 'FETCH_COURSE_CONTENT',payload: response.data }) 
})
.catch(error => {
  // dispatch(errorHandler())
    console.log(error);
});
};


export const fetchLeftmenu = (page) => async dispatch => {
  await axios.get(JSONDB_URL+page)
  .then( response => {
    dispatch({ type: 'FETCH_LEFTMENU',payload: response.data }) 
})
.catch(error => {
  // dispatch(errorHandler())
    console.log(error);
});
};

export const fetchStudentCourse = (page) => async dispatch => {
  await axios.get(JSONDB_URL+page)
    .then( response => {
      dispatch({ type: 'FETCH_STUDENT_COURSE',payload: response.data }) 
  })
  .catch(error => {
    // dispatch(errorHandler())
      console.log(error);
  });
  
  };

  export const getAllPosts = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(AITADB_URL+'/course',{method:'GET',headers:{
        'Authorization': 'Bearer ' + Config.accessToken.idToken,
      }})
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_COURSES", payload: result })
        );
    };
  };

  export const getSortedratingpost = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(AITADB_URL+'/course',{method:'GET',headers:{
        'Authorization': 'Bearer ' + Config.accessToken.idToken,
      }})
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "DO_THIS", payload: result })
        );
    };
  };

  export const getSortedpost = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(AITADB_URL+'/course',{method:'GET',headers:{
        'Authorization': 'Bearer ' + Config.accessToken.idToken,
      }})
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "DO_THIS", payload: result })
        );
    };
  };

  export const getAllMycourse = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      console.log(Config)
      
      fetch(AITADB_URL+'/course',{method:'GET',headers:{
        'Authorization': 'Bearer ' + Config.accessToken.idToken,
      }})
        .then((response) => response.json())
        .then((result) =>
          
          //dispatch response to the store
          dispatch({ type: "FETCH_MYCOURSES", payload: result })
        );
    };
  };

  export const getCreatequeicon = () => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/createqueicon')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_ICON", payload: result })
        );
    };
  };

  export const getquebank = ({id}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(QUIZDB_URL+'/question/'+id)
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_QUE_BANK", payload: result.data })
        );
    };
  };
// get quiz result
export const getquizresult = (id) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(QUIZDB_URL+'/quizresult/'+id)
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_QUIZ_RESULT", payload: result})
        );
    };
};

  export const getquizbankdetails = () => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(QUIZDB_URL+'/quizByInstructor/1f25b73b-c690-4d27-80b8-9a40d2965734')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_QUIZ_BANK", payload: result.data })
        );
    };
  };

  export const getquizbankheadcell = () => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/quizbankheadcell')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_HEADCELL", payload: result })
        );
    };
  };

  export const getpaymentlist = () => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/paymentbank')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_PAYMENTBANK", payload: result })
        );
    };
  };

  export const getpaymentbankheadcell = () => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/paymentbankheadcell')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_PAYMENTHEADCELL", payload: result })
        );
    };
  };

  export const getstudentlist = () => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/studentlist')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_STULIST", payload: result })
        );
    };
  };

  export const getstudentlistheadcell = () => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/studentlistheadcell')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_STULISTHEADCELL", payload: result })
        );
    };
  };

  export const getstudentdetail = ({id}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/studentlist?id='+ id)
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_STUDETAILS", payload: result })
        );
    };
  };


  export const getstudentevents = (student_id) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(QUIZDB_URL+'/event/'+student_id)
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_SCHEDULE", payload: result.data })
        );
    };
  };

  
  // instrucor program collection
   
  export const getAllPublishedPosts = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(AITADB_URL+'/program')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_PROGRAM", payload: result.data })
        );
    };
  };
  
// instrucor publised courses

export const getAllPublished = ({page}) => {
  return (dispatch) => {
    //   fetch placeholder data from jsonplaceholder
    fetch(JSONDB_URL+'/publishedcourses?_page='+page+'&_limit=8')
      .then((response) => response.json())
      .then((result) =>
        //dispatch response to the store
        dispatch({ type: "FETCH_PUBLISEDCOURSE", payload: result })
      );
  };
};



  export const getPublishSortedratingpost = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/publishedcourses?_page='+page+'&_limit=8&_sort=rating&_order=desc')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "DO_THIS", payload: result })
        );
    };
  };

  export const getPublishSortedpost = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/publishedcourses?_page='+page+'&_limit=8&_sort=Datecreatedby&_order=desc')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "DO_THIS", payload: result })
        );
    };
  };


  // instrucor draft courses

  export const getAllDraftPosts = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/draftcourses?_page='+page+'&_limit=8')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "FETCH_DRAFTCOURSE", payload: result })
        );
    };
  };


  export const getDraftSortedratingpost = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/draftcourses?_page='+page+'&_limit=8&_sort=rating&_order=desc')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "DO_THIS", payload: result })
        );
    };
  };

  export const getDraftSortedpost = ({page}) => {
    return (dispatch) => {
      //   fetch placeholder data from jsonplaceholder
      fetch(JSONDB_URL+'/draftcourses?_page='+page+'&_limit=8&_sort=Datecreatedby&_order=desc')
        .then((response) => response.json())
        .then((result) =>
          //dispatch response to the store
          dispatch({ type: "DO_THIS", payload: result })
        );
    };
  };

  export const fetchInstructordashboard = (page) => async dispatch => {
    await axios.get(JSONDB_URL+page)
    .then( response => {
      dispatch({ type: 'FETCH_INSTRUCTOR',payload: response.data }) 
  })
  .catch(error => {
    // dispatch(errorHandler())
      console.log(error);
  });
  };

  
// fetch student DashboardQuizResult from db
export const stuDashboardQuizResult = (page) => async dispatch => {
  fetch(QUIZDB_URL+page)
  .then( response => {
    dispatch({ type: 'stuDashboardQuizResult_Data',payload: response.data}) 
})
.catch(error => {
  // dispatch(errorHandler())
    console.log(error);
});
};
