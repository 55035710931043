import { useState } from 'react';
import React from 'react';
import { createMuiTheme} from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import { Link } from '@mui/material';

import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import MUIRichTextEditor from "mui-rte";
import UploadFileIcon from '@mui/icons-material/UploadFile';


export default function RichtextQuiz({ quiz ,id,timeEnded}) {
  const [files, setFiles] = useState([]);
  const onFileChange = event => {

    // Update the state
    console.log(event.target.files[0]);
    setFiles(event.target.files[0]);

  };

  console.log(files);
  const onFileUpload = () => {
    console.log("file uploaded")
  };
  const emojis = [
    {
        keys: ["face", "grin"],
        value: "😀",
        content: "😀",
    },
    {
        keys: ["face", "joy"],
        value: "😂",
        content: "😂",
    },
    {
        keys: ["face", "sweat"],
        value: "😅",
        content: "😅",
    }
]
    const [value, setValue] = useState("");
    console.log(value);
    const save = (data) => {
        
        setValue(data);
        console.log(value);
      };
    const defaultTheme = createMuiTheme()

    Object.assign(defaultTheme, {
        overrides: {
            MUIRichTextEditor: {
                root: {
                    marginTop: 20,
                    width: "auto",
                    borderTop:"1px solid gray",
                    borderRadius:"0.5rem",
                    borderRight: "1px solid gray" ,
                    borderLeft:"1px solid gray",
                    backgroundColor:"#f2f2f2",
                    
                },
                editor: {
                    borderBottom: "1px solid gray" ,
                    borderRadius:"0.5rem",
                    borderTop:"1px solid gray",
                    padding:'1',
                    height:"308px",
                    minHeight:"308px",
                    backgroundColor:"#fff"
                }
            }
        }
    })
   
  
    
  
    console.log("quiz", quiz);
    return (
      <div>
        <Grid container columns={12}>
          <Grid item sm={1} md={1} lg={1}>
            <Typography variant="h6">Q.{id}</Typography>
            {/* Question no */}
          </Grid>
  
          <Grid item sm={11} md={11} lg={11}>
            {/* Question content*/}
            <Box sx={{p: 3,mb: 3,borderRadius: 2,bgcolor:"#f6f8ff" }}>
              <Stack direction="column" spacing={1}>
                <Typography variant="body1">{quiz.description}</Typography>
                <Typography variant="body1">{quiz.question}</Typography>
                
                {quiz.referurl && <Typography component={Link} to="/"  variant="body1"><Link href={quiz.referurl} underline="none">Refernce for above question</Link></Typography>}
                {quiz.screenshot && <Typography variant="body1">{quiz.screenshot}</Typography>}
                
                {quiz.image? (<Box
                  component="img"
                  sx={{
  
                    pt:2,
  
                    height: 233,
                    width: 350,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="The house from the offer."
                  src={quiz.image}
                  />) :null}
                <ThemeProvider theme={defaultTheme}>
                    <MUIRichTextEditor
                        contentEditable='true'
                        label="Enter your answer here..."
                        autocomplete={{
                          strategies: [
                              {
                                  items: emojis,
                                  triggerChar: ":"
                              }
                          ]
                      }}
                        onSave={save}
                        inlineToolbar={true}
                    />
                </ThemeProvider>
                <Grid container columns={12}>
                  <Grid sx={{marginTop:'1rem'}}align="rightend" item sm={9.6} md={9.6} lg={9.6}>
                    <Typography sx={{color:"#336",fontWeight:400}} variant="body5">Maximum file size: 1 MB, maximum number of files: 1</Typography>
                  </Grid>
                </Grid>
                <Stack alignItems="center" style={{paddingBottom:'1rem',paddingTop:'1rem',marginRight:'8rem',border:'1px solid gray',width:'100%',backgroundColor:"#fff"}} spacing={3}>
                  <UploadFileIcon sx={{ color: "rgba(1, 79, 110, 1)", height: '32px', width: '32px' }} />
                  <input type="file" 
                  disabled = {timeEnded}
                  
                  
                  onChange={onFileChange} />
                  <Typography variant="qasubtitle" htmlFor="filePicker">Drag and drop the file here or click the button to upload the file.</Typography>
                  <Button disabled = {timeEnded} variant="outlined" onClick={onFileUpload} sx={{ padding: 1, fontSize: '14px',  borderRadius: '4px', height: '32px', fontWeight: '600' ,
                bgcolor:"#f95877" ,color: 'white',':hover': {
                  bgcolor: '#f95877',
                  color: 'white', }
                
                }}>Upload</Button>

                </Stack>
              </Stack>
  
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }


// const myTheme = createTheme({
//   // Set up your custom MUI theme here
// });


