import * as React from "react";
import { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { Grid, Typography, IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useHistory } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import StarIcon from "@mui/icons-material/Star";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CircularProgress, Dialog, DialogContent} from '@mui/material';
import Service from "../../service/service"
import { QUIZDB_URL } from "../constants/global";
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';






const useStyles = makeStyles({
  root: {
    cursor: "pointer",
    "&:hover": {
      background: "linear-gradient(#DDE1E6, #EBEEF1,#E8ECEF)",
    },
  },
});






export default function InboxMail(props) {
  const { handleViewMail } = props;
  const { handleUnreadMessageCount } = props;



  const history = useHistory();

  const[Loading,setLoading] = React.useState(false);
  const [user, setUser] = React.useState();  
  const [mailListFlag] = React.useState(true);
 

  const [AllMails, setAllMails] = React.useState([]);
  const [searchmail, setSearchmail] = React.useState("");
  const [mailpage,setMailpage] = React.useState(0);
  const [mailBookmarks, setMailBookmarks] = React.useState({});
  const [mailImportant, setMailImportant] = React.useState({});


  const [page, setPage] = React.useState(0);
  const rowsPerPage = 8



  const classes = useStyles();

  const SearchMailHandler = (e) =>{
      e.preventDefault()
    setSearchmail(e.target.value)

  }

  useEffect(() => {
    setLoading(true)
      new Service()
      .getUserProfile()
      .then((res) => {
      setUser(res.data)
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: QUIZDB_URL+'/message/recieveall/'+res.data.emailid,
        headers: { }
      };
      
      axios.request(config)
      .then((response) => {
        console.log("allmail by user",response.data.messages);
        setAllMails(response.data.messages)
        setMailBookmarks(
          response.data.messages.reduce(
            (bookmarks, message) => ({
              ...bookmarks,
              [message.id]: message.bookmark === 'true',
            }),
            {}
          )
        );
        setMailImportant(
          response.data.messages.reduce(
            (importantStatus, message) => ({
              ...importantStatus,
              [message.id]: message.important === 'true',
            }),
            {}
          )
        );
        
        setLoading(false)

      })
      .catch((error) => {

        console.log(error);
        setLoading(false)
        if (error.toString().includes('401')) {
          history.push("/");
        }
      });

      console.log("profile data",res.data)
    })

  }, [mailpage,history]);
  
  console.log(user);

  const NextPagination = () => {
    setMailpage(mailpage+1)
    setPage(page+1)
  }
  const PreviousPagination = () => {
    if (mailpage >0){

      setMailpage(mailpage-1)
      setPage(page-1)
    }


  }

  const handleReadMail  = (mailid) =>{

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: QUIZDB_URL+'/message/status/'+mailid,
      headers: { }
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
    
  }

  const ViewHandler = (event, mail_data) => {
    // navigate("/view/" + mail_data.uuid);
    // props.handleInboxFlag(false)
    // props.handleViewMailFlag(true)
    handleUnreadMessageCount()
    handleReadMail(mail_data.id)
    handleViewMail(mail_data);

  };

  const handleBookmark = (e, messageId) => {
    e.preventDefault();
  
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: QUIZDB_URL+'/message/bookmark/'+messageId,
      headers: { }
    };
    
    axios.request(config)
      .then((response) => {
        setMailBookmarks(prevBookmarks => ({
          ...prevBookmarks,
          [messageId]: !prevBookmarks[messageId]
        }));
        console.log(JSON.stringify(response.data));

      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const handleImportant = (e,messageId) => {
    e.preventDefault();

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: QUIZDB_URL+'/message/important/'+messageId,
      headers: { }
    };
    
    axios.request(config)
    .then((response) => {
      setMailImportant(prevImportant => ({
        ...prevImportant,
        [messageId]: !prevImportant[messageId]
      }));
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
    

  }


  const refreshPage = ()=>{
    window.location.reload();
 }


  return (
    <>
     <Dialog 
                        color="success"
                        PaperProps={{
                            style: {
                           
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={Loading} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                        <CircularProgress sx={{justifyContent:'center',alignItem:'center',ml:'2rem'}} />
                        <h4 style={{color:"#fff"}}>Loading...</h4>
                        </DialogContent>
                        </Dialog>
    {mailListFlag? <>

      <Grid
        item
        md={12}
        xs={12}
        sm={12}
        lg={12}
        sx={{ padding: "9px", borderBottom: 1, borderColor: "#e8e6e6" ,}}

      >
        {/* All Mail Icon */}
        <Grid container column={12} >
          <Grid item md={3}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
            >
              <Checkbox
              //   onClick={CheckedHandler}
              />
              <IconButton onClick={refreshPage}>
                <RefreshIcon />
              </IconButton>
              <IconButton>
                <CompareArrowsIcon />
              </IconButton>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item md={2}></Grid>

          <Grid item md={4}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
              // sx={{ height: 0.5 }}
            >
              <TextField
              value = {searchmail}
              onChange  = {(e) =>SearchMailHandler(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search mail.."
              />
            </Stack>
          </Grid>


          <Grid item md={3}>
            {/* <Pagination count={0} /> */}
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems ="center"
              // sx={{ height: 0.5 }}
            >
            {/* <Typography variant = "body1">{page+1} - 7 of {AllMails.length}</Typography>
            

            <IconButton disabled = {mailpage<1} onClick = {PreviousPagination}> <ChevronLeftIcon/> </IconButton>
            <IconButton onClick = {NextPagination}> <ChevronRightIcon/> </IconButton> */}
            {AllMails.length > 0 && (
  <>
    <Typography variant="body1">
      {Math.min(page * rowsPerPage + 1, AllMails.length)} - {Math.min(page * rowsPerPage + rowsPerPage, AllMails.length)} of {AllMails.length}
    </Typography>
    <IconButton disabled={page === 0} onClick={PreviousPagination}>
      <ChevronLeftIcon />
    </IconButton>
    <IconButton disabled={(page + 1) * rowsPerPage >= AllMails.length} onClick={NextPagination}>
      <ChevronRightIcon />
    </IconButton>
  </>
)}
            
            </Stack>
            
          </Grid>
        </Grid>
      </Grid>
      
      <Grid
        item
        md={12}
        xs={12}
        sm={12}
        lg={12}
        sx={{ borderBottom: 1, borderColor: "#e8e6e6" }}
      >
        {/* All Mail */}
      {AllMails.filter((mail_data) => {
                if (searchmail === "") {
                  return mail_data;
                } else if (
                  mail_data.subject.toLowerCase().includes(searchmail.toLowerCase())||
                  mail_data.sendername.toLowerCase().includes(searchmail) ||
                  mail_data.createddate.toLowerCase().includes(searchmail) ||
                  mail_data.message.toLowerCase().includes(searchmail)
                ) {
                  return mail_data;
                }
                return false
              }).sort((a, b) => new Date(b.createddate) - new Date(a.createddate))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((mail_data,index) => {
                return (
                            <Grid
            container
            spacing={0}
            columns={12}
            className={classes.root}

            sx={{
              backgroundColor: mail_data.status === 'read' ?"#f4f6f8": "white",
              padding: 1.2,
              borderBottom: 1,
              borderColor: "#e8e6e6",
            }}
            key = {mail_data.uuid}

          >
            <Grid item md={2}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                // sx={{ height: 0.6 }}

              >
                <Checkbox checked={props.checked} />

                <IconButton
                    onClick={(e) => handleBookmark(e, mail_data.id)}
                  >
                    <Tooltip title={mailBookmarks[mail_data.id] ? "Starred" : "Not Starred"}>
                    {mailBookmarks[mail_data.id] ? (
                      <StarIcon sx={{ color: "#ffc107" }} />
                    ) : (
                      <StarOutlineOutlinedIcon/>
                    )}
                    </Tooltip>
                </IconButton>

                <IconButton
                  onClick={(e) => handleImportant(e, mail_data.id)} 
                >
                  <Tooltip title={mailImportant[mail_data.id] ? "Important" : "Not Important"}>
                   {mailImportant[mail_data.id] ? (
                    <LabelImportantIcon sx={{ color: "#ffc107" }} />
                  ) : (
                    <LabelImportantIcon />
                  )}
                  </Tooltip>
                </IconButton>
                
              </Stack>
            </Grid>

        <Grid item md={10} onClick={(e) => ViewHandler(e, mail_data)}>

      {/* <Grid container columns = {12} alignItems="center">
            <Grid item md={3} >
            <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"


              >
            <Avatar
                  alt="Remy Sharp"
                  src="https://randomuser.me/api/portraits/med/men/32.jpg"
                />
                <Typography variant = "body1"  noWrap sx={{ fontWeight: "bold",fontSize:"15px" }}>
              {mail_data.sendername}
                </Typography>
                </Stack>
            </Grid>

            <Grid item md={7}>
              <Typography variant="body1" sx={{ fontWeight: "bold",fontSize:"14px",ml:2 }} noWrap>
                {mail_data.subject}
              </Typography>
            </Grid>

            <Grid item md={2} >
            <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-end"
              >
              <Typography variant="body2" sx={{ fontWeight: "bold",mr:2 }}>
                {mail_data.createddate
                  ? moment(mail_data.createddate).format("D MMM")
                  : "31 January"}
              </Typography>
              </Stack>
            </Grid>
          </Grid> */}
          <Grid container columns={12} alignItems="center">
  <Grid item md={3}>
    <Stack
      direction="row"
      spacing={1}
      justifyContent="flex-start"
      alignItems="center"
    >
      <Avatar
        alt="Remy Sharp"
        src="https://randomuser.me/api/portraits/med/men/32.jpg"
      />
      <Typography
        variant="body1"
        noWrap
        sx={{
          fontWeight: mail_data.status === 'read' ? "normal" : "bold",
          fontSize: "15px",
        }}
      >
        {mail_data.sendername}
      </Typography>
    </Stack>
  </Grid>

  <Grid item md={7}>
    <Typography
      variant="body1"
      sx={{
        fontWeight: mail_data.status === 'read' ? "normal" : "bold",
        fontSize: "14px",
        ml: 2,
      }}
      noWrap
    >
      {mail_data.subject}
    </Typography>
  </Grid>

  <Grid item md={2}>
    <Stack direction="row" spacing={1} justifyContent="flex-end">
      <Typography variant="body2" 
      sx={{  fontWeight: mail_data.status === 'read' ? "normal" : "bold",
               mr: 2 
       }}>
        {mail_data.createddate
          ? moment(mail_data.createddate).format("D MMM")
          : "31 January"}
      </Typography>
    </Stack>
  </Grid>
</Grid>

          </Grid>
          </Grid>
               );
              })}
      </Grid>
    </> : null}
    {/* {mailViewFlag ? <ViewMail mailViewFlag = {mailViewFlag} 
    handleMailViewFlag = {setMailViewFlag} 
    handleMailListFlag = {setMailListFlag}
    
    />  : null} */}
    </>
  );
}
