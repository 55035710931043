import React from "react";
import Home from "../../components/Home/Home";
import Career from "../../components/Career/Carrer";
import Products from "../../components/Products/Products";
import Blogs from "../../components/Blogs/Blogs";
import Community from "../../components/Community/Community";
class HomePage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (React.createElement("span", null,
            React.createElement(Home, null),
            React.createElement(Career, null),
            React.createElement(Products, null),
            React.createElement(Blogs, null),
            React.createElement(Community, null)));
    }
}
export default HomePage;