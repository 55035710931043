import React, {  useState } from "react";
import { Grid, Typography } from "@mui/material";
import ViewHeader from "./ViewHeader";
import { useLocation } from 'react-router-dom'
import { AITADB_URL } from "../constants/global";
import Config from "../../components/Global";
import axios from "axios";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
export default function TextViewer() {

  const [textdata, SetTextdata] = useState([]);

  const location = useLocation();
  const { assetid } = location.state
  const { subsectionName } = location.state
  const { coursename } = location.state
  const {imageid} = location.state


  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: AITADB_URL+"/sections/asset/"+assetid,
    headers: {
            'Authorization': `Bearer ${Config.getToken()}`,
    }
  };

  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
    SetTextdata(response.data)
  })
  .catch((error) => {
    console.log(error);
  });


  return (
    <main>
    <AdminNavbar menu="mycourse" navbar="" />
    <div>
      <ViewHeader coursename = {coursename} imageid={imageid}/>
      <Grid sx={{pl:12}} container columns={12} spacing={1}>
        <Grid item md={1} lg={1}></Grid>

        <Grid item md={10} lg={10}>
          {/* video content Grid */}
          <Typography variant="h3" sx = {{mt:3,mb:3}}>{subsectionName}</Typography>


          <Typography variant="h6">{textdata}</Typography>

        </Grid>
        <Grid item md={1} lg={1}></Grid>
      </Grid>
    </div>
    </main>
  );
}
