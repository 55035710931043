import React, { useState, useEffect } from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Box,
  Grid,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import FilterListIcon from "@mui/icons-material/FilterList";
import Moment from "moment";
import Quizservice from "../Quiz/quizservice";
import Pagination from "@mui/material/Pagination";
import {Link} from "react-router-dom";

const Quizresulttable = () => {
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOption, setFilterOption] = useState("");
  const [quizResult, setQuizResult] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);

  useEffect(() => {
    const userDetails = sessionStorage.getItem("userDetails");
    const email = JSON.parse(userDetails).data.email;

    new Quizservice()
      .getstudentquizres(email)
      .then((response) => {
        setQuizResult(response);
      })
      .catch((error) => {
        console.error("Error fetching quiz results:", error);
      });
  }, []);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterOption = (option) => {
    setFilterOption(option);
    setAnchorEl(null);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  


  let filteredData = quizResult
  .filter((quiz) =>
    quiz.title.toLowerCase().includes(searchText.toLowerCase())
  )
  .sort((a, b) => Moment(a.date).diff(b.date) || a.timestamp - b.timestamp);

if (filterOption === "newer") {
  filteredData = filteredData.sort((a, b) =>
    Moment(b.date).diff(a.date) || b.timestamp - a.timestamp
  );
} else if (filterOption === "older") {
  filteredData = filteredData.sort((a, b) =>
    Moment(a.date).diff(b.date) || a.timestamp - b.timestamp
  );
}

  const pageCount = Math.ceil(filteredData.length / rowsPerPage);
  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  

 

  
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <TextField
            label="Search by Title"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={handleSearchChange}
          />

          <Button
            variant="outlined"
            onClick={handleFilterClick}
            endIcon={<FilterListIcon />}
          >
            Filter
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem onClick={() => handleFilterOption("newer")}>
              Newer
            </MenuItem>
            <MenuItem onClick={() => handleFilterOption("older")}>
              Older
            </MenuItem>
          </Menu>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: ".9375rem",
                      fontWeight: 600,
                      letterSpacing: "2px",
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    Title
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: ".9375rem",
                      fontWeight: 600,
                      letterSpacing: "2px",
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    Date
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: ".9375rem",
                      fontWeight: 600,
                      letterSpacing: "2px",
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    Score
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: ".9375rem",
                      fontWeight: 600,
                      letterSpacing: "2px",
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    No. of attempts
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: ".9375rem",
                      fontWeight: 600,
                      letterSpacing: "2px",
                      fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif",
                    }}
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length > 0 ? (
                paginatedData.map((quiz) => (
                  <TableRow key={quiz.title}>
                    <TableCell>{quiz.title}</TableCell>
                    <TableCell align="center">{quiz.date}</TableCell>
                    <TableCell align="center">{quiz.score}</TableCell>
                    <TableCell align="center">{quiz.count}</TableCell>
                    <TableCell align="center">
                    <Link
                        to={{ pathname: '/viewquiz', state: { courseid:quiz.courseid } }}
                          style={{ textDecoration: "none", color: "#ffffff" }}
                        >
                      <Button
                        variant="contained"
                        startIcon={<PlaylistAddCheckIcon />}
                        
                      >
                        <Typography variant="button">RETAKE</Typography>
                      </Button>
                    </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    align="center"
                    style={{ padding: "1rem" }}
                  >
                    <Typography variant="body1">
                      No completed quizzes to show.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {paginatedData.length > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Pagination
              count={pageCount}
              page={page + 1}
              onChange={handleChangePage}
              color="primary"
              size="large"
            />
          </Box>
      )}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Quizresulttable;
