import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
class Footer extends React.Component {
    render() {
        return (React.createElement("footer", null,
            React.createElement("div", { className: "container pad" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "footer-social-link col-12 col-md-5 mb-3" },
                                React.createElement(Link, { className: "d-inline-block", to: "/" },
                                    React.createElement("img", { className: "footer-social-link__homelogo", src: "assets/images/footer/ft-logo2.svg" })),
                                React.createElement("p", { className: "text-white fw-700 fnt-16 col-md-9 px-0 mt-3 mt-md-5 pt-lg-4 mb-3 mb-md-4 pb-3" },
                                    "\u201COur mission is to build AI Careers for",
                                    React.createElement("br", null),
                                    " every aspiring Individual\u201D"),
                                React.createElement("div", { className: "footer-social-links" },
                                    React.createElement(Link, { className: "footer-social-links__item", to: "#" },
                                        React.createElement("img", { src: "assets/images/footer/fb.svg" })),
                                    React.createElement(Link, { className: "footer-social-links__item", to: "#" },
                                        React.createElement("img", { src: "assets/images/footer/tw.svg" })),
                                    React.createElement(Link, { className: "footer-social-links__item", to: "#" },
                                        React.createElement("img", { src: "assets/images/footer/linkedin.svg" })),
                                    React.createElement(Link, { className: "footer-social-links__item", to: "#" },
                                        React.createElement("img", { src: "assets/images/footer/instagram.svg" })))),
                            React.createElement("div", { className: "col-12 col-md-7" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "foter-links pb-4 pb-md-5 col-5 col-md-6 col-lg-4" },
                                        React.createElement("h4", { className: "foter-links__head fnt-16 fw-600" }, "AiTAHUB"),
                                        React.createElement("ul", { className: "foter-link-list custompadding" },
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/about" }, "About AitaHub")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "Team")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "Help & Support")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "Events")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "Contact")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "Media")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "Resources")))),
                                    React.createElement("div", { className: "foter-links pb-4 pb-md-5 col-5 col-md-6 col-lg-4" },
                                        React.createElement("h4", { className: "foter-links__head fnt-16 fw-600" }, "Products"),
                                        React.createElement("ul", { className: "foter-link-list custompadding" },
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/student" }, "Students")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/professional" }, "Professionals")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/companies" }, "Companies")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/partners" }, "Institutions")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/community" }, "Community")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "Mentors")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "Trainers")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "Vendors")))),
                                    React.createElement("div", { className: "foter-links pb-4 pb-md-5 col-5 col-md-6 col-lg-4" },
                                        React.createElement("h4", { className: "foter-links__head fnt-16 fw-600" }, "Careers"),
                                        React.createElement("ul", { className: "foter-link-list custompadding" },
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "AI Tech Careers")),
                                            React.createElement("li", { className: "foter-link-list__item" },
                                                React.createElement(Link, { className: "fnt-14 fw-500", to: "/contact-us" }, "AI Business Careers")))))))),
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "company-terms row text-center text-md-left align" },
                            React.createElement("div", { className: "col-12 col-md-3 col-lg-4 foter-link-list__item mb-3 mb-md-0 align" },
                                React.createElement(Link, { className: "fnt-12", to: "#" }, "Copyright2020")),
                            React.createElement("div", { className: "col-12 col-md-8 col-lg-6 ml-md-auto" },
                                React.createElement("div", { className: "d-flex flex-column flex-md-row justify-content-md-end clearfix" },
                                    React.createElement("div", { className: "text-md-right foter-link-list__item mb-3 mb-md-0 mr-md-4 float-left" },
                                        React.createElement(Link, { className: "fnt-12", to: "#" }, "Privacy Policy")),
                                    React.createElement("div", { className: "text-md-right foter-link-list__item mb-3 mb-md-0 mr-md-4 float-left" },
                                        React.createElement(Link, { className: "fnt-12", to: "#" }, "Terms of Service")),
                                    React.createElement("div", { className: "text-md-right foter-link-list__item float-left" },
                                        React.createElement(Link, { className: "fnt-12", to: "#" }, "Disclaimer"))))))))));
    }
}
export default Footer;