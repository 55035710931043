import React from "react";
import "../../components/Header/Header.scss";
import "../../styles/Button.scss";
import "./Login.scss";
import { Modal } from "react-bootstrap";
import Service from "../../service/service";
import { Link } from "react-router-dom";
import { PublicClientApplication, BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser";
import { MSAL_CONFIG } from "../../azure/azure-authentication-config";
import Config from "../../components/Global";
class signup extends React.Component {
    constructor(props) {
        super(props);
        this.program = {};
        this.isProgramSelected = false;
        this.state = {
            firstname: "",
            lastname: "",
            mailid: "",
            mobilenumber: "",
            password: "",
            confirmpassword: "",
            loginid: "",
            show: false,
            signUpSucess: false,
            progressbar: false,
            errorMessage: '',
            isError: false,
            validEmail: '',
            showOTPpopup: false,
            otp: "",
        };
        this.form = {
            firstname: { valid: false },
            lastname: { valid: false },
            loginid: { valid: false },
            mailid: { valid: false },
            mobilenumber: { valid: false }
        };
        this.validForm = false;
        this.formSubmitted = false;
        this.ValidateLoginId = new RegExp('^[a-zA-Z0-9_]*$');
        this.ValidateEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
        this.accessTokenRequest = {
            scopes: ["user.read"],
            account: undefined
        };
        this.onValueChange = (event) => {
            this.setState({ [event.target.id]: event.target.value });
            if (event.target.id == "mailid")
                this.checkForDataExistance(event.target.value, 'email').then((res) => {
                    this.setState({ isEmailExist: res.data.data });
                });
            else if (event.target.id == 'mobilenumber')
                this.checkForDataExistance(event.target.value, 'mobile').then((res) => {
                    this.setState({ isPhonenoExists: res.data.data });
                });
            else if (event.target.id == 'loginid')
                this.checkForDataExistance(event.target.value + "@aitahub.com", 'loginid').then((res) => {
                    this.setState({ isLoginidExists: res.data.data });
                });
            this.validateForm();
        };
        this.onAccessToken = this.onAccessToken.bind(this);
        if (this.props.location.state != undefined) {
            this.program = this.props.location.state;
            this.state.coursePrice = this.program.offer_price;
            this.isProgramSelected = true;
        }
    }
    checkForDataExistance(data, type) {
        return new Service().checkForDataExistance(data, type);
    }
    passwordCheck() {
        if (this.state.password && this.state.confirmpassword != "") {
            if (this.state.password === this.state.confirmpassword) {
                return 1;
            }
            else {
                this.handleModal();
                return 0;
            }
        }
    }
    verifyOTP() {
        this.setState({ showOTPpopup: false, progressbar: true });
        // call email verification api
        new Service().verifyVerificationCode(this.state.mailid, this.state.otp).then(res => {
            if (res === 200) {
                this.signupUser();
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    VerifyCredentials() {
        this.formSubmitted = true;
        if (this.validateForm() && this.passwordCheck()) {
            new Service().sendVerificationCode(this.state.mailid, this.state.firstname + this.state.lastname);
            this.setState({ showOTPpopup: true });
        }
    }
    enrollForFreeProgram(user, selectedProgram) {
        let program_list = [];
        user.program_list.forEach((ele) => {
            program_list.push(ele.id);
        });
        program_list.push(selectedProgram.id);
        user.program_list = program_list;
        new Service().updateUser(user).then((res) => {
            new Service().getUserByEmail(user.email).then((res) => {
                var userDetails = { data: res.data, program_ids: [] };
                res.data.program_list.forEach((ele) => { userDetails.program_ids.push(ele.id); });
                sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
                this.props.history.push("/");
            });
        });
    }
    signupUser() {
        if (this.isProgramSelected) {
            this.state.program_ids = [this.program.id];
        }
        let userRegDetails = {
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            email: this.state.mailid,
            mobile: this.state.mobilenumber,
            login_id: this.state.loginid + "@aitahub.com",
            user_type: "STUDENT",
            program_list: this.state.program_ids != undefined ? this.state.program_ids : [],
            password: this.state.password
        };
        new Service().signupUser(userRegDetails).then((res) => {
            this.state.progressbar = false;
            if (this.isProgramSelected) {
                if (this.program.price == 0 || this.program.offer_price == 0) {
                    this.enrollForFreeProgram(res, this.program);
                }
                else {
                    this.props.history.push({
                        pathname: "/payment",
                        state: {
                            user_details: res,
                            program: this.program
                        }
                    });
                }
            }
            else {
                this.state.signUpSucess = true;
                this.setState(this.state);
            }
        })
            .catch((error) => {
            if (error.response.data == undefined) {
                this.state.errorMessage = "Something went wrong!";
                this.state.isError = true;
                this.state.progressbar = false;
                this.setState(this.state);
            }
            else if (error.response.data.error.message === "A conflicting object with one or more of the specified property values is present in the directory.") {
                this.state.errorMessage = "Another user with the same value for Login Id already exists.";
                this.state.isError = true;
                this.state.progressbar = false;
                this.setState(this.state);
            }
            else if (error.response.data.error.message === "The specified password does not comply with password complexity requirements. Please provide a different password.") {
                this.state.errorMessage = error.response.data.error.message;
                this.state.isError = true;
                this.state.progressbar = false;
                this.setState(this.state);
            }
            else if (error.response.data.error.message === "Another object with the same value for property proxyAddresses already exists.") {
                this.state.errorMessage = "Email Already Exists.";
                this.state.isError = true;
                this.state.progressbar = false;
                this.setState(this.state);
            }
            else {
                this.state.errorMessage = error.response.data.error.message;
                this.state.isError = true;
                this.state.progressbar = false;
                this.setState(this.state);
            }
        });
    }
    onAccessToken(accessTokenResponse) {
        Config.setToken(accessTokenResponse);
        new Service().getUserByEmail(Config.getClaims().email).then((res) => {
            if (res.data.program_list.length > 0) {
                let programIds = [];
                res.data.program_list.forEach((ele) => {
                    programIds.push(ele.id);
                });
                this.props.history.push({
                    pathname: "/courses",
                    state: programIds
                });
            }
            else {
                this.props.history.push("/programs", res.data.data);
            }
        });
    }
    showLogin() {
        this.myMSALObj.acquireTokenSilent(this.accessTokenRequest).then(this.onAccessToken).catch((error) => {
            console.log("Silent failed : " + JSON.stringify(error));
            if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
                this.myMSALObj.acquireTokenPopup(this.accessTokenRequest).then(this.onAccessToken).catch(function (error) {
                    console.log(error);
                });
            }
        });
    }
    validateForm() {
        const firstname = document.getElementById('firstname');
        this.form.firstname.valid = firstname.value.length != 0 ? true : false;
        const lastname = document.getElementById('lastname');
        this.form.lastname.valid = lastname.value.length != 0 ? true : false;
        this.form.loginid.valid = (this.state.loginid.length != 0 && this.ValidateLoginId.test(this.state.loginid)) ? true : false;
        this.form.mailid.valid = (this.state.mailid.length != 0 && this.ValidateEmail.test(this.state.mailid)) ? true : false;
        const mobilenumber = document.getElementById('mobilenumber');
        this.form.mobilenumber.valid = mobilenumber.value.length == 10 ? true : false;
        this.setState({ form: this.form });
        console.log(this.form);
        this.validForm = true;
        Object.values(this.form).forEach((ele) => {
            if (Object.values(ele)[0] === false) {
                this.validForm = false;
                return;
            }
        });
        return this.validForm;
    }
    handleModal() {
        this.setState({ show: !this.state.show });
    }
    render() {
        return (React.createElement("section", { className: "py-4 py-lg-5 pading" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center" },
                    React.createElement("div", { className: "col-12 col-md-8 col-lg-7 col-xl-8" },
                        React.createElement(Modal, { show: this.state.progressbar },
                            React.createElement("div", { className: "container" },
                                React.createElement("img", { className: "gif", src: "assets/images/loader.gif" }))),
                        React.createElement(Modal, { show: this.state.showOTPpopup === true, size: "lg" },
                            React.createElement("div", { className: "login-pad" },
                                React.createElement("div", { className: "login-form custom-1" },
                                    React.createElement("ul", { className: "login-slider login-pad mb-0" },
                                        React.createElement("li", { className: "login-slider__item login-form__signup form-section col-12", id: "signUp" },
                                            React.createElement("div", { className: "logn-form" },
                                                React.createElement("div", { className: "validate-form mb-3 mb-md-4" },
                                                    React.createElement("div", null,
                                                        React.createElement("label", { style: { fontSize: "12px" } }, "Email Verification"),
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", id: "otp", type: "number", autoComplete: "off", placeholder: "Enter OTP sent to your Email Address", onChange: this.onValueChange }),
                                                            React.createElement("span", { className: "form-fields__border" })),
                                                        React.createElement("div", { className: "col-xl-2 login-width col-md-2", style: { padding: "20px 0px", float: "right" } },
                                                            React.createElement("button", { className: "col-sm-2 col-lg-2 col-md-2 btn btn--primary form-submit__btn", onClick: () => this.verifyOTP(), style: { fontSize: "16px" }, type: "button" }, "Verify OTP")))))))))),
                        React.createElement("div", { className: "login-section" },
                            React.createElement("div", { className: "login-form custom" },
                                React.createElement("ul", { className: "login-slider login-pad mb-0" },
                                    this.state.signUpSucess === false &&
                                        React.createElement("li", { className: "login-slider__item login-form__signup form-section col-12", id: "signUp" },
                                            React.createElement("div", { className: "log-head" },
                                                React.createElement("h1", { className: "fnt-32 fw-700 text-2424 mb-2" }, "Sign up"),
                                                React.createElement("h5", { className: "fnt-16 fw-500 text-2a2a mb-0" }, "Signing up as")),
                                            React.createElement("div", { className: "login-type" },
                                                React.createElement("ul", { className: "login-type-list clearfix" },
                                                    React.createElement("li", { className: "login-type-list__item float-md-left" },
                                                        React.createElement("label", { className: "login-container-radio" },
                                                            React.createElement("input", { type: "radio", name: "login-type" }),
                                                            React.createElement("div", { className: "login-type-icon" },
                                                                React.createElement("div", { className: "login-type-icon__Img" },
                                                                    React.createElement("img", { src: "assets/images/login/login-student.svg" })),
                                                                React.createElement("h6", { className: "login-type-icon__name" }, "Students")))),
                                                    React.createElement("li", { className: "login-type-list__item float-md-left" },
                                                        React.createElement("label", { className: "login-container-radio" },
                                                            React.createElement("input", { type: "radio", name: "login-type" }),
                                                            React.createElement("div", { className: "login-type-icon" },
                                                                React.createElement("div", { className: "login-type-icon__Img" },
                                                                    React.createElement("img", { src: "assets/images/login/login-proffesional.svg" })),
                                                                React.createElement("h6", { className: "login-type-icon__name" }, "Professionals")))),
                                                    React.createElement("li", { className: "login-type-list__item float-md-left" },
                                                        React.createElement("label", { className: "login-container-radio" },
                                                            React.createElement("input", { type: "radio", name: "login-type" }),
                                                            React.createElement("div", { className: "login-type-icon" },
                                                                React.createElement("div", { className: "login-type-icon__Img" },
                                                                    React.createElement("img", { src: "assets/images/login/login-corparate.svg" })),
                                                                React.createElement("h6", { className: "login-type-icon__name" }, "Corporates")))),
                                                    React.createElement("li", { className: "login-type-list__item float-md-left" },
                                                        React.createElement("label", { className: "login-container-radio" },
                                                            React.createElement("input", { type: "radio", name: "login-type" }),
                                                            React.createElement("div", { className: "login-type-icon" },
                                                                React.createElement("div", { className: "login-type-icon__Img" },
                                                                    React.createElement("img", { src: "assets/images/login/login-others.svg" })),
                                                                React.createElement("h6", { className: "login-type-icon__name" }, "Others")))))),
                                            React.createElement("div", { className: "logn-form" },
                                                React.createElement("div", { className: "validate-form mb-3 mb-md-4" },
                                                    React.createElement("div", { style: { paddingBottom: "20px" } },
                                                        React.createElement("label", { style: { fontSize: "12px" } }, "First Name"),
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", id: "firstname", type: "text", autoComplete: "off", placeholder: "First Name", onChange: this.onValueChange }),
                                                            React.createElement("span", { className: "form-fields__border" })),
                                                        React.createElement("div", null, (!this.form.firstname.valid && this.formSubmitted) &&
                                                            React.createElement("span", { className: "error" }, "* firstname is required.")),
                                                        React.createElement("label", { style: { fontSize: "12px" } }, "Last Name"),
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", id: "lastname", type: "text", autoComplete: "off", placeholder: "Last Name", onChange: this.onValueChange }),
                                                            React.createElement("span", { className: "form-fields__border" })),
                                                        React.createElement("div", null, (!this.form.lastname.valid && this.formSubmitted) &&
                                                            React.createElement("span", { className: "error" }, "* lastname is required.")),
                                                        React.createElement("div", { className: "col-auto" },
                                                            React.createElement("label", { style: { fontSize: "12px" } }, "Login Id"),
                                                            React.createElement("div", { className: "input-group mb-2" },
                                                                React.createElement("input", { type: "text", id: "loginid", style: { padding: "12px 15px", fontSize: "14px" }, autoComplete: "off", className: "form-control", placeholder: "Login Id", onChange: this.onValueChange }),
                                                                React.createElement("div", { className: "input-group-prepend" },
                                                                    React.createElement("div", { style: { padding: "12px 0px", fontSize: "14px" }, className: "input-group-text" }, "@aitahub.com"))),
                                                            (!this.form.loginid.valid && this.formSubmitted) &&
                                                                React.createElement("div", null,
                                                                    React.createElement("label", { className: "error" },
                                                                        "Please enter an valid loginid. ",
                                                                        React.createElement("br", null),
                                                                        "- Accepts only 0-9 and letters A-Z (both uppercase and lowercase) ",
                                                                        React.createElement("br", null),
                                                                        "- special characters only underscore is allowed ")),
                                                            this.state.isLoginidExists &&
                                                                React.createElement("div", null,
                                                                    React.createElement("label", { style: { fontSize: "12px", color: "red", paddingBottom: "20px" } }, "The loginId already exists."))),
                                                        React.createElement("label", { style: { fontSize: "12px" } }, "Email Address"),
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", id: "mailid", type: "text", autoComplete: "off", placeholder: "Email Address", onChange: this.onValueChange }),
                                                            React.createElement("span", { className: "form-fields__border" })),
                                                        (!this.form.mailid.valid && this.formSubmitted) &&
                                                            React.createElement("div", null,
                                                                React.createElement("span", { className: "error" }, "* Please enter an valid e-mail address.")),
                                                        this.state.isEmailExist &&
                                                            React.createElement("div", null,
                                                                React.createElement("label", { style: { fontSize: "12px", color: "red", paddingBottom: "20px" } }, "The email already exists.")),
                                                        React.createElement("label", { style: { fontSize: "12px" } }, "Mobile Number"),
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", id: "mobilenumber", type: "number", autoComplete: "off", placeholder: "Mobile Number", onChange: this.onValueChange, maxLength: 10, minLength: 10, pattern: "^[0-9]*$" }),
                                                            React.createElement("span", { className: "form-fields__border" })),
                                                        (!this.form.mobilenumber.valid && this.formSubmitted) &&
                                                            React.createElement("div", null,
                                                                React.createElement("label", { style: { fontSize: "12px", color: "red", paddingBottom: "20px" } },
                                                                    "Please enter valid Mobile number",
                                                                    React.createElement("br", null),
                                                                    "- Don't include +91 ",
                                                                    React.createElement("br", null),
                                                                    "- Enter 10 digit mobile number")),
                                                        this.state.isPhonenoExists &&
                                                            React.createElement("div", null,
                                                                React.createElement("label", { style: { fontSize: "12px", color: "red", paddingBottom: "20px" } }, "The phonenumber already exists.")),
                                                        this.isProgramSelected &&
                                                            React.createElement("div", null,
                                                                React.createElement("label", { style: { fontSize: "12px" } }, "College Name"),
                                                                React.createElement("div", { className: "form-fields" },
                                                                    React.createElement("input", { className: "input", id: "college_name", type: "text", autoComplete: "off", placeholder: "College Name", onChange: this.onValueChange }),
                                                                    React.createElement("span", { className: "form-fields__border" }))),
                                                        this.isProgramSelected &&
                                                            React.createElement("div", null,
                                                                React.createElement("label", { style: { fontSize: "12px" } }, "Current Education"),
                                                                React.createElement("div", { className: "form-fields" },
                                                                    React.createElement("input", { className: "input", id: "current_education", type: "text", autoComplete: "off", placeholder: "Current Education", onChange: this.onValueChange }),
                                                                    React.createElement("span", { className: "form-fields__border" }))),
                                                        this.isProgramSelected &&
                                                            React.createElement("div", null,
                                                                React.createElement("label", { style: { fontSize: "12px" } }, "Selected Program"),
                                                                React.createElement("div", { className: "form-fields" },
                                                                    React.createElement("input", { className: "input", type: "text", autoComplete: "off", defaultValue: this.program.title, readOnly: true }),
                                                                    React.createElement("span", { className: "form-fields__border" }))),
                                                        React.createElement("label", { style: { fontSize: "12px" } }, "Password"),
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", id: "password", type: "password", autoComplete: "off", placeholder: "Password", onChange: this.onValueChange }),
                                                            React.createElement("span", { className: "form-fields__border" })),
                                                        React.createElement("label", { style: { fontSize: "12px" } }, "Confirm Password"),
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", id: "confirmpassword", type: "password", autoComplete: "off", placeholder: "Re-enter Password", onChange: this.onValueChange }),
                                                            React.createElement("span", { className: "form-fields__border" }))),
                                                    this.state.isError === true &&
                                                        React.createElement("label", { style: { fontSize: "12px", color: "red", paddingBottom: "20px" } }, this.state.errorMessage),
                                                    React.createElement("div", { className: "form-submit" },
                                                        React.createElement("button", { className: "btn btn--primary form-submit__btn js-signUp js-next", type: "button", onClick: () => this.VerifyCredentials() }, "Signup")),
                                                    React.createElement("div", { className: "row", style: { paddingTop: "25px" } },
                                                        React.createElement("div", { className: "col-xl-4 col-sm-4 col-md-4", style: { width: "38%" } },
                                                            React.createElement("p", { className: "mb-0 text-9797 fnt-12 fw-200" },
                                                                React.createElement(Link, { className: "text-5877 signUp__link animate-line enable js-signUp", to: "/" }, "Back to Home"))),
                                                        React.createElement("div", { className: "col-xl-6 col-sm-6 col-md-6", style: { width: "60%" } },
                                                            React.createElement("p", { className: "mb-0 text-9797 fnt-12 fw-200", style: { textAlign: "right" } },
                                                                "Already have an Account?\u00A0",
                                                                React.createElement("span", { className: "text-5877 signUp__link animate-line enable js-signUp", onClick: () => this.showLogin(), style: { cursor: "pointer" } }, "Login")))))),
                                            React.createElement("div", null,
                                                React.createElement(Modal, { show: this.state.show, onHide: () => this.handleModal(), centered: true, size: "sm", backdrop: "non-static" },
                                                    React.createElement(Modal.Body, null,
                                                        React.createElement("p", { style: { textAlign: "center" } }, "Password Mismatch"))))),
                                    (this.state.signUpSucess === true && !this.isProgramSelected) &&
                                        React.createElement("li", { className: "login-slider__item login-form__signup form-section col-12", id: "signUp" },
                                            React.createElement("div", { className: "log-head" },
                                                React.createElement("h1", { className: "fnt-16 fw-400 text-2424 mb-2 alert alert-success text-center", role: "alert" }, "Your account has been created successfully!"),
                                                React.createElement("h5", { className: "fnt-16 fw-500 text-2a2a mb-0 text-center" },
                                                    "Please use ",
                                                    React.createElement("strong", null,
                                                        this.state.loginid,
                                                        "@aitahub.com"),
                                                    " as login email id"),
                                                React.createElement("div", null,
                                                    React.createElement("div", { className: "row" },
                                                        React.createElement("div", { className: "col-sm-2 back-to-home", style: { paddingTop: "20px" } },
                                                            React.createElement(Link, { to: "/" },
                                                                React.createElement("button", { className: "col-sm-2 col-lg-2 col-md-2 btn btn--primary form-submit__btn", style: { fontSize: "16px", cursor: "pointer" }, type: "button" }, "Back to Home"))),
                                                        React.createElement("div", { className: "col-xl-7 space-between col-md-7" }),
                                                        React.createElement("div", { className: "col-xl-2 login-width col-md-2", style: { paddingTop: "20px" } },
                                                            React.createElement("button", { className: "col-sm-2 col-lg-2 col-md-2 btn btn--primary form-submit__btn", onClick: () => this.showLogin(), style: { fontSize: "16px" }, type: "button" }, "Login"))),
                                                    React.createElement("div", null))))))))))));
    }
}
export default signup;