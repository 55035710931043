import React from "react";
import "../../styles/Link.scss";
import "./Community.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
export default class Community extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            name: '',
            email: '',
            phone: '',
            dropDownValue: '',
            dropDown: 'hide',
            mentor: '',
            support: ''
        };
        this.onValueChange = (event) => {
            this.setState({ [event.target.id]: event.target.value });
        };
        this.assignMentor = (event) => {
            this.setState({ mentor: event.target.value });
        };
        this.assignSupport = (event) => {
            this.setState({ support: event.target.value });
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    showDropdown() {
        this.setState({ dropDown: 'show' });
    }
    assignValue(value) {
        this.setState({ dropDownValue: value, dropDown: 'hide' });
    }
    submit() {
        alert(this.state);
    }
    render() {
        return (React.createElement("main", { style: { paddingTop: "145px" } },
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 col-md-6" },
                            React.createElement("div", { className: "banner-content" },
                                React.createElement("h6", { className: "banner-content__title fw-700 text-5d5d" }, "Digital Tech for Anyone, Anywhere in India"),
                                React.createElement("h1", { className: "banner-content__head fw-700 text-1818" }, "AI Skills, Jobs and Pay for Every Talents")),
                            React.createElement("div", { className: "banner-info" },
                                React.createElement("h6", { className: "banner-info__title text-1615 fw-600" }, "Making Learning Affordable and accessible to all"),
                                React.createElement("p", { className: "banner-info__disc fnt-18 text-1818 fw-400 text-justify" }, "CT Skills Galaxy Foundation aims to help in solving India's biggest problem of Employability & of Digital Technology Skills Gap; Build AI Powered Careers for Underserved Talents in Millions in India, Combining Industry efforts and Technology."))),
                        React.createElement("div", { className: "col12 col-md-6 col-lg-5 offset-lg-1" },
                            React.createElement("form", { id: "enquiry", autoComplete: "off", "data-parsley-validate": "", method: "post" },
                                React.createElement("div", { className: "form-aitahack" },
                                    React.createElement("div", { className: "form-aitahack__head" },
                                        React.createElement("h4", { className: "mb-0 fnt-18 text-1615 fw-700" }, "Build Careers for Underserved Talents"),
                                        React.createElement("p", { className: "mb-0 fnt-16 fw-500 text-4a4a" }, "Pledge Your Support- Fund or Mentor")),
                                    React.createElement("div", { className: "form-aitahack__form scroll-container overflow-x-hidden position-relative ps ps--active-y" },
                                        React.createElement("div", { className: "row" },
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", id: "name", type: "text", autoComplete: "off", required: true, onChange: this.onValueChange }),
                                                            React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.name !== '' }) }, "Name"),
                                                            React.createElement("span", { className: "form-fields__border" }))))),
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", type: "email", id: "email", autoComplete: "off", required: true, onChange: this.onValueChange }),
                                                            React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.email !== '' }) }, "Email"),
                                                            React.createElement("span", { className: "form-fields__border" }))))),
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "form-field__input", name: "community", type: "hidden", value: "community" }),
                                                            React.createElement("input", { className: "input", type: "number", id: "phone", autoComplete: "off", "data-parsley-minlength": "10", "data-parsley-maxlength": "10", "data-parsley-type": "digits", "data-parsley-error-message": "Enter valid 10 digit number", required: true, onChange: this.onValueChange }),
                                                            React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.phone !== '' }) }, "Phone"),
                                                            React.createElement("span", { className: "form-fields__border" }))))),
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "hidden-input", type: "text", required: true }),
                                                            React.createElement("input", { className: "input-check", type: "checkbox", autoComplete: "off", onClick: () => this.showDropdown() }),
                                                            React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.dropDownValue !== '' }) }, "WHo"),
                                                            React.createElement("span", { className: "form-fields__border" }),
                                                            React.createElement("div", { className: "selected-item" },
                                                                React.createElement("div", { className: "selected-item__text" },
                                                                    this.state.dropDownValue,
                                                                    " ")),
                                                            React.createElement("div", { className: classNames("select-dropdown", { "hideDropdown": this.state.dropDown === 'hide', "showDropdown": this.state.dropDown === 'show' }) },
                                                                React.createElement("div", { className: "select-dropdown__item" },
                                                                    React.createElement("ul", { className: "select-list scroll-container position-relative mb-0 ps" },
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Computer Science") },
                                                                            React.createElement("label", null,
                                                                                "Computer Science",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Computer Science", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Science") },
                                                                            React.createElement("label", null,
                                                                                "Science",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Science", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Technology") },
                                                                            React.createElement("label", null,
                                                                                "Technology",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Technology", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Engineering") },
                                                                            React.createElement("label", null,
                                                                                "Engineering",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Engineering", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Mathematics") },
                                                                            React.createElement("label", null,
                                                                                "Mathematics",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Mathematics", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("div", { className: "ps__rail-x", style: { left: "0px", bottom: "0px" } },
                                                                            React.createElement("div", { className: "ps__thumb-x", tabIndex: 0, style: { left: "0px", width: "0px" } })),
                                                                        React.createElement("div", { className: "ps__rail-y", style: { top: "0px", right: "0px" } },
                                                                            React.createElement("div", { className: "ps__thumb-y", tabIndex: 0, style: { top: "0px", height: "0px" } }))))))))),
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields custom-radios pl-0" },
                                                            React.createElement("h6", { className: "fnt-18 text-2424 fw-500" }, "support"),
                                                            React.createElement("div", { className: "d-flex flex-wrap clearfix" },
                                                                React.createElement("label", { className: "custom-radio-1 float-left mr-3 mr-md-4" },
                                                                    React.createElement("input", { type: "radio", checked: this.state.support === 'Fund', value: "Fund", required: true, "data-parsley-multiple": "radio-support", onChange: this.assignSupport }),
                                                                    React.createElement("span", { className: "checkmark" }),
                                                                    React.createElement("span", { className: "ml-1 fnt-14 fw-400 tex-2424" }, "Fund")),
                                                                React.createElement("label", { className: "custom-radio-1 float-left mr-3 mr-md-4" },
                                                                    React.createElement("input", { type: "radio", checked: this.state.support === 'Mentor', value: "Mentor", required: true, "data-parsley-multiple": "radio-support", onChange: this.assignSupport }),
                                                                    React.createElement("span", { className: "checkmark" }),
                                                                    React.createElement("span", { className: "ml-1 fnt-14 fw-400 tex-2424" }, "Mentor")),
                                                                React.createElement("label", { className: "custom-radio-1 float-left mr-3 mr-md-4" },
                                                                    React.createElement("input", { type: "radio", checked: this.state.support === 'Both', value: "Both", required: true, "data-parsley-multiple": "radio-support", onChange: this.assignSupport }),
                                                                    React.createElement("span", { className: "checkmark" }),
                                                                    React.createElement("span", { className: "ml-1 fnt-14 fw-400 tex-2424" }, "Both"))))))),
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields custom-radios pl-0" },
                                                            React.createElement("h6", { className: "fnt-18 text-2424 fw-500" }, "Mentor"),
                                                            React.createElement("div", { className: "d-flex flex-wrap clearfix" },
                                                                React.createElement("label", { className: "custom-radio-1 float-left mr-3 mr-md-4" },
                                                                    React.createElement("input", { type: "radio", checked: this.state.mentor === '6/9/12 Hours a Month', value: "6/9/12 Hours a Month", required: true, "data-parsley-multiple": "radio-mentor", onChange: this.assignMentor }),
                                                                    React.createElement("span", { className: "checkmark" }),
                                                                    React.createElement("span", { className: "ml-1 fnt-14 fw-400 tex-2424" }, "6/9/12 Hours a Month")),
                                                                React.createElement("label", { className: "custom-radio-1 float-left mr-3 mr-md-4" },
                                                                    React.createElement("input", { type: "radio", checked: this.state.mentor === 'Technology', value: "Technology", required: true, "data-parsley-multiple": "radio-mentor", onChange: this.assignMentor }),
                                                                    React.createElement("span", { className: "checkmark" }),
                                                                    React.createElement("span", { className: "ml-1 fnt-14 fw-400 tex-2424" }, "Technology")),
                                                                React.createElement("label", { className: "custom-radio-1 float-left mr-3 mr-md-4" },
                                                                    React.createElement("input", { type: "radio", checked: this.state.mentor === 'Business Field', value: "Business Field", required: true, "data-parsley-multiple": "radio-mentor", onChange: this.assignMentor }),
                                                                    React.createElement("span", { className: "checkmark" }),
                                                                    React.createElement("span", { className: "ml-1 fnt-14 fw-400 tex-2424" }, "Business Field"))))))))),
                                    React.createElement("div", { className: "col-12 px-0" },
                                        React.createElement("div", { className: "form-submission w-100" },
                                            React.createElement("button", { className: "btn btn--skew w-100", type: "submit", onClick: () => this.submit() }, "Submit"))))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "section-mission sec-pd" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row align-items-center" },
                            React.createElement("div", { className: "col-12 col-md-6" },
                                React.createElement("p", { className: "position-relative px-5 text-justify" },
                                    React.createElement("img", { className: "img-fluid blockquoteRight", src: "assets/images/community/blockquoteRight.png" }),
                                    "AitaHub Mission is to Transform Education and Technology, Bridge the Skill Gap & Build a Successful New Careers for One Million Aspiring Individuals by 2030 focusing Talents from Lower Tier Cities, Towns & Rural India with Diverse Education Background",
                                    React.createElement("img", { className: "img-fluid blockquoteLeft", src: "assets/images/community/blockquoteRight.png" }))),
                            React.createElement("div", { className: "col-12 col-md-6 pt-5 pt-md-0" },
                                React.createElement("div", { className: "common-card common-card--mission" },
                                    React.createElement("h4", { className: "fnt-20 text-1615 fw-600" }, "Build a High Growth Career for Talents from Lower Tier Cities."),
                                    React.createElement("p", { className: "fw-400" }, "Every Hour spent on skilling aitahub students will be monetized to sponsor career programs for talents from economically weaker sections."),
                                    React.createElement("span", { className: "d-block fnt-18 fw-500 text-5d5d mt-4 pt-md-2 pt-lg-3" }, "6/9/12 Hours a Month"),
                                    React.createElement(Link, { className: "btn btn--skew d-inline-block mt-4", to: "/signup" }, "Sign up"))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("ul", { className: "flip-card-list" },
                                React.createElement("li", { className: "flip-card-list__item row" },
                                    React.createElement("div", { className: "flip-card-content col-12 col-md-6" },
                                        React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3" }, "Education to Employment: Building a Systems, Partnerships that can work"),
                                        React.createElement("div", { className: "point-list" },
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Opportunity for Every Graduate to Build High Growth Careers"),
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Building Tools, Resources, Mentors and Solutions for every one"),
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "End to End Career Ecosystem for learners with age of 10\u2019s, 20\u2019s to 30\u2019s"))),
                                    React.createElement("div", { className: "flip-card-img col-12 col-md-6" },
                                        React.createElement("img", { className: "img-fluid", src: "assets/images/community/cmt1.png", alt: "" })))))))),
            React.createElement("section", { className: "sec-pd p-0" },
                React.createElement("div", { className: "sec-pd--withBackground section-home-community" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h4", { className: "fnt-32 fw-700 text-1818 sec-head" }, "Our Initiatives and Impact"),
                                React.createElement("ul", { className: "community-list mb-0 row" },
                                    React.createElement("li", { className: "community-list__item col-12 col-md-6" },
                                        React.createElement(Link, { className: "d-block community", to: "#" },
                                            React.createElement("div", { className: "community-img p-2" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/community/cm1.png" })),
                                            React.createElement("div", { className: "community-body pt-lg-2 px-lg-3 pb-lg-5" },
                                                React.createElement("p", { className: "mb-0" }, "Making Learning Affordable")))),
                                    React.createElement("li", { className: "community-list__item col-12 col-md-6" },
                                        React.createElement(Link, { className: "d-block community", to: "#" },
                                            React.createElement("div", { className: "community-img p-2" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/community/cm2.png" })),
                                            React.createElement("div", { className: "community-body pt-lg-2 px-lg-3 pb-lg-5" },
                                                React.createElement("p", { className: "mb-0" }, "Careers to all Aspiring Individuals")))))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 mb-1" })),
                    React.createElement("h3", { className: "text-1216 fw-600 fnt-32 mb-4" }, "Building Tools, Resources & Capabilities for Inclusive Job Oriented Education"),
                    React.createElement("h3", { className: "text-1216 fw-600 fnt-20 mb-3" }, "Helping learners from Pan India reach their full potential"),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-6 col-lg-4" },
                            React.createElement("div", { className: "ah-card" },
                                React.createElement("div", { className: "ah-card__img" },
                                    React.createElement("img", { src: "assets/images/community/img-1.png", alt: "img" })),
                                React.createElement("div", { className: "ah-card__desc" },
                                    React.createElement("p", { className: "fw-500 fnt-18 text-1818" }, "AI Tech Skills Lab & 4.0 Career Centres"),
                                    React.createElement(Link, { className: "btn btn--skew mt-4 mt-lg-5", to: "/login" }, "Join")))),
                        React.createElement("div", { className: "col-md-6 col-lg-4" },
                            React.createElement("div", { className: "ah-card" },
                                React.createElement("div", { className: "ah-card__img" },
                                    React.createElement("img", { src: "assets/images/community/img-2.png", alt: "img" })),
                                React.createElement("div", { className: "ah-card__desc" },
                                    React.createElement("p", { className: "fw-500 fnt-18 text-1818" }, "AitaEngine for Job Readiness "),
                                    React.createElement(Link, { className: "btn btn--skew mt-4 mt-lg-5", to: "/login" }, "Demo")))),
                        React.createElement("div", { className: "col-md-6 col-lg-4" },
                            React.createElement("div", { className: "ah-card" },
                                React.createElement("div", { className: "ah-card__img" },
                                    React.createElement("img", { src: "assets/images/community/img-3.png", alt: "img" })),
                                React.createElement("div", { className: "ah-card__desc" },
                                    React.createElement("p", { className: "fw-500 fnt-18 text-1818" }, "Train the Trainer in 4.0 Skills"),
                                    React.createElement(Link, { className: "btn btn--skew mt-4 mt-lg-5", to: "/contact-us" }, "Contact"))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "sec-pd section-student-jobs" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "jobs-head" },
                                    React.createElement("h2", { className: "fnt-32 text-1615 fw-600 col-lg-10 px-0" }, "Preparing Students with Diverse Education Background for Most in Demand Jobs and Skills")),
                                React.createElement("div", { className: "job-type" },
                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Tech Jobs"),
                                    React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p1.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analyst")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p2.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Engineer")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p3.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning Engineer")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p5.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Scientist")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/cds.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Citizen Data Scientist")))))),
                                React.createElement("div", { className: "job-type" },
                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Specialists Careers"),
                                    React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc1.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc2.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Deep Learning")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc3.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "NLP")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc4.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Neural Networks")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc5.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Computer Vision")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc6.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "High End Analytics")))))),
                                React.createElement("div", { className: "job-type" },
                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Skills"),
                                    React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk1.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Coding")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk2.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Modelling")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk3.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analytics")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk4.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Digital")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk5.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Business")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk6.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Personality Skills and Project Management")))))),
                                React.createElement("div", { className: "job-type" },
                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Business Jobs"),
                                    React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr1.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Business Analyst")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr2.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Management Consultant")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/ca.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Chartered Accountant")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr4.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Investment Banker")))),
                                        React.createElement("li", { className: "careers-list__item float-left" },
                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                React.createElement("div", { className: "careers-icon float-left" },
                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr5.svg" })),
                                                React.createElement("div", { className: "careers-title float-left" },
                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Marketing Manager"))))))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: "col-md-10 col-lg-10 mx-auto community-contact-link w-100 text-center" },
                                React.createElement("h4", { className: "fnt-32 fw-700 text-1818" }, "Contribute today for a better future"),
                                React.createElement("p", { className: "fnt-24" }, "Connect with our Social Impact team to find out about Partnering Opportunities, Community Initiatives, Programs, and more."),
                                React.createElement("div", { className: "community-contact-link__btn mb-4 pb-md-3 pb-lg-0 mb-lg-5" },
                                    React.createElement(Link, { className: "btn btn--skew d-inline-block mt-4", to: "/contact-us" }, "Get in Touch")),
                                React.createElement("p", null, "CT Skills Galaxy Foundation is a registered 12 A non-profit in the India, and all funds given to us are tax deductible in the India  to the fullest extent allowed by law. Our Tax ID number is \u2026\u2026\u2026\u2026\u2026.."))))))));
    }
}