import React from "react";
import "../../styles/Link.scss";
import "./Partner.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
export default class Partner extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            name: '',
            email: '',
            phone: '',
            scroll: '',
            dropDown: 'hide',
            dropDownValue: '',
        };
        this.onValueChange = (event) => {
            this.setState({ [event.target.id]: event.target.value });
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    showDropdown() {
        this.setState({ dropDown: 'show' });
    }
    assignValue(value) {
        this.setState({ dropDownValue: value, dropDown: 'hide' });
    }
    render() {
        return (React.createElement("main", { style: { paddingTop: "15px" } },
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 col-md-6" },
                            React.createElement("div", { className: "banner-content" },
                                React.createElement("h6", { className: "banner-content__title fw-700 text-5d5d mt-2" }, "Job Placement, High Pay Careers for Students"),
                                React.createElement("h1", { className: "banner-content__head fw-700 text-1818" }, "Transforming Education to Industry 4.0 Skills and Careers ")),
                            React.createElement("div", { className: "banner-info" },
                                React.createElement("p", { className: "banner-info__disc fnt-18 text-1818 fw-400", style: { textAlign: "justify" } }, "Aitahub with AI Skills Lab and Machine Learning enabled Job Readiness Bot, empower educators in building Most in Demand Domain Knowledge and Job specific skills that can translate to high growth, high pay, global careers for their students. "))),
                        React.createElement("div", { className: "col12 col-md-6 col-lg-5 offset-lg-1" },
                            React.createElement("form", { id: "enquiry", autoComplete: "off", "data-parsley-validate": "", method: "post" },
                                React.createElement("div", { className: "form-aitahack" },
                                    React.createElement("div", { className: "form-aitahack__head" },
                                        React.createElement("h4", { className: "mb-0 fnt-18 text-1615 fw-700" }, "Join a network of Higher Education Partners")),
                                    React.createElement("div", { className: "form-aitahack__form scroll-container overflow-x-hidden position-relative ps ps--active-y" },
                                        React.createElement("div", { className: "row" },
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", type: "text", id: "name", autoComplete: "off", required: true, onChange: this.onValueChange }),
                                                            React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.name !== '' }) }, "Name"),
                                                            React.createElement("span", { className: "form-fields__border" }))))),
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "input", type: "email", id: "email", autoComplete: "off", required: true, onChange: this.onValueChange }),
                                                            React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.email !== '' }) }, "Email"),
                                                            React.createElement("span", { className: "form-fields__border" }))))),
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "form-field__input", id: "partners", type: "hidden", value: "partners" }),
                                                            React.createElement("input", { className: "input", type: "number", id: "phone", autoComplete: "off", "data-parsley-minlength": "10", "data-parsley-maxlength": "10", "data-parsley-type": "digits", "data-parsley-error-message": "Enter valid 10 digit number", required: true, onChange: this.onValueChange }),
                                                            React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.phone !== '' }) }, "Phone"),
                                                            React.createElement("span", { className: "form-fields__border" }))))),
                                            React.createElement("div", { className: "col-12" },
                                                React.createElement("div", { className: "row" },
                                                    React.createElement("div", { className: "form-required col-md-12" },
                                                        React.createElement("div", { className: "form-fields" },
                                                            React.createElement("input", { className: "hidden-input", type: "text", required: true }),
                                                            React.createElement("input", { className: "input-check", type: "checkbox", autoComplete: "off", onClick: () => this.showDropdown() }),
                                                            React.createElement("label", { className: classNames("form-fields__label", { "active": this.state.dropDownValue !== '' }) }, "WHo"),
                                                            React.createElement("span", { className: "form-fields__border" }),
                                                            React.createElement("div", { className: "selected-item" },
                                                                React.createElement("div", { className: "selected-item__text" }, this.state.dropDownValue)),
                                                            React.createElement("div", { className: classNames("select-dropdown", { "hideDropdown": this.state.dropDown === 'hide', "showDropdown": this.state.dropDown === 'show' }) },
                                                                React.createElement("div", { className: "select-dropdown__item" },
                                                                    React.createElement("ul", { className: "select-list scroll-container position-relative mb-0 ps" },
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Computer Science") },
                                                                            React.createElement("label", null,
                                                                                "Computer Science",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Computer Science", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Science") },
                                                                            React.createElement("label", null,
                                                                                "Science",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Science", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Technology") },
                                                                            React.createElement("label", null,
                                                                                "Technology",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Technology", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Engineering") },
                                                                            React.createElement("label", null,
                                                                                "Engineering",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Engineering", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("li", { className: "select-list__item", onClick: () => this.assignValue("Mathematics") },
                                                                            React.createElement("label", null,
                                                                                "Mathematics",
                                                                                React.createElement("input", { className: "input-rad", type: "radio", name: "technology-name", value: "Mathematics", "data-parsley-multiple": "technology-name" }))),
                                                                        React.createElement("div", { className: "ps__rail-x", style: { left: "0px", bottom: "0px" } },
                                                                            React.createElement("div", { className: "ps__thumb-x", tabIndex: 0, style: { left: "0px", width: "0px" } })),
                                                                        React.createElement("div", { className: "ps__rail-y", style: { top: "0px", right: "0px" } },
                                                                            React.createElement("div", { className: "ps__thumb-y", tabIndex: 0, style: { top: "0px", height: "0px" } }))))))))))),
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("div", { className: "form-submission w-100" },
                                            React.createElement("button", { className: "btn btn--skew w-100", type: "submit" }, "Submit"))))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h3", { className: "text-1216 fw-600 fnt-32 mb-4" }, "Job Oriented Higher Education"))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-6 col-lg-4" },
                            React.createElement("div", { className: "ah-card" },
                                React.createElement("div", { className: "ah-card__img" },
                                    React.createElement("img", { src: "assets/images/partner/img-1.png", alt: "img" })),
                                React.createElement("div", { className: "ah-card__desc" },
                                    React.createElement("p", { className: "fw-500 fnt-18 text-1818" }, "AI Tech Skills Lab & 4.0 Career Centres"),
                                    React.createElement(Link, { className: "btn btn--skew mt-4 mt-lg-5", to: "/login" }, "Join")))),
                        React.createElement("div", { className: "col-md-6 col-lg-4" },
                            React.createElement("div", { className: "ah-card" },
                                React.createElement("div", { className: "ah-card__img" },
                                    React.createElement("img", { src: "assets/images/partner/img-2.png", alt: "img" })),
                                React.createElement("div", { className: "ah-card__desc" },
                                    React.createElement("p", { className: "fw-500 fnt-18 text-1818" }, "AitaEngine for Job Readiness "),
                                    React.createElement(Link, { className: "btn btn--skew mt-4 mt-lg-5", to: "/engine" }, "Demo")))),
                        React.createElement("div", { className: "col-md-6 col-lg-4" },
                            React.createElement("div", { className: "ah-card" },
                                React.createElement("div", { className: "ah-card__img" },
                                    React.createElement("img", { src: "assets/images/partner/img-3.png", alt: "img" })),
                                React.createElement("div", { className: "ah-card__desc" },
                                    React.createElement("p", { className: "fw-500 fnt-18 text-1818" }, "Train the Trainer in 4.0 Skills"),
                                    React.createElement(Link, { className: "btn btn--skew mt-4 mt-lg-5", to: "/contact-us" }, "Contact"))))))),
            React.createElement("section", { className: "sec-pd sec-pd--top section-student-jobs sec-pd--eBttm" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-10" },
                            React.createElement("div", { className: "jobs-head" },
                                React.createElement("h2", { className: "fnt-32 text-1615 fw-600" }, "Preparing Students with Diverse Education Background for Most in Demand Jobs and Skills")))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: "job-type" },
                                React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Tech Jobs"),
                                React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p1.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Data Analyst")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p2.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Data Engineer")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p3.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Machine Learning Engineer")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p5.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Data Scientist")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/cds.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Citizen Data Scientist")))))),
                            React.createElement("div", { className: "job-type" },
                                React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Specialists Careers"),
                                React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc1.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Machine Learning")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc2.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Deep Learning")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc3.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "NLP")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc4.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Neural Networks")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc5.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Computer Vision")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc6.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "High End Analytics")))))),
                            React.createElement("div", { className: "job-type" },
                                React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Skills"),
                                React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk1.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Coding")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk2.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Modelling")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk3.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Data Analytics")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk4.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Digital")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk5.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Business")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sk6.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Personality Skills and Project Management")))))),
                            React.createElement("div", { className: "job-type" },
                                React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Business Jobs"),
                                React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr1.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Business Analyst")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr2.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Management Consultant")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/ca.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Chartered Accountant")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr4.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Investment Banker")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr5.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-600" }, "Marketing Manager")))))))))),
            React.createElement("section", { className: "section-home-blog sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-6 col-lg-5" },
                            React.createElement("h3", { className: "text-1818 fnt-32 fw-700 mb-3 pr-lg-4" }, "Your Learning and Skilling Partner"),
                            React.createElement("h6", { className: "text-1216 fnt-20 fw-600 mb-4" }, "Business & Academic Partnerships at Work"),
                            React.createElement("ul", { className: "ul-style" },
                                React.createElement("li", null,
                                    React.createElement("p", { className: "text-1818 fnt18" }, "Rank among Top, Become Global")),
                                React.createElement("li", null,
                                    React.createElement("p", { className: "text-1818 fnt18" }, "Build Global Careers for Students")),
                                React.createElement("li", null,
                                    React.createElement("p", { className: "text-1818 fnt18" }, "High Pay, High JOB Placement ")),
                                React.createElement("li", null,
                                    React.createElement("p", { className: "text-1818 fnt18" }, "Access to Global Corporate Network")),
                                React.createElement("li", null,
                                    React.createElement("p", { className: "text-1818 fnt18" }, "AI Brand Equity & Growth")),
                                React.createElement("li", null,
                                    React.createElement("p", { className: "text-1818 fnt18" }, "Become Industry R&D Lab"))),
                            React.createElement(Link, { className: "btn btn--skew mt-4 mt-lg-5", to: "/login" }, "Join")),
                        React.createElement("div", { className: "col-md-6 col-lg-6 offset-lg-1" },
                            React.createElement("div", { className: "img-c" },
                                React.createElement("img", { src: "assets/images/partner/img-4.png", alt: "img" })))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: "div-box" },
                                React.createElement("h6", { className: "text-1818 fnt-24 text-center" },
                                    "Aitahub is creating one hub for companies to acquire Most in Demand Skills ",
                                    React.createElement("br", null),
                                    "and to hire diverse, high skilled talents for AI powered Jobs globally. Students of Higher Education Institutions to get the Benefits. "))))))));
    }
}