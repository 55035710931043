import { Box, Button,Typography } from "@mui/material";
import React, { useEffect } from 'react'
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import {getquizresult} from '../../store/action'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
export default function Quizresult({quickcheckid,loadingresult,quizlength,courseId}) {
    const dispatch = useDispatch();
	console.log("checid",quickcheckid)
    console.log("loading",loadingresult)
    useEffect(() => {
        dispatch(getquizresult(quickcheckid));
      
        
      }, [quickcheckid,dispatch]);
    const result = useSelector((state) => state.quizResult);
    console.log(result)

    if (!result) {
        return <h3>Loading result...</h3>;
    }

    return (
        <div>
        {result && <Dialog 
                        disableBackdropClick="false"
                        color="success"
                        
                        fullWidth
                        maxWidth="xs"
                        
                        PaperProps={{
                            style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            },
                        }} open={loadingresult} 
                        >
                        <DialogContent sx={{display:'block',justifyContent:'center',alignItem:'center'}}>
                            <Paper sx={{ border: '1px solid grey',borderRadius:'8px' ,bgcolor: 'background.paper',maxWidth:"504px" }}>    
                                <List sx={{p:'0px',m:'1px'}}>
                                    <ListItemText>
                                    
                                    <Typography variant = "h6"  sx={{textAlign:'center',fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Your Result</Typography>
                                    
                                    </ListItemText>
                                    
                                    <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                                    <div style={{display:'flex' ,flexDirection:'row'}}>
                                        <Typography minWidth="100px" sx={{ml:'2rem',textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Total Marks</Typography>
                                        <Typography sx={{ml:'10rem',fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{result.Totalmarks}</Typography>
                                    </div>
                                    </ListItemText>
                               
                                    <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                                    <div style={{display:'flex' ,flexDirection:'row'}}>
                                        <Typography minWidth="100px" sx={{ml:'2rem',textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Your Score</Typography>
                                        <Typography sx={{ml:'10rem',fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{result.Answeredmarks}</Typography>
                                        </div>
                                    </ListItemText>
                                    <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                                    <div style={{display:'flex' ,flexDirection:'row'}}>
                                        <Typography minWidth="100px" sx={{ml:'2rem',textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Short answer</Typography>
                                        <Typography sx={{ml:'10rem',fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{result.Shortansweredmarks}</Typography>
                                        </div>
                                    </ListItemText>
                                    <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                                    <div style={{display:'flex' ,flexDirection:'row'}}>
                                        <Typography minWidth="100px" sx={{ml:'2rem',textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Total Questions</Typography>
                                        <Typography sx={{ml:'9.3rem',fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>{quizlength}</Typography>
                                        </div>
                                    </ListItemText>
                                    {/* <ListItemText sx={{paddingTop:'.5rem',paddingBottom:'.5rem',paddingLeft:'.5rem',paddingRight:'.5rem',borderBottom:'1px solid rgb(230, 232, 240)'}} >
                                    <div style={{display:'flex' ,flexDirection:'row'}}>
                                        <Typography minWidth="100px" sx={{ml:'2rem',textAlign:'left',fontSize:'1rem',fontWeight:540,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>Marks for each question</Typography>
                                        <Typography sx={{ml:'5.3rem',fontSize:'.875rem',fontWeight:400,fontFamily:'Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'}}>10</Typography>
                                        </div>
                                    </ListItemText> */}
                                </List>
                                <Box 
                      
                                    sx={{display: 'flex',justifyContent:'center',p:'.5rem'}}
                                    >   <Link to={{pathname:"/viewquiz" , state:{courseid:courseId} }} style={{ textDecoration: "none",color: "#000000"  }}>
                                        <Button   sx={{backgroundColor:'#f95877',color:"#fff"}} size="small" >
                                            close
                                        </Button>
                                        </Link>
                                        
                                </Box>
                            </Paper>
                        </DialogContent>
    </Dialog> 
    }
    </div>
    
    )
    
}
