import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import { useEffect } from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Detailscard from './Deatilscard';
import Breadcrums from '../Breadcrums/Breadcrums';
import Service from "../../service/service"
import Config from "../../components/Global";


export default function Myprofile() {
  // let { id } = useParams();
  const [user, setUser] = React.useState();
  useEffect(() => {
    new Service().getUserByEmail(Config.getClaims().email).then(res=>{
      setUser(res.data)
      console.log("profile data",res.data)
  })

  //   dispatch(getstudentdetail({id}));
    
  }, []);
  // const users = useSelector((state) => state.Stulist.studentdetails);
  console.log("users",user)


    function stringAvatar(name) {
    return {
        // sx: {
        //     bgcolor: "#f95877",
        //     fontSize:13,
        //   },
      
      children: `${name.split(' ')[0][0]}`,
    };
  }
  const breadcrums = 
[
  {
    page: "HOME",
    route: "/dashboard"
  },
  {
    page: "MY PROFILE",
    route: "/myprofile"
  },
  
]
  


    return (
      <main>
        <AdminNavbar menu="mycourse" navbar="" />
        <div style={{marginLeft:'15px'}}>


          <Grid container columns={12} sx  = {{mb:2,mt:3,ml:'12rem'}}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{fontWeight:400,fontSize: '1.55rem', fontFamily:"'Poppins' !important"}}
                  >
                    My Profile
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    mb: 2,
                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                  }}
                >
                  <Breadcrums pages={breadcrums} />
                </Grid>
            </Grid>
            {user && 

            
            
            <Grid  sx={{ ml:'12rem' ,pr:'3.42rem',mb:6}} spacing={1}  container  column={12}>
                <Grid item lg={11} md={11} sm={12} xs={12}>
                    <div style={{display:'flex',flexDirection:'row'}}>
                            
                        <Avatar 
                        {...stringAvatar(user.first_name)}
                        sx={{height:'50px',width:'50px',
                        mt:.5,
                         bgcolor: "#f95877",fontSize:30}}
                         />
                            
                        <div style={{display:'block'}}>
                        <Typography  sx={{alignText:"left",marginLeft:'.5rem',fontFamily:"'Poppins' !important",color:"#336",fontSize: '1.5rem',fontWeight:"500"}}>{user.first_name+" "+user.last_name}</Typography>
                        <Stack direction="row" spacing={1}>
                        <Typography  sx={{alignText:"left",marginLeft:'.5rem',marginBottom:'1rem',fontFamily:"'Poppins' !important",color:"#336",fontSize: '.975rem',fontWeight:"450"}}>User Id:</Typography>
                        <Chip size="small" sx={{bgcolor:'#f95877',color:"#fff",fontWeight:"450"}} label={user.email}  />
                        </Stack>
                        </div>
                    </div>
                    
                </Grid>

                <Grid width='100%' sx={{mt:'1rem'}}item lg={11} md={11} sm={12} xs={12}>
                  <Detailscard user={user} />
                </Grid>

            </Grid>
           }
        </div>
      </main>
    )
}