import React from "react";
import { Link } from "react-router-dom";
import "./Blogs.scss";
class Blogs extends React.Component {
    render() {
        return (React.createElement("section", { className: "section-home-blog sec-pd" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 d-flex justify-content-between align-items-center mb-3 pb-md-1" },
                        React.createElement("h5", { className: "fnt-20 fw-700 text-5d5d mb-0" }, "Aitahack"),
                        React.createElement(Link, { className: "fnt-16 fw-600 view-all d-inline-block btn-arrow", style: { textDecoration: "none" }, to: "/aitahack" }, "Visit Our Blog")),
                    React.createElement("div", { className: "col-12 col-md-6 col-lg-5 mb-4 mb-md-0" },
                        React.createElement("div", { className: "home-blog-head w-100 d-flex flex-column flex-md-row align-items-end justify-content-between cleafix" },
                            React.createElement("div", { className: "home-blog-head__title float-left" },
                                React.createElement("h2", { className: "fnt-32 fw-700 text-1818 mb-2 mb-md-3" }, "Daily Hackathon for AI Community"),
                                React.createElement("p", { className: "fnt-18 fnt-500 text-1818 text-justify" }, "Share Idea, Create Datasets; Identify Problems; Model, Code, Predict and Solve Data to Value Problems; Collaborate Learning, Compete with Global Talents"))),
                        React.createElement("div", { className: "point-list mb-3 mb-md-5 pr-lg-5" },
                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Community of Data Scientist, ML Specialists, Data Engineers, AI Engineers and Specialists"),
                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Partiipation in regular user case challenges and Aitathons")),
                        React.createElement(Link, { to: "/aitahack", className: "fnt-16 fw-600 d-inline-block btn btn--skew mt-lg-2" }, "Join Aitahack 1.0")),
                    React.createElement("div", { className: "col-12 col-md-5 offset-md-1 offset-lg-2" },
                        React.createElement("ul", { className: "home-blog row mb-0" },
                            React.createElement("li", { className: "home-blog__item col-12" },
                                React.createElement(Link, { to: "/aitahack", className: "blog" },
                                    React.createElement("div", { className: "blog__img" },
                                        React.createElement("img", { src: "assets/images/home/blog1.png" })),
                                    React.createElement("div", { className: "blog-body" },
                                        React.createElement("div", { className: "blog-body__head mb-1" },
                                            React.createElement("span", { className: "fnt-20 fw-700 text-5d5d" }, "Latest Blog")),
                                        React.createElement("div", { className: "blog-body__title mb-2 pb-md-1" },
                                            React.createElement("h4", { className: "fnt-24 fw-700 text-1818" }, "Top 10 Data Science Careers")),
                                        React.createElement("div", { className: "blog-body__info" },
                                            React.createElement("p", { className: "fnt-18 fw-500 text-1818" }, "AI & Data science careers\u00A0are in high-demand globally")),
                                        React.createElement("div", { className: "blog-body__btn" },
                                            React.createElement("button", { className: "btn btn--skew" }, "Read More")))))))))));
    }
}
export default Blogs;