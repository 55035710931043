import React from "react";
import { Box, Button, Grid, Stack, Typography} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControl from "@mui/material/FormControl";
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios'
import { QUIZDB_URL } from "../constants/global";

export default function RadioQuiz({ questNo,quickCheckId,quiz,timeEnded }) {
  
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);





  const handleChange = (event) => {
    const value = event.target.value;
    console.log(value);


    // added below code to update selected options
    const list = [...selected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setSelected(list);

    setOpenAlert(false);
  };


  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpenAlert(false);

  };


  const createQuizMark = (questionId) => {

    if (selected.length === 0) {
      setOpenAlert(true);
      }

    
else{

    setOpen(true);
    console.log("selected",selected);


    var data = new FormData();
    data.append('quickCheckId', quickCheckId);
    data.append('questionId', questionId);
    data.append('answerId', selected);
    data.append('answer', '');

    var config = {
      method: 'post',
    maxBodyLength: Infinity,
      url: QUIZDB_URL+'/quizMarks',

      data : data
    };

    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });

      };
}
  
  
  // console.log("quiz", quiz);
  return (
    <div>
      <Grid container columns={12}>
        <Grid item sm={1} md={1} lg={1}>
          <Typography variant="h6">Q.{questNo}</Typography>
          {/* Question no */}
        </Grid>

        <Grid item sm={11} md={11} lg={11}>
          {/* Question content*/}
          <Box sx={{ p: 3, mb: 3, borderRadius: 2, bgcolor: "#f6f8ff" }}>
            <Stack direction="column" spacing={1}>
              <Typography variant="body1">{quiz.description}</Typography>
              <Typography variant="body1">{quiz.question}</Typography>
              <Typography variant="body2">select more than one answer:</Typography>
              {quiz.image? (<Box
                component="img"
                sx={{

                  pt:2,

                  height: 233,
                  width: 350,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                alt="The house from the offer."
                src={quiz.image}
                />) :null}

            <FormControl sx = {{mt:1,p:1}}>
            <Typography sx = {{color:"text.secondary"}}> {quiz.anstext}</Typography>

              <div 
              // style={{ display: "flexend", margin: 1  }}
              >
                {quiz.answer.map((option, id) => (
                  <div key={id}>
                  <Checkbox
                    value={option.answerId}
                    onChange={handleChange}
                    checked={selected.includes(option.answerId)}
                    disabled = {timeEnded | open}
                  />
                  <span>{option.answer}</span>
                </div>
                ))}
              </div>
              
            </FormControl>

            <Button variant = "contained"
             disabled = {timeEnded | open}
             sx={{ maxWidth: '70px', maxHeight: '35px', minWidth: '70px', minHeight: '35px',
             bgcolor:"#f95877" ,color: 'white',':hover': {
              bgcolor: '#f95877',
              color: 'white', }
             
             
             }}

             onClick = {() => createQuizMark(quiz.id)}
             >
              
              <Typography variant = "body2"  sx= {{fontSize:18,textTransform: 'none',color:"white"}}>
                Check
                {/* {quiz.submittext} */}
                </Typography>
              
              </Button>
            </Stack>

          </Box>

          {/* alert */}
          <Box >
            <Snackbar anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                  }} open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                      <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
                          Please Choose The Answer!
                      </Alert>
                  </Snackbar>
          </Box>

        </Grid>
      </Grid>
    </div>
  );
}
