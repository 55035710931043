import React from "react";
import "./Home.scss";
import { Link } from "react-router-dom";
class Home extends React.Component {
    render() {
        return (React.createElement("section", { className: "sec-pd section-home", style: { padding: "10rem 0 0 0" } },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 col-md-7 col-lg-5" },
                        React.createElement("ul", { className: "home-content-slider", style: { padding: "0" } },
                            React.createElement("li", { className: "home-content-slider__item" },
                                React.createElement("div", { className: "home-baner-text" },
                                    React.createElement("h6", { className: "home-baner-text__titile fnt-24 text-5d5d fw-700" }, "AI Careers for Every Talents"),
                                    React.createElement("h1", { className: "home-baner-text__head fnt-40 text-1818 fw-700" }, "Build High Growth, High Pay Careers"),
                                    React.createElement("p", { className: "home-baner-text__info fnt-18 text-1818 fw-500 text-justify", style: { textAlign: "justify" } }, "Get ready on world\u2019s first ever Job Readiness Engine, designed with ultimate AI Workbench for Most In Demand Jobs and skills that top employers are Hiring in Industry 4.0 technologies such as Artificial Intelligence, Big Data, Cloud, Internet of Things and Data Security.")))),
                        React.createElement("div", { className: "home-slider-btn" },
                            React.createElement(Link, { to: "/student", className: "home-slider-btn__item btn btn--skewBrd fnt-16 c-color" },
                                "Students",
                                React.createElement("img", { className: "img-brd", src: "assets/images/home/brd2.png" })),
                            React.createElement(Link, { to: "/professional", className: "home-slider-btn__item btn btn--skewBrd fnt-16" },
                                "Professionals",
                                React.createElement("img", { className: "img-brd", src: "assets/images/home/brd2.png" })),
                            React.createElement(Link, { to: "/companies", className: "home-slider-btn__item btn btn--skewBrd" },
                                "Companies",
                                React.createElement("img", { className: "img-brd", src: "assets/images/home/brd2.png" })))),
                    React.createElement("div", { className: "col-12 col-md-5 col-lg-7" },
                        React.createElement("ul", { className: "home-image-banner pl-xl-5" },
                            React.createElement("li", { className: "home-image-banner__item", style: { textAlign: "center" } },
                                React.createElement("img", { className: "img-fluid ml-md-auto img-gif", src: "assets/images/home/Hand.gif" }))))))));
    }
}
export default Home;