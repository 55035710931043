import React, { useState } from "react";
import AzureAuthenticationContext from "./azure-authentication-context";
import { Link } from "react-router-dom";
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;
// Log In, Log Out button
const AzureAuthenticationButton = ({ onAuthenticated }) => {
    // Azure client context
    const authenticationModule = new AzureAuthenticationContext();
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState();
    const logIn = (method) => {
        const typeName = "loginPopup";
        const logInType = isIE ? "loginRedirect" : typeName;
        // Azure Login
        authenticationModule.login(logInType, returnedAccountInfo);
    };
    const logOut = () => {
        if (user) {
            onAuthenticated(undefined);
            // Azure Logout
            authenticationModule.logout(user);
        }
    };
    const returnedAccountInfo = (user) => {
        // set state
        setAuthenticated((user === null || user === void 0 ? void 0 : user.name) ? true : false);
        onAuthenticated(user);
        setUser(user);
    };
    const showLogInButton = () => {
        return (React.createElement("section", { className: "py-4 py-lg-5 pad" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center" },
                    React.createElement("div", { className: "col-12 col-md-8 col-lg-7 col-xl-8" },
                        React.createElement("div", { className: "login-section" },
                            React.createElement("div", { className: "login-form custom" },
                                React.createElement("ul", { className: "login-slider login-pad mb-0" },
                                    React.createElement("li", { className: "login-slider__item login-form__signup form-section col-12", id: "login" },
                                        React.createElement("div", { className: "log-head" },
                                            React.createElement("h1", { className: "fnt-32 fw-700 text-2424 mb-2" }, "Log in"),
                                            React.createElement("h5", { className: "fnt-16 fw-500 text-2a2a mb-0" }, "Login to continue")),
                                        React.createElement("div", { className: "logn-form" },
                                            React.createElement("div", { className: "validate-form mb-3 mb-md-4" },
                                                React.createElement("div", { className: "form-required" },
                                                    React.createElement("p", { className: "mt-2 text-9797 fnt-12 fw-400" }, "Please use your aitahub.com as login email id")),
                                                React.createElement("div", { className: "form-submit" },
                                                    React.createElement("button", { className: "btn btn--primary form-submit__btn js-main-login js-next", type: "button", id: "authenticationButton", onClick: () => logIn("loginPopup") }, "Login"))),
                                            React.createElement("div", { className: "signUp text-center" },
                                                React.createElement("p", { className: "mb-0 text-9797 fnt-12 fw-400" },
                                                    "New to Aitahub?\u00A0",
                                                    React.createElement(Link, { to: "/signup", className: "text-5877 signUp__link animate-line enable js-signUp" }, "Sign up")))))))))))));
    };
    const showLogOutButton = () => {
        return (React.createElement("div", { id: "authenticationButtonDiv" },
            React.createElement("div", { id: "authentication" },
                React.createElement("button", { id: "authenticationButton", onClick: () => logOut() }, "Log out"))));
    };
    const showButton = () => {
        return authenticated ? showLogOutButton() : showLogInButton();
    };
    return (React.createElement("div", { id: "authentication" }, authenticationModule.isAuthenticationConfigured ? (showButton()) : (React.createElement("div", null, "Authentication Client ID is not configured."))));
};
export default AzureAuthenticationButton;