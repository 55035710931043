import React from "react";
import { Link } from "react-router-dom";
import "./About.scss";
export default class About extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (React.createElement("main", { style: { paddingTop: "75px" } },
            React.createElement("section", { className: "sec-abt-banner" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "text-center m-auto custom-padding" },
                            React.createElement("div", { className: "px-lg-4" },
                                React.createElement("h3", { className: "text-1818 fnt-48 fw-700 mb-3" }, "Vision"),
                                React.createElement("h4", { className: "text-5d5d fnt-24 mb-3 fw-700" }, "One Hub for Diverse, AI Skilled Talents"),
                                React.createElement("h3", { className: "text-1818 fnt-32 fw-700 mb-3" }, "We Transform Talents and Technology for Building New India in the Digital Age"),
                                React.createElement("h3", { className: "text-1818 fnt-32 fw-700 mb-4" }, "Making Learning Affordable and AI Careers Accessible to all"))),
                        React.createElement("div", { className: "custom-padding text-center m-auto" },
                            React.createElement("p", { className: "text-1818 fnt-18" }, "Our endeavour is to help in solving India's biggest problem of Employability & of Digital Technology Skills Gap; Build AI Powered Careers for Every Aspiring Individuals across Pan India, combining Industry efforts and Technology.    "))))),
            React.createElement("section", { className: "sec-mission" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-lg-11 offset-lg-1" },
                            React.createElement("div", { className: "mission-c" },
                                React.createElement("h3", { className: "text-1818 fnt-48 fw-700 mb-5" }, "Our Mission"),
                                React.createElement("h6", { className: "text-1818 fnt-20" }, "AitaHub Mission is to Build a Successful High Growth, High Pay Careers for One Million Aspiring Individuals by 2030 focusing Talents from Lower Tier Cities, Towns & Rural India with Diverse Education Background"),
                                React.createElement("h6", { className: "text-1818 fnt-20 mb-0" }, "AitaHub is working towards achieving larger mission of Empowering Talents, Reducing Talent Hiring Supply Chain, Enabling Corporate Transition towards Hiring Skills and Making Talent-Technology Transformation a combined Industry-Community efforts")))))),
            React.createElement("section", { className: "sec-pd sec-pd--withBackground" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h3", { className: "fnt-32 fw-700 text-1818 mb-4" }, "Our Impact"),
                            React.createElement("ul", { className: "community-list mb-0 row", style: { padding: "0px" } },
                                React.createElement("li", { className: "community-list__item col-12 col-md-6" },
                                    React.createElement("div", { className: "community" },
                                        React.createElement("div", { className: "community-img p-2" },
                                            React.createElement("img", { className: "img-fluid", src: "assets/images/about/ab1.png" })),
                                        React.createElement("div", { className: "community-body pt-lg-2 px-lg-3 pb-lg-3" },
                                            React.createElement("div", { className: "community-body__head" },
                                                React.createElement("h3", { className: "fnt-18 text-1818" }, "Making Learning Affordable"))))),
                                React.createElement("li", { className: "community-list__item col-12 col-md-6" },
                                    React.createElement("div", { className: "community" },
                                        React.createElement("div", { className: "community-img p-2" },
                                            React.createElement("img", { className: "img-fluid", src: "assets/images/about/ab2.png" })),
                                        React.createElement("div", { className: "community-body pt-lg-2 px-lg-3 pb-lg-3" },
                                            React.createElement("div", { className: "community-body__head" },
                                                React.createElement("h3", { className: "fnt-18 text-1818" }, "Careers to all Aspiring Individuals")))))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h3", { className: "text-1818 fnt-32 fw-700 mb-2" }, "\u201CWe are committed to make AI Careers Accessible to All\u201D"),
                            React.createElement("h3", { className: "text-1818 fnt-32 fw-700 mb-5" }, "Founding Directors and Partners"))),
                    React.createElement("ul", { className: "mb-0 row brand-card-list", style: { padding: "0px" } },
                        React.createElement("li", { className: "brand-card-list__item col-lg-4 col-md-6" },
                            React.createElement(Link, { className: "round-card d-block", to: "#" },
                                React.createElement("div", { className: "img-c" },
                                    React.createElement("img", { src: "assets/images/about/a1.png", alt: "img" })),
                                React.createElement("h6", { className: "text-1216 fw-600 fnt-20 mb-1" }, "Shyamakrishna Kattepur"),
                                React.createElement("p", { className: "text-1818 fnt-18" }, "Group VP, Platform Engineering, ABB"))),
                        React.createElement("li", { className: "brand-card-list__item col-lg-4 col-md-6" },
                            React.createElement(Link, { className: "round-card d-block", to: "#" },
                                React.createElement("div", { className: "img-c" },
                                    React.createElement("img", { src: "assets/images/about/a2.png", alt: "img" })),
                                React.createElement("h6", { className: "text-1216 fw-600 fnt-20 mb-1" }, "Ishwar Hegde"),
                                React.createElement("p", { className: "text-1818 fnt-18" }, "CEO, AitaHub"))),
                        React.createElement("li", { className: "brand-card-list__item col-lg-4 col-md-6" },
                            React.createElement(Link, { className: "round-card d-block", to: "#" },
                                React.createElement("div", { className: "img-c" },
                                    React.createElement("img", { src: "assets/images/about/a3.png", alt: "img" })),
                                React.createElement("h6", { className: "text-1216 fw-600 fnt-20 mb-1" }, "Avin Jain"),
                                React.createElement("p", { className: "text-1818 fnt-18" }, "IITian, Founder & CEO, BDB"))),
                        React.createElement("li", { className: "brand-card-list__item col-lg-4 col-md-6" },
                            React.createElement(Link, { className: "round-card d-block", to: "#" },
                                React.createElement("div", { className: "img-c" },
                                    React.createElement("img", { src: "assets/images/about/a2.png", alt: "img" })),
                                React.createElement("h6", { className: "text-1216 fw-600 fnt-20 mb-1" }, "Seshadri S"),
                                React.createElement("p", { className: "text-1818 fnt-18" }, "Strategy & Business Development"))),
                        React.createElement("li", { className: "brand-card-list__item col-lg-4 col-md-6" },
                            React.createElement(Link, { className: "round-card d-block", to: "#" },
                                React.createElement("div", { className: "img-c" },
                                    React.createElement("img", { src: "assets/images/about/a2.png", alt: "img" })),
                                React.createElement("h6", { className: "text-1216 fw-600 fnt-20 mb-1" }, "CA Raghavendra"),
                                React.createElement("p", { className: "text-1818 fnt-18" }, "Finance"))),
                        React.createElement("li", { className: "brand-card-list__item col-lg-4 col-md-6" },
                            React.createElement(Link, { className: "round-card d-block", to: "#" },
                                React.createElement("div", { className: "img-c" },
                                    React.createElement("img", { src: "assets/images/about/a2.png", alt: "img" })),
                                React.createElement("h6", { className: "text-1216 fw-600 fnt-20 mb-1" }, "Dr. Sheetal Hegde"),
                                React.createElement("p", { className: "text-1818 fnt-18" }, "Talents, Careers, & Skills"))),
                        React.createElement("li", { className: "brand-card-list__item col-lg-4 col-md-6" },
                            React.createElement(Link, { className: "round-card d-block", to: "#" },
                                React.createElement("div", { className: "img-c" },
                                    React.createElement("img", { src: "assets/images/about/a2.png", alt: "img" })),
                                React.createElement("h6", { className: "text-1216 fw-600 fnt-20 mb-1" }, "Smitha V Harithasa"),
                                React.createElement("p", { className: "text-1818 fnt-18" }, "Founder CEO, Calibre Consulting Talent Acquisition"))),
                        React.createElement("li", { className: "brand-card-list__item col-lg-4 col-md-6" },
                            React.createElement(Link, { className: "round-card d-block", to: "#" },
                                React.createElement("div", { className: "img-c" },
                                    React.createElement("img", { src: "assets/images/about/mad-hat.png", alt: "img" })),
                                React.createElement("h6", { className: "text-1216 fw-600 fnt-20 mb-1" }, "Amit Bahirath"),
                                React.createElement("p", { className: "text-1818 fnt-18" }, "Founder, CEO, Mad Hat Communications Digital Marketing")))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: "custom-padding mx-auto community-contact-link w-100 text-center" },
                                React.createElement("h4", { className: "fnt-32 fw-700 text-1818" }, "Contribute today for a better future"),
                                React.createElement("p", { className: "fnt-24" }, "Connect with our Social Impact team to find out about Partnering Opportunities, Community Initiatives, Programs, and more."),
                                React.createElement("div", { className: "community-contact-link__btn mb-4 pb-md-3 pb-lg-0 mb-lg-5" },
                                    React.createElement(Link, { className: "btn btn--skew d-inline-block mt-4", to: "/contact-us" }, "Get in Touch")),
                                React.createElement("p", null, "CT Skills Galaxy Foundation is a registered 12 A non-profit in the India, and all funds given to us are tax deductible in the India  to the fullest extent allowed by law. Our Tax ID number is \u2026\u2026\u2026\u2026\u2026.."))))))));
    }
}
