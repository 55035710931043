import { Avatar, Box, Button, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import {Link} from "react-router-dom";
import ContentView from "./ContentView";
import SectionView from "./Sectionview";
import Service from "../../service/service";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {useEffect } from 'react';





  

const CoursePreview = () => {
    const history = useHistory();
    const location = useLocation();
    var userDetails  = sessionStorage.getItem("userDetails");
    console.log("details",userDetails);
    const [courseitem, setcourseitem] = React.useState('');
    console.log("courseitem",courseitem)
    const [coursecontent, setcoursecontent] = React.useState('');
    const [coursesection, setcoursesection] = React.useState('');
    useEffect(() => {
        if (location.state !== undefined) {
            const { courseid } = location.state
            console.log("data",courseid);
            new Service().getsinglecourse(courseid).then(
                (response) => {
                    setcourseitem(response.data);
                    
                }
              ).catch((res)=>{                      if((res+'').includes("401"))
                          history.push("/")
              });
            new Service().getcontentById(courseid).then(
                (response) => {
                    const contentFilterSortedData = response.data.filter(item => item.type !== 'assignment').sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
                    console.log("filter non asignmennt content",contentFilterSortedData)
                    setcoursecontent(contentFilterSortedData);

                }
              ).catch((res)=>{
                      if((res+'').includes("401"))
                          history.push("/")
              });
            new Service().getsectionforcourse(courseid).then(
                (response) => {
                    const sectionFilterSortedData = response.data.filter(item => item.type !== 'assignment').sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
                    console.log("filter non asignmennt section",sectionFilterSortedData)
                    setcoursesection(sectionFilterSortedData);
                    
                }
              ).catch((res)=>{
                      if((res+'').includes("401"))
                          history.push("/")
              });
        }
    }, [history,location.state]);
    

    console.log("courseid",courseitem.id)
    // const userId = '1f25b73b-c690-4d27-80b8-9a40d2965734'
    // const quizId = 'f8f798bb-bfda-4784-896e-2ce25b43fd19'
    const handleClickcodeboard = () => {
        
        window.open('https://code.aitahub.com/', '_blank');
      };
   



  

  

  return (
    <main>
    <AdminNavbar menu="mycourse" navbar="" />
    <div style={{marginLeft:'7rem'}}>
    <Box >
        <Box 
        sx = {{bgcolor:"#fee7eb",p:1}}
        >
            {/* course header pannel*/}
        <Grid container columns = {12} sx = {{mt:8,mb:3}} >
            <Grid item sm = {1} md = {1} lg = {1}></Grid>
            <Grid item sm = {7} md = {7} lg = {7} >{/* content grid */}

                <Typography variant = "h1" color='#000000' sx = {{fontSize:"3.70rem",fontWeight:"600",lineHeight:"1.25"}}>
                {courseitem.title}
                </Typography>
                <Typography variant = "subtitle1" color = '#00000080' sx = {{mt:1,fontSize:"1.2rem"}}>
                    {courseitem.description}</Typography>


            </Grid>
            <Grid item sm = {4} md = {4} lg = {4}></Grid>
            <Grid item sm = {1} md = {1} lg = {1}></Grid>

            <Grid item sm = {11} md = {11} lg = {11}>


            <Stack direction = {"row"} spacing = {2} sx = {{mt:2}}>
            <Link to={{pathname:"/coursetrailer" ,
             state: { 
                courseitem:courseitem ,
               } }}
            
            style={{ textDecoration: "none",color: "#f95877"  }}>

                    <Button variant = "contained" 
                    
                    sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}
                    
                    endIcon={<PlayCircleOutlineOutlinedIcon />}
                    
                    >
                        Watch Trailer

                    </Button>
                    </Link>
                    <Button variant = "contained" 
                    sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}
                    onClick={handleClickcodeboard}
                    
                     >PlayGround</Button>
                 
                 <Link to={{pathname:"/viewquiz" , state:{courseid:courseitem.id} }}
                style={{ textDecoration: "none",color: "#ffffff"  }}>

                    <Button variant = "contained" 
                    sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}
                    
                     >Quiz</Button>
                </Link>   
               
                 <Link to={{pathname:"/assignment" , state:{courseid:courseitem.id} }}
                style={{ textDecoration: "none",color: "#ffffff"  }}>

                    <Button variant = "contained" 
                    sx = {{bgcolor:"#f95877" ,color: 'white',':hover': {
                        bgcolor: '#f95877',
                        color: 'white', },}}
                    
                     >Assignment</Button>
                </Link>
                

                </Stack>
                </Grid>
            

        </Grid>
        </Box>

        
<Card elevation = {1}>
<Box sx = {{p:1}}>
<Grid container columns = {12} alignItems = "center" >
    <Grid item sm = {1} md ={1} lg = {1}></Grid>

        <Grid item sm = {2} md ={2} lg = {2} >
            <Grid container columns = {4} >

            <Grid item sm = {1} md ={1} lg = {1}>

                    <Stack direction = "row" spacing = {2}>
                    
                    <Avatar alt="Remy Sharp" src="https://randomuser.me/api/portraits/men/62.jpg" />
                    </Stack>

                    </Grid>
                    <Grid item sm = {3} md ={3} lg = {3}>
                    <Stack direction = "column" spacing = {0}>
                    
                    <Typography variant = "body1" sx = {{fontWeight:700}}>Elijah Murray</Typography>
                    <Typography variant = "caption">Instructor</Typography>

                    </Stack>

                </Grid>
        
            </Grid>
            
    </Grid>
        <Grid item sm = {1} md ={1} lg = {1}>
        <Stack direction = "row" spacing = {1} >

            <AccessTimeIcon/>
            <Typography>{courseitem.duration}</Typography>
            </Stack>
        </Grid>
        

    </Grid>
    </Box>
    </Card>


<Box sx = {{mt:0.3,p:1,pt:3}}>

<Grid container columns = {12} >
    <Grid item sm = {1} md = {1} lg = {1}></Grid>
    <Grid item sm = {10} md = {10} lg = {10}>

            <Grid container columns = {12} >
                    <Grid item sm = {12} md = {4} lg = {2.5} >
                    <Typography variant = "button" sx = {{fontWeight:600,letterSpacing:2}}>table of contents
                            </Typography>
                    </Grid>
                    <Grid item sm = {12} md = {8} lg = {9.5} sx = {{mt:1.5}}>
                    <Divider sx={{border:'1px solid grey'}}/>
                    </Grid>
            </Grid>

        <Grid container columns = {12} spacing = {4} sx = {{mb:1}}>

            <Grid item sm = {12} md = {7} lg = {7}>{/* accordian */}
    <Box sx = {{mt:3}}>


    
        {coursecontent && <ContentView coursecontent={coursecontent}/>}
        {coursesection && <SectionView coursesection={coursesection} courseitem = {courseitem} imageid={courseitem.thumbnail_asset_id}/>}
                </Box>
                </Grid>





            </Grid>
            


           
        


                </Grid>
                

</Grid>
    


    </Box>
    </Box>
    </div>
    
    </main>

  );
};



export default CoursePreview;