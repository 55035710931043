export default function SearchFilter(datas, searchString) {
    var searchResult = [];
    for (const data of datas) {
        for (const obj of Object.values(data)) {
            if ((obj + '').toLowerCase().includes(('' + searchString).toLowerCase())) {
                searchResult.push(data);
                break;
            }
        }
        ;
    }
    ;
    return searchResult;
}