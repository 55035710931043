import React from "react";
import "../../styles/Link.scss";
import { Link as Dom } from "react-scroll";
import classNames from "classnames";
import { Link } from "react-router-dom";
class Aitaengine extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            active: 'skill'
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    activate(value) {
        this.setState({ active: value });
    }
    render() {
        return (React.createElement("main", { style: { paddingTop: "145px" } },
            React.createElement("section", { className: "sec-pd section-home-product h-100vh" },
                React.createElement("div", { className: "product-spin product-spin--engine" },
                    React.createElement("div", { className: "product-img-part" },
                        React.createElement("div", { className: "product-img-part__down" },
                            React.createElement("img", { className: "img-fluid", src: "assets/images/engine/circle-bottom.png" })),
                        React.createElement("div", { className: "product-img-part__up" },
                            React.createElement("img", { className: "img-fluid", src: "assets/images/engine/Engine.png" })))),
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row content-visibility align-items-center" },
                        React.createElement("div", { className: "col-12 col-md-6" },
                            React.createElement("div", { className: "banner-content" },
                                React.createElement("h6", { className: "banner-content__title fw-700 text-5d5d" }, "Experiential, Self-Learning Place for Talents"),
                                React.createElement("h1", { className: "banner-content__head fw-700 text-1818" }, "Transform on World\u2019s First Job Readiness Engine")),
                            React.createElement("div", { className: "banner-info" },
                                React.createElement("h6", { className: "banner-info__title text-1615 fw-600" }, "Machine and Deep Learning Enabled"),
                                React.createElement("p", { className: "banner-info__disc fnt-18 text-1818 fw-400 text-justify" }, "Aitahub Introducing this Engine with ultimate AI Workbench to ready Students and Working Professionals in Most In Demand Jobs and skills for Industry 4.0 technologies such as Artificial Intelligence \u2013 Data Science, Big Data, Cloud, Internet of Things and Data Security.")),
                            React.createElement("div", { className: "banner-btn" },
                                React.createElement(Link, { className: "d-inline-block home-slider-btn__item btn btn--skew", to: "/login" }, "Request Demo"),
                                React.createElement(Link, { className: "d-inline-block home-slider-btn__item btn btn--skewBrd", to: "/contact-us" },
                                    "Contact Us",
                                    React.createElement("img", { className: "img-brd", src: "assets/images/engine/brd2.png" })))),
                        React.createElement("div", { className: "col-12 col-md-6" },
                            React.createElement("div", { className: "product-spin product-spin--mobile" },
                                React.createElement("div", { className: "product-img-part" },
                                    React.createElement("div", { className: "product-img-part__down" },
                                        React.createElement("img", { className: "img-fluid", src: "assets/images/engine/circle-bottom.png" })),
                                    React.createElement("div", { className: "product-img-part__up" },
                                        React.createElement("img", { className: "img-fluid", src: "assets/images/engine/Engine.png" })))))))),
            React.createElement("section", { className: "sec-pd" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row js-custom-tabs" },
                        React.createElement("div", { className: "col-12 col-md-4 col-lg-3" },
                            React.createElement("ul", { className: "sub-tab-list sticky-element", style: { padding: "0px" } },
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "skill" }) },
                                    React.createElement(Dom, { to: "skill", offset: -100, onClick: () => this.activate("skill") }, "Skills and Specialist Programs")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "data-science" }) },
                                    React.createElement(Dom, { to: "data-science", offset: -100, onClick: () => this.activate("data-science") }, "Data Science Engine")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "features" }) },
                                    React.createElement(Dom, { to: "features", offset: -100, onClick: () => this.activate("features") }, "Features and Benefits")),
                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "tools" }) },
                                    React.createElement(Dom, { to: "tools", offset: -100, onClick: () => this.activate("tools") }, "Module & Tools")))),
                        React.createElement("div", { className: "col-12 col-md-9 col-lg-9" },
                            React.createElement("ul", { className: "sub-tab-content-list tab-pad-left" },
                                React.createElement("li", { className: "sub-tab-content-list__item active" },
                                    React.createElement("div", { className: "random-divs" },
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "skill", className: "Blueline-border border-0 bg-6f8f radius-0" },
                                                React.createElement("div", { className: "col-12 col-md-11 px-0" },
                                                    React.createElement("h5", { className: "fnt-24 text-5d5d fw-700 mb-2" }, "Learn Anytime, Anywhere, Any level on Engine"),
                                                    React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3" }, "Top AI Based Skills and Careers Designed for You"),
                                                    React.createElement("h6", { className: "fnt-20 text-1615 fw-600 mb-4 mb-md-5" }, "Talents Ready for Jobs from Day One"),
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Specialists Careers"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-3 mb-md-4 mb-lg-5", style: { padding: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Deep Learning")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc3.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "NLP")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc4.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Neural Networks")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Computer Vision")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/engine/sc6.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "High End Analytics"))))),
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Tech Jobs"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-3 mb-md-4 mb-lg-5", style: { padding: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analyst")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Engineer")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p3.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning Engineer")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Data Scientist")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/cds.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Citizen Data Scientist"))))),
                                                    React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Business Jobs"),
                                                    React.createElement("ul", { className: "careers-list d-flex flex-wrap clearfix mb-0", style: { padding: "0px" } },
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr1.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Business Analyst")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr2.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Management Consultant")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/ca.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Chartered Accountant")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr4.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Investment Banker")))),
                                                        React.createElement("li", { className: "careers-list__item float-left" },
                                                            React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                                                React.createElement("div", { className: "careers-icon float-left" },
                                                                    React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr5.svg" })),
                                                                React.createElement("div", { className: "careers-title float-left" },
                                                                    React.createElement("h4", { className: "mb-0 fw-500" }, "Marketing Manager")))))))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "data-science", className: "Blueline-border" },
                                                React.createElement("div", { className: "work-bench" },
                                                    React.createElement("h6", { className: "fnt-24 text-1818 fw-700  px-0" }, "Ultimate AI Workbench for Experiential Learning"),
                                                    React.createElement("ul", { className: "work-bench-step d-flex flex-wrap", style: { padding: "0px" } },
                                                        React.createElement("li", { className: "work-bench-step__item odd-list" },
                                                            React.createElement("div", { className: "work-step" },
                                                                React.createElement("span", { className: "text-1818 fw-600" }, "Tool Kit"))),
                                                        React.createElement("li", { className: "work-bench-step__item odd-list" },
                                                            React.createElement("div", { className: "work-step" },
                                                                React.createElement("span", { className: "text-1818 fw-600" }, "Business Problem"))),
                                                        React.createElement("li", { className: "work-bench-step__item odd-list" },
                                                            React.createElement("div", { className: "work-step" },
                                                                React.createElement("span", { className: "text-1818 fw-600" }, "Data Acquisition"))),
                                                        React.createElement("li", { className: "work-bench-step__item even-list" },
                                                            React.createElement("div", { className: "work-step" },
                                                                React.createElement("span", { className: "text-1818 fw-600" }, "Feature Engineering"))),
                                                        React.createElement("li", { className: "work-bench-step__item even-list" },
                                                            React.createElement("div", { className: "work-step" },
                                                                React.createElement("span", { className: "text-1818 fw-600" }, "Transform Data"))),
                                                        React.createElement("li", { className: "work-bench-step__item even-list" },
                                                            React.createElement("div", { className: "work-step" },
                                                                React.createElement("span", { className: "text-1818 fw-600" }, "Data Architecture"))),
                                                        React.createElement("li", { className: "work-bench-step__item odd-list" },
                                                            React.createElement("div", { className: "work-step" },
                                                                React.createElement("span", { className: "text-1818 fw-600" }, "Machine Learning Models"))),
                                                        React.createElement("li", { className: "work-bench-step__item odd-list" },
                                                            React.createElement("div", { className: "work-step" },
                                                                React.createElement("span", { className: "text-1818 fw-600" }, "Train, Test, Deploy Models"))),
                                                        React.createElement("li", { className: "work-bench-step__item odd-list" },
                                                            React.createElement("div", { className: "work-step" },
                                                                React.createElement("span", { className: "text-1818 fw-600" }, "Advanced Use Cases"))))))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "features", className: "Blueline-border" },
                                                React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-4 pb-xl-2" }, "Engine to Boost Start to Finish Skills"),
                                                React.createElement("div", { className: "points-color" },
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Learn by Doing on Top Employers AI Workbench"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Built in Job Based Modules, Skill Based Content"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Designed from AI and Data Science Veterans"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Perform Job Tasks in Job Environment"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Build Your Own Professional Project Portfolio"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Solve 100+ Use Cases -Technical & Business"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Experience 100+ Tools, Techniques with ML Process"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Built in Business, Project & Soft Skills"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Skills Dashboard, Project Repository"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "ML Enabled Hiring Tool: Performance Analytics"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "AitaDtQ-Certification Metrics"),
                                                    React.createElement("p", { className: "points-color__item text-1818 fnt-24 fw-500" }, "Learn Anytime, Anywhere, Anyone, Any Level")))),
                                        React.createElement("div", { className: "random-divs__item" },
                                            React.createElement("div", { id: "tools", className: "Blueline-border" },
                                                React.createElement("div", { className: "skil-content" },
                                                    React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3" }, "100 + Tools, Platform, AI + Data Science Use Cases"),
                                                    React.createElement("p", { className: "fw-500" }, "Specialist Data Science Modules for Each Business")),
                                                React.createElement("ul", { className: "skill-icons-list d-flex flex-wrap clearfix" },
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill1.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill2.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill3.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill4.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill5.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill6.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill7.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill8.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill9.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill10.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill11.png" }))),
                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill12.png" })))),
                                                React.createElement("div", { className: "mt-md-2 mt-lg-4" },
                                                    React.createElement(Link, { className: "d-inline-block home-slider-btn__item btn btn--skew mt-5", to: "/login" }, "Request Demo")))))),
                                React.createElement("li", { className: "sub-tab-content-list__item" },
                                    React.createElement("p", null, "Data Science Engine")),
                                React.createElement("li", { className: "sub-tab-content-list__item" },
                                    React.createElement("p", null, "Features and Benefits")),
                                React.createElement("li", { className: "sub-tab-content-list__item" },
                                    React.createElement("p", null, "Module & Tools")))))))));
    }
}
export default Aitaengine;