import React from "react";
import "../../styles/Link.scss";
import { Link as Dom } from "react-scroll";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Slider from "react-slick";
class UpcomingPrograms extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            active: "upcomingPrograms",
            activeTab: "midsp",
            slickdisable: true
        };
        this.settings = {
            slidesToShow: 2,
            infinite: false,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
    }
    activetab(value) {
        this.setState({ activeTab: value });
    }
    activate(value) {
        this.setState({ active: value });
    }
    render() {
        return (React.createElement("section", { className: "sec-pd" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("ul", { className: "nav nav-pills mb-0 career-tabs__head responsive-list-tabs slick-initialized slick-slider", role: "tablist" },
                            React.createElement("div", { className: "slick-list draggable" },
                                React.createElement("div", { className: "slick-track", style: { opacity: "1", width: "10000px", transform: "translate3d(0px, 0px, 0px)" } },
                                    React.createElement("div", { className: classNames("slick-slide slick-active", { "slick-current": this.state.activeTab === "midsp" }), "data-slick-index": "0", "aria-hidden": "false" },
                                        React.createElement("div", null,
                                            React.createElement("li", { className: "nav-item px-0", style: { width: "100%", display: "inline-block" } },
                                                React.createElement("div", { style: { cursor: "pointer" }, className: "nav-link active", id: "program1-tab", "data-toggle": "pill", role: "tab", "aria-controls": "program1", "aria-selected": "true", tabIndex: 0, onClick: () => this.activetab("midsp") }, "MIDSP in Data Science")))),
                                    React.createElement("div", { className: classNames("slick-slide slick-active", { "slick-current": this.state.activeTab === "ai" }), "data-slick-index": "1", "aria-hidden": "false" },
                                        React.createElement("div", null,
                                            React.createElement("li", { className: "nav-item px-0", style: { width: "100%", display: "inline-block" } },
                                                React.createElement("div", { className: "nav-link active show", id: "program2-tab", "data-toggle": "pill", role: "tab", "aria-controls": "program2", "aria-selected": "true", style: { color: "#000000", cursor: "pointer" }, tabIndex: 0, onClick: () => this.activetab("ai") }, "AI Jobs Readiness Program"))))))),
                        React.createElement("div", { className: "tab-content responsive-tab-content tab-content--student" },
                            this.state.activeTab === "midsp" &&
                                React.createElement("div", { className: "tab-pane fade responsive-tab-content__list show active", id: "program1", role: "tabpanel", "aria-labelledby": "program1-tab" },
                                    React.createElement("div", { className: "row js-custom-tabs" },
                                        React.createElement("div", { className: "col-12 col-md-4 col-lg-3" },
                                            React.createElement("ul", { className: "sub-tab-list sticky-element", style: { padding: "0" } },
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "upcomingPrograms" }) },
                                                    React.createElement(Dom, { offset: -100, to: "upcomingPrograms", onClick: () => this.activate("upcomingPrograms") }, "Upcoming Program")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "hire" }) },
                                                    React.createElement(Dom, { offset: -100, to: "hire", onClick: () => this.activate("hire") }, "Hiring Partners")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "student" }) },
                                                    React.createElement(Dom, { offset: -100, to: "student", onClick: () => this.activate("student") }, "Student Experience")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "module" }) },
                                                    React.createElement(Dom, { offset: -100, to: "module", onClick: () => this.activate("module") }, "Module & Tools")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "instructors" }) },
                                                    React.createElement(Dom, { offset: -100, to: "instructors", onClick: () => this.activate("instructors") }, "Instructors")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "ai-lab" }) },
                                                    React.createElement(Dom, { offset: -100, to: "ai-lab", onClick: () => this.activate("ai-lab") }, "AI Skills Lab")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "ml" }) },
                                                    React.createElement(Dom, { offset: -100, to: "ml", onClick: () => this.activate("ml") }, "ML Engine")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "live-projects" }) },
                                                    React.createElement(Dom, { offset: -100, to: "live-projects", onClick: () => this.activate("live-projects") }, "Live Projects")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "aitahack" }) },
                                                    React.createElement(Dom, { offset: -100, to: "aitahack", onClick: () => this.activate("aitahack") }, "Aita Hack")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "certification" }) },
                                                    React.createElement(Dom, { offset: -100, to: "certification", onClick: () => this.activate("certification") }, "Certification")),
                                                React.createElement("li", { className: classNames("sub-tab-list__item", { "active": this.state.active === "placement" }) },
                                                    React.createElement(Dom, { offset: -100, to: "placement", onClick: () => this.activate("placement") }, "Placement & Career Support")))),
                                        React.createElement("div", { className: "col-12 col-md-8 col-lg-9" },
                                            React.createElement("ul", { className: "sub-tab-content-list tab-pad-left", style: { paddingLeft: "0px" } },
                                                React.createElement("li", { className: "sub-tab-content-list__item active" },
                                                    React.createElement("div", { className: "random-divs" },
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "upcomingPrograms" },
                                                                React.createElement(Slider, { centerMode: true, infinite: false },
                                                                    React.createElement("div", null,
                                                                        React.createElement("ul", null,
                                                                            React.createElement("li", { className: "col-12 col-md-11 programs-list__item", style: { width: "100%", display: "inline-block" } },
                                                                                React.createElement("ul", { className: "programs-card-list mb-0" },
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#" },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr1.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Program1"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "MIDSP in Data Science")))),
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#" },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr2.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Date"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "Start Date: Jan 15, 2021")))),
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#" },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr3.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Tech Jobs"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "Data Analyst, Data Engineer, Data Scientist ML Engineer")))),
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#" },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr5.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Duration"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "40 Weeks, 500 Hours")))),
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#" },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr4.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Tools, Tasks & Projects"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "30+Tools, 50+ Use Cases"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "09 Live Projects + Capstone"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "30 + Assignments"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "10+ Hackathon")))))))),
                                                                    React.createElement("div", null,
                                                                        React.createElement("ul", null,
                                                                            React.createElement("li", { className: "col-12 col-md-11 programs-list__item", style: { width: "100%", display: "inline-block" } },
                                                                                React.createElement("ul", { className: "programs-card-list mb-0", style: { padding: "0" } },
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#", tabIndex: -1 },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr1.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Program2"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "MIDSP in Data Science")))),
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#", tabIndex: -1 },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr2.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Date"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "Start Date: Jan 15, 2021")))),
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#", tabIndex: -1 },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr3.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Tech Jobs"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "Data Analyst, Data Engineer, Data Scientist ML Engineer")))),
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#", tabIndex: -1 },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr5.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Duration"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "40 Weeks, 500 Hours")))),
                                                                                    React.createElement("li", { className: "programs-card-list__item" },
                                                                                        React.createElement(Link, { className: "program-link", to: "#", tabIndex: -1 },
                                                                                            React.createElement("div", { className: "program-link__icon" },
                                                                                                React.createElement("div", { className: "img" },
                                                                                                    React.createElement("img", { className: "img-contain", src: "assets/images/student/pr4.svg" }))),
                                                                                            React.createElement("div", { className: "program-link__info" },
                                                                                                React.createElement("h6", { className: "fnt-20 fw-600 text-1615 mb-1 mb-lg-2" }, "Tools, Tasks & Projects"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "30+Tools, 50+ Use Cases"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "09 Live Projects + Capstone"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "30 + Assignments"),
                                                                                                React.createElement("p", { className: "fnt-18 text-1818 fw-400 mb-0" }, "10+ Hackathon"))))))))))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "hire", className: "Blueline-border p-0" },
                                                                React.createElement("div", { className: "pad-div" },
                                                                    React.createElement("h4", { className: "fnt-32 text-1615 fw-600" }, "Hired by Top Employers for High Pay, Global Jobs"),
                                                                    React.createElement("p", null, "Programs are Made by Made for Top Employers")),
                                                                React.createElement("div", { className: "img-div" },
                                                                    React.createElement("img", { src: "assets/images/student/st1.png" })))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "student", className: "Blueline-border" },
                                                                React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-4" }, "Aithub Careers Advantage"),
                                                                React.createElement("ul", { className: "mb-0 row regular-list", style: { padding: "0" } },
                                                                    React.createElement("li", { className: "col-12 col-md-6 regular-list__item" },
                                                                        React.createElement(Link, { className: "d-block", to: "#" },
                                                                            React.createElement("div", { className: "image" },
                                                                                React.createElement("img", { className: "img", src: "assets/images/student/s1.png" })),
                                                                            React.createElement("p", { className: "mb-0" }, "Learning in the Job Environment"))),
                                                                    React.createElement("li", { className: "col-12 col-md-6 regular-list__item" },
                                                                        React.createElement(Link, { className: "d-block", to: "#" },
                                                                            React.createElement("div", { className: "image" },
                                                                                React.createElement("img", { className: "img", src: "assets/images/student/s1.png" })),
                                                                            React.createElement("p", { className: "mb-0" }, "Best of Campus and Online")))))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "module", className: "Blueline-border" },
                                                                React.createElement("div", { className: "skil-content" },
                                                                    React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3" }, "Job Based Modules, Skill Based Content"),
                                                                    React.createElement("p", { className: "fw-500" }, "Learn & Experience in Most in Demand Tools"),
                                                                    React.createElement("div", { className: "point-list" },
                                                                        React.createElement("p", { className: "point-list__item" }, "30+ Modules, Sub Modules"),
                                                                        React.createElement("p", { className: "point-list__item" }, "50+ Data Science Topics"),
                                                                        React.createElement("p", { className: "point-list__item" }, "50+ Use Cases"),
                                                                        React.createElement("p", { className: "point-list__item" }, "30+ Tools"))),
                                                                React.createElement("ul", { className: "skill-icons-list mb-0 d-flex flex-wrap clearfix" },
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill1.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill2.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill3.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill4.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill5.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill6.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill7.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill8.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill9.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill10.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill11.png" }))),
                                                                    React.createElement("li", { className: "skill-icons-list__item float-left" },
                                                                        React.createElement(Link, { className: "icon-link", to: "#" },
                                                                            React.createElement("img", { className: "icon-link__img", src: "assets/images/student/skill12.png" })))))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "instructors", className: "p-1 bg-6f8f radius-12" },
                                                                React.createElement("div", { className: "row" },
                                                                    React.createElement("div", { className: "col-12 col-lg-3" },
                                                                        React.createElement("div", { className: "img-responsive d-flex align-items-center" },
                                                                            React.createElement("img", { className: "radius-12", src: "assets/images/student/industry.png" }))),
                                                                    React.createElement("div", { className: "col-12 col-lg-9 py-4" },
                                                                        React.createElement("div", { className: "px-3 px-lg-0" },
                                                                            React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2" }, "Expert Instructors, Mentors from the Industry"),
                                                                            React.createElement("p", { className: "mb-0" }, "Sessions are Done by Practitioners only")))))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "ai-lab", className: "p-1 bg-6f8f radius-12" },
                                                                React.createElement("div", { className: "row" },
                                                                    React.createElement("div", { className: "col-12 col-lg-3" },
                                                                        React.createElement("div", { className: "img-responsive d-flex align-items-center" },
                                                                            React.createElement("img", { className: "radius-12", src: "assets/images/student/campus.png" }))),
                                                                    React.createElement("div", { className: "col-12 col-lg-9 py-4" },
                                                                        React.createElement("div", { className: "px-3 px-lg-0" },
                                                                            React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2" }, "Best of Campus and Online Experience"),
                                                                            React.createElement("p", { className: "mb-0" }, "Learn and get trained in connected AI Digitech Skills Lab")))))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "ml", className: "Blueline-border" },
                                                                React.createElement("div", { className: "row" },
                                                                    React.createElement("div", { className: "col-12 col-lg-6" },
                                                                        React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3 mb-lg-4" }, "Perform Job Tasks on World\u2019s First Job Readiness Engine"),
                                                                        React.createElement("div", { className: "point-list" },
                                                                            React.createElement("p", { className: "point-list__item" }, "Built in with 100+ Tools, Data Science Use Cases"),
                                                                            React.createElement("p", { className: "point-list__item" }, "Ultimate AI workbench "),
                                                                            React.createElement("p", { className: "point-list__item" }, "Machine Learning Platform for Job Readiness"))),
                                                                    React.createElement("div", { className: "col-12 col-lg-6" },
                                                                        React.createElement("img", { className: "img-fluid", src: "assets/images/student/mind.png" }))))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "live-projects", className: "Blueline-border" },
                                                                React.createElement("div", null,
                                                                    React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3 mb-lg-4" }, "Best of Campus and Online Experience"),
                                                                    React.createElement("div", { className: "point-list" },
                                                                        React.createElement("p", { className: "point-list__item" }, "Joint Projects with Data Science Veterans"),
                                                                        React.createElement("p", { className: "point-list__item" }, "Multiple Module Specific Hands on Projects"),
                                                                        React.createElement("p", { className: "point-list__item" }, "Professional Capstone Project"))),
                                                                React.createElement("div", null,
                                                                    React.createElement("h4", { className: "fnt-24 text-1818 fw-600 mb-2 mb-md-3 mb-lg-4" }, "Best Data Science Use Cases"),
                                                                    React.createElement("ul", { className: "social-case-list d-flex flex-wrap clearfix" },
                                                                        React.createElement("li", { className: "social-case-list__item flaot-left brd-clr brd-clr--blue" },
                                                                            React.createElement(Link, { className: "d-block", to: "#" },
                                                                                React.createElement("div", { className: "science-icon" },
                                                                                    React.createElement("div", { className: "science-icon__img" },
                                                                                        React.createElement("img", { src: "assets/images/student/twt.png" }))),
                                                                                React.createElement("div", { className: "science-info bg-blueGrad text-center" },
                                                                                    React.createElement("p", { className: "fnt-16 text-white fw-600 mb-0" }, "Fake Account Detection Case")))),
                                                                        React.createElement("li", { className: "social-case-list__item flaot-left brd-clr brd-clr--yellow" },
                                                                            React.createElement(Link, { className: "d-block", to: "#" },
                                                                                React.createElement("div", { className: "science-icon" },
                                                                                    React.createElement("div", { className: "science-icon__img" },
                                                                                        React.createElement("img", { src: "assets/images/student/amazon.png" }))),
                                                                                React.createElement("div", { className: "science-info bg-yellowGrad text-center" },
                                                                                    React.createElement("p", { className: "fnt-16 text-white fw-600 mb-0" }, "Amazon\u2019s Recommendation Engine - Predictive Analytics Case")))),
                                                                        React.createElement("li", { className: "social-case-list__item flaot-left brd-clr brd-clr--red" },
                                                                            React.createElement(Link, { className: "d-block", to: "#" },
                                                                                React.createElement("div", { className: "science-icon" },
                                                                                    React.createElement("div", { className: "science-icon__img" },
                                                                                        React.createElement("img", { src: "assets/images/student/airbnb.png" }))),
                                                                                React.createElement("div", { className: "science-info bg-redGred text-center" },
                                                                                    React.createElement("p", { className: "fnt-16 text-white fw-600 mb-0" }, "Airbnb Improving Search Case")))),
                                                                        React.createElement("li", { className: "social-case-list__item flaot-left brd-clr brd-clr--green" },
                                                                            React.createElement(Link, { className: "d-block", to: "#" },
                                                                                React.createElement("div", { className: "science-icon" },
                                                                                    React.createElement("div", { className: "science-icon__img" },
                                                                                        React.createElement("img", { src: "assets/images/student/gAids.png" }))),
                                                                                React.createElement("div", { className: "science-info bg-greenGrad text-center" },
                                                                                    React.createElement("p", { className: "fnt-16 text-white fw-600 mb-0" }, "Targeted Advertising Case")))),
                                                                        React.createElement("li", { className: "social-case-list__item flaot-left brd-clr brd-clr--darkBlue" },
                                                                            React.createElement(Link, { className: "d-block", to: "#" },
                                                                                React.createElement("div", { className: "science-icon" },
                                                                                    React.createElement("div", { className: "science-icon__img" },
                                                                                        React.createElement("img", { src: "assets/images/student/fb.png" }))),
                                                                                React.createElement("div", { className: "science-info bg-darkBlueGrad text-center" },
                                                                                    React.createElement("p", { className: "fnt-16 text-white fw-600 mb-0" }, "Facebook\u2019s Advanced Image Recognition Case")))))))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "aitahack", className: "Blueline-border p-0" },
                                                                React.createElement("img", { className: "radius-12 img-fluid", src: "assets/images/student/aitahack.png" }),
                                                                React.createElement("div", { className: "pad-div" },
                                                                    React.createElement("h4", { className: "fnt-32 text-1615 fw-600" }, "Collaborate Learning on Aita Hack Platform"),
                                                                    React.createElement("div", { className: "point-list" },
                                                                        React.createElement("p", { className: "point-list__item" }, "Participate in Hackathons,"),
                                                                        React.createElement("p", { className: "point-list__item" }, "Compete with Global Talents "),
                                                                        React.createElement("p", { className: "point-list__item" }, "Model, Code, Predict and Solve Data to Value Problems")),
                                                                    React.createElement(Link, { className: "d-inline-block btn btn--skew", to: "/aitahack" }, "Join Aitahack 1.0")))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "certification", className: "p-1 bg-6f8f radius-12" },
                                                                React.createElement("div", { className: "row" },
                                                                    React.createElement("div", { className: "col-12 col-lg-3" },
                                                                        React.createElement("div", { className: "img-responsive d-flex align-items-center" },
                                                                            React.createElement("img", { className: "radius-12", src: "assets/images/student/certificate.png" }))),
                                                                    React.createElement("div", { className: "col-12 col-lg-8 pl-md-0 py-4" },
                                                                        React.createElement("div", { className: "px-3 px-lg-0" },
                                                                            React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2" }, "Industry Recognized Certificates"),
                                                                            React.createElement("p", { className: "mb-0" }, "Opportunities to Earn Multiple Credentials Module Specific, Tool, Platform Specific Certificates")))))),
                                                        React.createElement("div", { className: "random-divs__item" },
                                                            React.createElement("div", { id: "placement", className: "Blueline-border" },
                                                                React.createElement("h4", { className: "fnt-32 text-1615 fw-600 mb-2 mb-md-3 mb-lg-4 col-11 px-0" }, "Our Mission: Placement for All"),
                                                                React.createElement("ul", { className: "slider-card2 custom-arrows pt-5 pt-md-0 slick-initialized slick-slider" },
                                                                    React.createElement(Slider, Object.assign({}, this.settings),
                                                                        React.createElement("div", { style: { width: "287px" } },
                                                                            React.createElement("ul", null,
                                                                                React.createElement("li", { className: "slider-card2__item col-12" },
                                                                                    React.createElement("img", { className: "img-fluid mb-2", src: "assets/images/student/csl1.png" }),
                                                                                    React.createElement("p", { className: "fw-500 mb-0 mt-1" }, "ML Led E2E Performance Analytics Hiring Tool")))),
                                                                        React.createElement("div", { style: { width: "287px" } },
                                                                            React.createElement("ul", null,
                                                                                React.createElement("li", { className: "slider-card2__item col-12" },
                                                                                    React.createElement("img", { className: "img-fluid mb-2", src: "assets/images/student/csl2.png" }),
                                                                                    React.createElement("p", { className: "fw-500 mb-0 mt-1" }, "ML Led E2E Performance Analytics Hiring Tool")))),
                                                                        React.createElement("div", { style: { width: "287px" } },
                                                                            React.createElement("ul", null,
                                                                                React.createElement("li", { className: "slider-card2__item col-12" },
                                                                                    React.createElement("img", { className: "img-fluid mb-2", src: "assets/images/student/csl1.png" }),
                                                                                    React.createElement("p", { className: "fw-500 mb-0 mt-1" }, "ML Led E2E Performance Analytics Hiring Tool")))),
                                                                        React.createElement("div", { style: { width: "287px" } },
                                                                            React.createElement("ul", null,
                                                                                React.createElement("li", { className: "slider-card2__item col-12" },
                                                                                    React.createElement("img", { className: "img-fluid mb-2", src: "assets/images/student/csl2.png" }),
                                                                                    React.createElement("p", { className: "fw-500 mb-0 mt-1" }, "ML Led E2E Performance Analytics Hiring Tool")))))))))))))),
                            this.state.activeTab === "ai" &&
                                React.createElement("div", null,
                                    React.createElement("p", null, "2"))))))));
    }
}
export default UpcomingPrograms;