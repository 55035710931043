import React from "react";
import { Grid } from "@mui/material";
import Config from "../../components/Global";
import ViewHeader from "./ViewHeader";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { AITADB_URL } from "../constants/global";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";

export default function WordViewer() {
  const location = useLocation();
  const [assetId,setassetid] = React.useState('');
  const [pdffile,setpdffile] = React.useState(null);
  const { coursename } = location.state
  const {imageid} = location.state
  console.log(assetId);
  useEffect(() => {
    if (location.state !== undefined) {
      const { assetid } = location.state
      setassetid(assetid)
      const request = new Request(AITADB_URL+"/sections/asset/"+assetid,
      {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${Config.getToken()}`,
          "Content-Type": "application/pdf"
        },
      }
    );
      fetch(request)
            .then((response) => response.blob())
            .then((blob) => {              
              const file = window.URL.createObjectURL(blob);
              setpdffile(file)
              // const iframe = document.querySelector("iframe");
              // document.querySelector("iframe").src = file;
              // if (iframe?.src) iframe.src = file;
            })
        
    // async function fetchPdfData() {
    //   // Make a request to your server to fetch the PDF data from MySQL
    //   const response = await fetch(`${AITADB_URL}/sections/asset/${assetid}`, {headers :{
    //           'Authorization': `Bearer ${Config.getToken()}`
    //         }});
    //   const data = await response.arrayBuffer();
    //   setpdffile(data);
    // }

    // fetchPdfData();
  }
  }, [location.state]);
  

  
        
          // .catch((err: AxiosError) => {
          //   // process error
          // });
  
  
    
  

    return (
      <main>
      <AdminNavbar menu="mycourse" navbar="" />
      <div >
        <ViewHeader coursename = {coursename} imageid={imageid}/>
        <Grid container columns={12} spacing={1} sx = {{mt:5,mb:5}}>
        <Grid item md={1} lg={1}></Grid>
        <Grid item md={10} lg={10}>
          {pdffile  &&
          <div>
          
          <iframe
            title="file"
            type="application/document"
            width="100%"
            height="600px"
            style={{"overflow": "auto"}}
            src={`data:application/document;base64,${pdffile
            }`}
            
            >
         </iframe>
         </div>}

        </Grid>
        </Grid>
        
        
      </div>
      </main>
    );
  }