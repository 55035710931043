import React from "react";
import ReactDOM from "react-dom";
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import AdminHeader from "./components/Dashboard/AdminHeader";
import TextViewer from "./JScomponents/ViewCourse/TextViewer";
import PdfViewer from "./JScomponents/ViewCourse/PdfViewer";
import VideoPlayer from "./JScomponents/ViewCourse/VideoPlayer";
import WordViewer from "./JScomponents/ViewCourse/WordViewer";
import AudioPlayer from "./JScomponents/ViewCourse/AudioPlayer";
import Login from "./pages/Login/Login";
import signup from "./pages/Login/signup";
import Students from "./pages/Students/Students";
import HomePage from "./pages/Home/HomePage";
import Dashboard from "./pages/Dashboard/Dashboard";
import store from './store';
// import Courses from "./pages/Courses";
import Courses from "./JScomponents/Courses/CourseTiles";
import Professionals from "./pages/Professionals/Professionals";
import Companies from "./pages/Companies/Companies";
import Aitahack from "./pages/Aitahack/Aitahack";
import Aitaengine from "./pages/Aitaengine/Aitaengine";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import Partner from "./pages/Partners/Partner";
import Community from "./pages/Community/Community";
import ViewCourse from "./JScomponents/ViewCourse";
// import ViewCourse1 from "./JScomponents/ViewCourse1";
import Quiz from "./JScomponents/Quiz/index";
import Programs from "./components/programs/programs";
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Scheduler from "./JScomponents/Partcipants/Scheduler";
import { ThemeProvider } from '@mui/material/styles';
import theme from './newtheme';
import Myprofile from "./JScomponents/MyProfile/Myprofile";
// import UserPrograms from "./JScomponents/Programs/ProgramTiles";
import Payment from "./components/Payment/Payment";
import UserPrograms from "./pages/Programs/UserPrograms";
import Trailer from "./JScomponents/ViewCourse/Trailer";
import Assignment from "./JScomponents/ViewCourse/Assignment";
import Mail from "./JScomponents/Mail";
import Viewquiz from "./JScomponents/ViewCourse/Viewquiz";

const routing = (React.createElement(StyledEngineProvider, { injectFirst: true },
    React.createElement(ThemeProvider, { theme: theme },
        React.createElement(CssBaseline, null),
        React.createElement(Provider, { store: store },
            React.createElement(Router, null,
                React.createElement(Route, { exact: true, path: ["/dashboard", "/courses", "/userprograms", "/coursepreview", "/coursepreview/1", "/events", "/quiz", "/pdfviewer", "/audioplayer", "/textviewer", "/wordviewer", "/videoplayer", "/myprofile", "/coursetrailer", "/assignment","/mail","/viewquiz"], component: AdminHeader }),
                React.createElement(Route, { exact: true, path: ["/", "/student", "/professional", "/companies", "/aitahack", "/engine", "/contact-us", "/about", "/partners", "/community", "/Programs"], component: Header }),
                React.createElement(Route, { exact: true, path: "/", component: HomePage }),
                React.createElement(Route, { exact: true, path: "/courses", component: Courses }),
                React.createElement(Route, { exact: true, path: "/login", component: Login }),
                React.createElement(Route, { exact: true, path: "/events", component: Scheduler }),
                React.createElement(Route, { exact: true, path: "/coursepreview", component: ViewCourse }),
                // React.createElement(Route, { exact: true, path: "/coursepreview/1", component: ViewCourse1 }),
                React.createElement(Route, { exact: true, path: "/quiz", component: Quiz }),
                React.createElement(Route, { exact: true, path: "/viewquiz", component: Viewquiz }),
                React.createElement(Route, { exact: true, path: ["/signup", "/applycourse"], component: signup }),
                React.createElement(Route, { exact: true, path: "/student", component: Students }),
                React.createElement(Route, { exact: true, path: "/dashboard", component: Dashboard }),
                React.createElement(Route, { exact: true, path: "/professional", component: Professionals }),
                React.createElement(Route, { exact: true, path: "/companies", component: Companies }),
                React.createElement(Route, { exact: true, path: "/aitahack", component: Aitahack }),
                React.createElement(Route, { exact: true, path: "/engine", component: Aitaengine }),
                React.createElement(Route, { exact: true, path: "/contact-us", component: Contact }),
                React.createElement(Route, { exact: true, path: "/about", component: About }),
                React.createElement(Route, { exact: true, path: "/partners", component: Partner }),
                React.createElement(Route, { exact: true, path: "/community", component: Community }),
                React.createElement(Route, { exact: true, path: "/Programs", component: Programs }),
                React.createElement(Route, { exact: true, path: "/pdfviewer", component: PdfViewer }),
                React.createElement(Route, { exact: true, path: "/audioplayer", component: AudioPlayer }),
                React.createElement(Route, { exact: true, path: "/textviewer", component: TextViewer }),
                React.createElement(Route, { exact: true, path: "/videoplayer", component: VideoPlayer }),
                React.createElement(Route, { exact: true, path: "/coursetrailer", component: Trailer }),
                React.createElement(Route, { exact: true, path: "/wordviewer", component: WordViewer }),
                React.createElement(Route, { exact: true, path: "/payment", component: Payment }),
                React.createElement(Route, { exact: true, path: "/userprograms", component: UserPrograms }),
                React.createElement(Route, { exact: true, path: "/myprofile", component: Myprofile }),
                React.createElement(Route, { exact: true, path: "/assignment", component: Assignment }),
                React.createElement(Route, { exact: true, path: "/mail", component: Mail }),
                React.createElement(Route, { exact: true, path: ["/", "/student", "/professional", "/companies", "/aitahack", "/engine", "/contact-us", "/about", "/partners", "/community", "/Programs"], component: Footer }))))));
ReactDOM.render(routing, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();