import React from "react";
import Config from "../../components/Global";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import "./Dashboard.scss";
import StudentDashboard from "../../JScomponents/StudentDashboard";
import Service from "../../service/service";
import { Grid, Typography } from "@mui/material";
class Dashboard extends React.Component {
    showSideNavBar(value) {
        this.setState({ sidenavValue: value });
        this.setState(this.state);
    }
    constructor(props) {
        super(props);
        this.user = {};
        
        this.state = {
            sidenavValue: ''
        };
        if (Config.getClaims() !== undefined) {
            new Service().getUserByEmail(Config.getClaims().email).then(res => {
                this.user = res.data;
                this.setState(this.user);
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            props.history.push("/");
        }
    }
    render() {
        return (React.createElement("main", null,
            React.createElement(AdminNavbar, { menu: "dashboard", navbar: this.state.sidenavValue }),
            React.createElement("div", { className: "container", style: { paddingTop: "10px", paddingLeft: '74px' } },
                React.createElement("div", { className: "col-12 col-md-9 col-xl-10" },
                    React.createElement("div", { className: "dashboard-content" },
                        React.createElement(Grid, { container: true, columns: 12 },
                            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, lg: 12, sx: {
                                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                                } },
                                React.createElement(Typography, { variant: "button", sx: { lineHeight: 1.5, fontSize: "1.55rem", fontWeight: 400 } }, "Dashboard")),
                            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, lg: 12, sx: {
                                    mb: 2,
                                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                                } },
                                )),
                        React.createElement("div", { className: "d-flex align-items-center" },
                            React.createElement("div", { className: "dashboard-sidenavBtn d-md-none", onClick: () => this.showSideNavBar("show") },
                                React.createElement("span", { className: "dashboard-sidenavBtn__item" }),
                                React.createElement("span", { className: "dashboard-sidenavBtn__item" }),
                                React.createElement("span", { className: "dashboard-sidenavBtn__item" })),
                            React.createElement(Typography, { variant: "button", sx: { fontSize: '.9375rem', fontWeight: 600, letterSpacing: "2px", fontFamily: "Exo 2,Helvetica Neue,Arial,sans-serif", mb: 2, mt: 1 } },
                                "Hello,",
                                React.createElement("span", { style: { color: "#fa5a78" } },
                                    this.user.first_name,
                                    " ",
                                    this.user.last_name)))))),
            React.createElement("div", { style: { marginLeft: '5rem' } },
                React.createElement(StudentDashboard, null))));
    }
}
export default Dashboard;