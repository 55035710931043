class GlobalConfig {
    constructor() {
        this.accessToken = null;
    }
    setToken(token) {
        this.accessToken = token;
        sessionStorage.setItem("token", JSON.stringify(token));
    }
    getToken() {
        if (this.accessToken == null) {
            let token = sessionStorage.getItem("token");
            if (token != null)
                this.accessToken = JSON.parse(token);
            else {
                this.accessToken = {};
            }
        }
        return this.accessToken.idToken;
    }
    getClaims() {
        if (this.accessToken == null) {
            let token = sessionStorage.getItem("token");
            if (token != null)
                this.accessToken = JSON.parse(token);
            else {
                this.accessToken = {};
            }
        }
        return this.accessToken.idTokenClaims;
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
}
GlobalConfig._instance = new GlobalConfig();
export { GlobalConfig };
let Config = GlobalConfig.Instance;
export default Config;