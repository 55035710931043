import axios from "axios";
import Config from "../components/Global";
import { AITADB_URL } from "../JScomponents/constants/global";
import { QUIZDB_URL } from "../JScomponents/constants/global";
axios.defaults.baseURL = AITADB_URL + "/";
export default class Service {
    constructor() {
        this.handleErrResponse = () => {
            if (sessionStorage.getItem("userDetails") != null)
                sessionStorage.removeItem("userDetails");
            if (sessionStorage.getItem("token") != null)
                sessionStorage.removeItem("token");
        };
    }
    async getCourseList() {
        const response = await axios({
            url: "/course",
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + Config.getToken(),
            }
        });
        return response.data;
    }
    async getsinglecourse(id) {
        const response = await axios({
            url: "/course/" + id,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + Config.getToken(),
            }
        });
        return response.data;
    }
    async getcontentById(id) {
        const response = await axios({
            url: "/course/" + id + "/content",
            method: "get",
            headers: {
                'Authorization': 'Bearer ' + Config.getToken(),
            }
        });
        console.log("response", response.data.data);
        return response.data;
    }
    async getcontentfileById(id) {
        const response = await axios({
            url: "/course/content/" + id,
            method: "get",
            headers: {
                'Authorization': 'Bearer ' + Config.getToken(),
            }
        });
        return response;
    }
    async getsectionpdfById(id) {
        return {
            url: "/sections/asset/" + id,
            option: {
                method: 'get',
                headers: new Headers({
                    'Authorization': `Bearer ${Config.getToken()}`
                }),
            }
        };
    }
    async addSectionTitle(data) {
        const response = await axios({
            url: '/sections',
            method: 'POST',
            data: data,
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + Config.getToken()
            }
        });
        return response.data;
    }
    async getsectionforcourse(id) {
        const response = await axios({
            url: "/sections/" + id,
            method: "get",
            headers: {
                'Authorization': 'Bearer ' + Config.getToken(),
            }
        });
        return response;
    }
    async getsubsectionforcourse(id) {
        const response = await axios({
            url: "/sectionsasset/" + id,
            method: "get",
            headers: {
                'Authorization': 'Bearer ' + Config.getToken(),
            }
        });
        return response;
    }
    async getUSer() {
        return await axios({
            url: "/student/profiles",
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + Config.getToken(),
            }
        });
    }
    getImageAsset(assetId) {
        return axios.defaults.baseURL + "imageasset/" + assetId;
    }
    getDocumentPDF(assetId) {
        return axios.defaults.baseURL + "pdfasset/" + assetId;
    }
    async getProgramDetails() {
        const response = await axios({
            url: "/programs",
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + Config.getToken(),
            }
        });
        return response.data;
    }
    async signupUser(userDetails) {
        const signup = await axios({
            url: "/student/register",
            method: "POST",
            data: userDetails
        });
        return signup.data;
    }
    async sendVerificationCode(mailId, name) {
        const sendVerificationCode = await axios({
            url: "/student/email_code",
            params: {
                "email": mailId,
                "name": name
            }
        });
        return sendVerificationCode.status;
    }
    async verifyVerificationCode(mailId, code) {
        const sendVerificationCode = await axios({
            url: "/student/verifyemail",
            params: {
                "email": mailId,
                "code": code
            }
        });
        return sendVerificationCode.status;
    }
    checkForDataExistance(data, type) {
        return axios.get("data/existance?userData=" + data + "&type=" + type);
    }
    getCategories() {
        return [
            {
                name: "All Programs",
                value: "All_PROGRAMS"
            },
            {
                name: "MIDSP - Most in Demand Skill Programs",
                value: "MOST_IN_DEMAND_SKILL_PROGRAMS"
            },
            {
                name: "JRP - Job Readiness Program",
                value: "JOB_READINESS_PROGRAMS"
            },
            {
                name: "JPP - Job Placement Program",
                value: "JOB_PLACEMENT_PROGRAMS"
            },
            {
                name: "CET - Competitive Exam Training",
                value: "COMPETITIVE_EXAM_TRAINING"
            },
            {
                name: "ES - Employment Skills ",
                value: "EMPLOYEMENT_SKILLS"
            }
        ];
    }
    async addPaymentDetails(data) {
        return await axios({
            url: "/add/payment",
            method: "POST",
            data: data
        });
    }
    async addUser(data) {
        return await axios({
            url: "/student/register",
            method: "POST",
            data: data
        });
    }
    // async getUserByEmail(email) {
    //     const response = await axios({
    //         url: "/user/profile?email=" + email,
    //         method: "GET",
    //         headers: {
    //             'Authorization': 'Bearer ' + Config.getToken(),
    //         }
    //     });
    //     return response.data;
    // }

    async getUserByEmail(email) {
        const response = await axios({
            url: QUIZDB_URL + '/user/profile/' + email,
            method: 'GET',
        });
        console.log("response", response);
        return response.data;
    }
    async updateUser(userDetails) {
        const response = await axios({
            url: "/student/profile",
            method: "PUT",
            data: userDetails
        });
        return response.data;
    }
    async getProgramDetailsById(programId) {
        const response = await axios({
            url: "/program/" + programId,
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + Config.getToken(),
            }
        });
        return response.data;
    }
    getCourseThumbnail(assetId) {
        return {
            url: axios.defaults.baseURL + "course/thumbnail/" + assetId,
            option: {
                method: 'get',
                headers: new Headers({
                    'Authorization': `Bearer ${Config.getToken()}`
                }),
            }
        };
    }
    async getUserNonRegPrograms(userId) {
        const response = await axios({
            url: axios.defaults.baseURL + "user/" + userId + "/programs/nonreg",
            headers: {
                'Authorization': 'Bearer ' + Config.getToken(),
            }
        });
        return response.data;
    }

    async getUserProfile() {
        // const response = await axios({
        //   url: "/userprofile",
        //   method: "GET",
        //   headers: {
        //     Authorization: "Bearer " + Config.getToken(),
        //   },
        // });
        const userDetails = sessionStorage.getItem('userDetails');
        const userEmail = JSON.parse(userDetails).data.email;
        const userName = JSON.parse(userDetails).data.first_name + ' ' + JSON.parse(userDetails).data.last_name;
        
        const response = {
            data: {
              name: userName,
              emailid: userEmail
            }
          };
        
        console.log('service', response);
        return response;
      }
}