import React, { useState } from "react";
import AzureAuthenticationButton from "../../azure/azure-authentication-component";
function App() {
    // current authenticated user
    const [currentUser, setCurrentUser] = useState();
    console.log("details", currentUser);
    // authentication callback
    const onAuthenticated = async (userAccountInfo) => {
        setCurrentUser(userAccountInfo);
    };
    // Render JSON data in readable format
    const PrettyPrintJson = ({ data }) => {
        return (React.createElement("div", null,
            React.createElement("pre", null, JSON.stringify(data, null, 2))));
    };
    return (React.createElement("div", { id: "App" },
        React.createElement(AzureAuthenticationButton, { onAuthenticated: onAuthenticated }),
        currentUser && (React.createElement("div", null,
            React.createElement(PrettyPrintJson, { data: currentUser })))));
}
export default App;