import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


const drawerWidth = 240;
const theme = createTheme({


    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#ffffff',
                    color: 'rgba(255, 255, 255, 1)',
                    boxSizing: "border-box",
                    width: drawerWidth
                }
            }
        },
        input: {
            color: 'rgba(0, 0, 0, 0.6)',
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#f95877'
                }
            }

        },
        MuiInputBase: {
            input: {
              background: "#fff",
              color: 'rgba(0, 0, 0, 0.6)',
            },
          },
        MuiTab: {
            styleOverrides: {

                root: {
                    "&:hover": {
                        
                        color:'#f83f63'
                    },
                    color:'#f95877'
                },
                
            }

        },

        MuiButton: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                backgroundColor:"#f95877" ,
                color: 'white',
                ':hover': {
                  backgroundColor: '#f83f63',
                  color: 'white', },
                // boxShadow: 'none',
                // textTransform: 'none',
                // fontSize: 16,
                // padding: '6px 12px',
                // border: '1px solid',
                // lineHeight: 1.5,
                // backgroundColor: 'pink',
                
                // fontFamily: [
                 
                //   '"Segoe UI Symbol"',
                // ].join(','),
                // '&:hover': {
                //   backgroundColor: 'red',
                //   borderColor: 'green',
                //   boxShadow: 'grey',
                // },
                // '&:active': {
                //   boxShadow: '',
                //   backgroundColor: '#0062cc',
                //   borderColor: '#005cbf',
                // },
                // '&:focus': {
                //   boxShadow: 'purple',
                // },
              },
              
            },
          },



    },
    palette: {
        // primary: {
        //     main: '#ffffff',
        //     // main:"#f6f8ff",
        //     light: '#427b9c',
        //     dark: '#002743',
        // },
        secondary: {
            main: '#b73d6a',
            light: '#ed6e98',
            dark: '#830040',
        },
        text: {
            primary: 'rgba(0,0,0,0.6)',
            secondary: 'rgba(0,0,0)',
        },
        error: {
            main: red.A400,
        },
    },
    typography: {

        fontFamily: [
            "'Poppins' !important"
        ],
        
        h5: {
            color: 'rgba(0, 39, 67, 1)',
            fontSize: '36px',
            fontWeight: '600'
        },
        
        accordionheader:{

            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '14px',
            fontWeight: '400'
        },
        submitbtt:{
            fontSize: '16px',
            fontWeight: '600',
            color: 'rgba(0, 0, 0, 0.87)',

        },

    }
});

export default theme;