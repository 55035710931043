import React from "react";
import "./Students.scss";
import { Link } from "react-router-dom";
import UpcomingPrograms from "./UpcomingPrograms";
class Students extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (React.createElement("main", null,
            React.createElement("section", { className: "section-student-banner sec-pd bgimage" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row content-visibility align-items-center" },
                        React.createElement("div", { className: "col-12 col-md-6 col-lg-5" },
                            React.createElement("div", { className: "banner-content" },
                                React.createElement("h6", { className: "banner-content__title fw-700 text-5d5d" }, "Achieve 1.5 to 5 times Pay"),
                                React.createElement("h1", { className: "banner-content__head fw-700 text-1818" }, "Get Ready and Hired for Top AI Careers")),
                            React.createElement("div", { className: "banner-info" },
                                React.createElement("h6", { className: "banner-info__title text-1615 fw-600" }, "Made for Top Employers"),
                                React.createElement("p", { className: "banner-info__disc fnt-18 text-1818 fw-400 text-justify" }, "All Students pursuing higher education in Colleges and Universities with Science, Technology, Engineering, Mathematics, Statistics, Computer Science, Commerce, Economics, and Management background can build careers in Artificial Intelligence at Aitahub.")),
                            React.createElement("div", { className: "banner-btn" },
                                React.createElement(Link, { className: "d-inline-block home-slider-btn__item btn btn--skew", to: "/contact-us" }, "Apply Now"),
                                React.createElement(Link, { className: "d-inline-block home-slider-btn__item btn btn--skewBrd", to: "/login" },
                                    "Request Demo",
                                    React.createElement("img", { className: "img-brd", src: "assets/images/student/brd2.png" }))))))),
            React.createElement("section", { className: "sec-pd sec-pd--top section-student-jobs", style: { background: "#f6f8ff" } },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: "jobs-head" },
                                React.createElement("h2", { className: "fnt-32 text-1615 fw-600" }, "Top 10 AI Powered Careers Designed for You")),
                            React.createElement("div", { className: "job-type" },
                                React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Tech Jobs"),
                                React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p1.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Data Analyst")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p2.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Data Engineer")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p3.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Machine Learning Engineer")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/p5.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Data Scientist")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/cds.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Citizen Data Scientist")))))),
                            React.createElement("div", { className: "job-type" },
                                React.createElement("h6", { className: "job-type__head fnt-20 text1615 fw-600" }, "Business Jobs"),
                                React.createElement("ul", { className: "careers-list careers-list--jobs d-flex flex-wrap clearfix mb-0" },
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr1.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Business Analyst")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr2.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Management Consultant")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/ca.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Chartered Accountant")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr4.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Investment Banker")))),
                                    React.createElement("li", { className: "careers-list__item float-left" },
                                        React.createElement(Link, { className: "d-inline-flex align-items-center clearfix", to: "#" },
                                            React.createElement("div", { className: "careers-icon float-left" },
                                                React.createElement("img", { className: "img-fluid", src: "assets/images/programs/pr5.svg" })),
                                            React.createElement("div", { className: "careers-title float-left" },
                                                React.createElement("h4", { className: "mb-0 fw-500" }, "Marketing Manager")))))))))),
            React.createElement(UpcomingPrograms, null)));
    }
}
export default Students;
