import * as React from "react";
import { Button,Divider,Grid,IconButton,Stack,TextField,Typography,} from "@mui/material";
import { useParams } from "react-router-dom";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Avatar from "@mui/material/Avatar";
import ReplyIcon from '@mui/icons-material/Reply';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from "moment";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ReactHtmlParser from "react-html-parser";






export default function ViewMail(props) {
console.log("viewmail",props)

  

  const [ViewMailData] = React.useState(props.ViewMailData);

  console.log("ViewMailData", ViewMailData);

  let { id } = useParams();
  console.log(id);

  const BacktoMail = (event) => {
    props.handleInboxFlag(true)
    props.handleViewMailFlag(false)
    // navigate.push("/mail" );
    // console.log("index");
  };

  return (
    <>
      <Grid
        item
        md={12}
        xs={12}
        sm={12}
        lg={12}
        sx={{ padding: "13.5px", borderBottom: 1, borderColor: "#e8e6e6" }}
      >

        {/* View Mail Icon */}
        <Grid container columns = {12} >

            <Grid item md = {7}>
                {/* from to */}
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
            >
            <IconButton  onClick = {BacktoMail}>
                <ChevronLeftIcon /> 
            </IconButton>
            <Avatar
                  alt="Remy Sharp"
                  src={"https://randomuser.me/api/portraits/med/men/"+ 32 + ".jpg"}
                />

                <Grid container columns = {12} >

                <Grid item >
                <Typography variant="subtitle2" sx = {{fontSize:15,fontWeight:600,}}>{ViewMailData.sendername }</Typography>
                </Grid>

                <Grid item  sx = {{marginLeft:1}}>
                    <Typography variant="caption" sx = {{fontSize:13,color:"#818e9a" }}>{"  <"}{ViewMailData.sender}{">"}</Typography>
                </Grid>

                <Grid item md = {12}>
                <Typography variant="caption" sx = {{fontSize:13,color:"#818e9a" }}>To: {ViewMailData.reciever}</Typography>

                </Grid>
            </Grid>

            
                
            </Stack>
            
            
            
                
                </Grid>
            <Grid item md = {5}>
                {/* created date */}
                <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
            >
<div>
                <Typography sx = {{fontSize:13,color:"#818e9a" }}variant = "caption">{  moment(ViewMailData.createddate).format("D/M/YYYY  HH:mm ss:SS A")}</Typography>
                <IconButton><ReplyIcon/></IconButton>
                <IconButton><MoreVertIcon/></IconButton>
                </div>
            </Stack>
                </Grid>

        </Grid>



      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        sm={12}
        lg={12}
        // sx={{ padding: 2, borderBottom: 0, borderColor: "#e8e6e6" }}
      >
        {/* View Mail */}

        <Grid container rows={2} >
          <Grid item md={12} sx={{ marginTop: 1, height: 350, padding: 8 }}>
            <Typography variant="h4"> {ViewMailData.subject}</Typography>

            <Typography variant="body1" sx = {{pl:.5}}>
              {ReactHtmlParser(ViewMailData.message)}
              </Typography>
          </Grid>

          <Grid item md={12}>
            <Divider />
            <TextField
              multiline
              fullWidth
              rows={2}
              placeholder="Type a message"

             
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                  //   color: "#d5d5d5",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
            ></TextField>
          </Grid>
        </Grid>

        <Grid container columns={12} sx={{mb:1 }}>
          <Grid item md={9}></Grid>
          <Grid item md={3}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <IconButton>
                <AddPhotoAlternateIcon />
              </IconButton>
              <IconButton>
                <AttachFileIcon />
              </IconButton>
              <Button
                style={{
                  borderRadius: 8,
                  // backgroundColor: "rgb(0, 171, 85)",
                  padding: "3px 16px",
                  fontSize: "18px",
                  textTransform: "none",
                  color: "White",
                }}
                variant="contained"
              >
                Send
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
