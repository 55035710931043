import React from "react";
import "./Community.scss";
import { Link } from "react-router-dom";
class Community extends React.Component {
    render() {
        return (React.createElement("section", { className: "sec-pd sec-pd--withBackground section-home-community" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("ul", { className: "community-list mb-0 row", style: { padding: "0" } },
                            React.createElement("li", { className: "community-list__item col-12 col-md-6" },
                                React.createElement("div", { className: "community" },
                                    React.createElement("div", { className: "community-img p-2" },
                                        React.createElement("img", { className: "img-fluid", src: "assets/images/home/cm1.png" })),
                                    React.createElement("div", { className: "community-body pb-0" },
                                        React.createElement("div", { className: "community-body__head" },
                                            React.createElement("h3", { className: "fnt-32 fw-700 text-1818" }, "Transform Institutions to Skills Based Education")),
                                        React.createElement("div", { className: "point-list" },
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "High JOB Placement"),
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Right Job and Right Pay for Students"),
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Access to Global Corporate Network"),
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Surviving Job Market Disruption"),
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Brand Equity"),
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Competitive Edge"))),
                                    React.createElement("div", { className: "community-footer" },
                                        React.createElement(Link, { to: "partners", className: "btn btn--skew d-inline-block" }, "Read More")))),
                            React.createElement("li", { className: "community-list__item col-12 col-md-6" },
                                React.createElement("div", { className: "community" },
                                    React.createElement("div", { className: "community-img p-2" },
                                        React.createElement("img", { className: "img-fluid", src: "assets/images/home/cm2.png" })),
                                    React.createElement("div", { className: "community-body pb-0" },
                                        React.createElement("div", { className: "community-body__head" },
                                            React.createElement("h3", { className: "fnt-32 fw-700 text-1818" }, "AI Careers for Anyone, Anywhere in India")),
                                        React.createElement("div", { className: "community-body__disc" },
                                            React.createElement("p", { className: "fnt-14 fw-400 text-5d5d" }, "Making AI accessible to all \u2013 Skill or Fund Empowering the Powerless")),
                                        React.createElement("div", { className: "point-list" },
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Opportunity for Every Graduate to Build High Growth Careers"),
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "Building Tools, Resources, Mentors and Solutions for every one"),
                                            React.createElement("p", { className: "point-list__item fnt-18 text-1818 fw-500" }, "End to End Career Ecosystem for learners with age of 10\u2019s, 20\u2019s to 30\u2019s"))),
                                    React.createElement("div", { className: "community-footer" },
                                        React.createElement(Link, { to: "/community", className: "btn btn--skew  d-inline-block" }, "Find Community"))))))))));
    }
}
export default Community;