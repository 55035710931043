import React, { useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from '@mui/material/Pagination';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import AdminNavbar from "../../components/Dashboard/AdminNavbar";
import "../../pages/Dashboard/Dashboard.scss";
import Breadcrums from "../Breadcrums/Breadcrums";
import Quizservice from "../Quiz/quizservice";
import axios from 'axios';
import { QUIZDB_URL } from "../constants/global";



function Viewquiz() {
  const history = useHistory();
  const location = useLocation();

  const [courseId, setCourseId] = React.useState();
  const [expanded, setExpanded] = React.useState(null);
  const [quizdata, setquizdata] = React.useState([]);
  const [assignmentStatus] = React.useState();
  const[Loading,setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(5);
  const [filteredData, setFilteredData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');

  
 console.log(Loading);

  var userDetails  = sessionStorage.getItem("userDetails");
  const email = JSON.parse(userDetails).data.email

  const breadcrums = [
    {
      page: "HOME",
      route: "/dashboard"
    },
    {
      page: "My Courses",
      route: "/Courses"
    },
    {
      page: "Course Preview",
      route: "/coursepreview"
    },
    {
      page: "Viewquiz",
      route: "/viewquiz"
    }
    
  ]

  // const breadcrums = ["Home", "Assignment"];



  
 
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;




  

  useEffect(() => {
    setLoading(true);
    if (location.state !== undefined) {
      
      const { courseid } = location.state;
      setCourseId(courseid)
      // new Quizservice().getassignment(courseitem.id).then((response) => {
      new Quizservice().getQuiz(courseid).then((response) => {
        setquizdata(response);
        console.log("quizdata",response)
        // setquizid(response.data[0].id);
        setLoading(false);

      });
      // AssignmentStatusHandler()
 
    }
  }, [location.state]);

  useEffect(() => {
    filterData();
  }, [quizdata, searchTerm]);

  // Get the paginated data based on the current page
  // const paginatedData = quizdata.slice(indexOfFirstItem, indexOfLastItem);
  const paginatedData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterData = () => {
    const filtered = quizdata.filter((data) =>
      data.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset current page to 1 when search term changes
  };

  const handleChange = (index) => {
    setExpanded(index === expanded ? null : index);
  };
  
  


  

  

  
    

    

  const handlestartquiz = (id,title,score) => {
    var data = new FormData();
    data.append('useremail', email);
    data.append('quizid', id);
  
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: QUIZDB_URL+'/quickCheck',
      data: data
    };
  
    axios(config)
      .then(function (response) {
        history.push(`/quiz?quickCheckId=${response.data.quickCheckId}&quizid=${id}&courseId=${courseId}`,{title,score});
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  
  
  

  return (
    <main>
      <AdminNavbar menu="mycourse" navbar="" />

      <div
        className="container"
        style={{
          paddingBottom: "26px",
          paddingTop: "15px",
          paddingLeft: "54px",
        }}
      >
        <div className="row">
          {/* main grid */}
          <Grid
            container
            minWidth="fit-content"
            display="flex-start"
            sx={{ pt: "2rem" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                marginLeft: "1rem",
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                },
              }}
            >
              <Typography
                variant="button"
                sx={{ lineHeight: 1.5, fontSize: "1.55rem", fontWeight: 400 }}
              >
                Quizzes
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                marginLeft: "1rem",
                mb: 2,
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                },
              }}
            >
              <Breadcrums pages={breadcrums} courseid= {courseId} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                marginLeft: "1rem",
                mb: 2,
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "right",
                  lg: "right",
                },
              }}
            >
              <input type="text" placeholder="Search by name..." value={searchTerm} onChange={handleSearchChange} />
            </Grid>
            
            {quizdata && paginatedData &&
              paginatedData.map((data, id) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    marginLeft: "1rem",
                    mb: 0,
                  }}
                  key={id}
                >
                  <div>
                    <Accordion
                      key={id}
                      expanded={id === expanded}
                      onChange={() => handleChange(id)}
                      sx={{ mb: 3, mt: 1 }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          variant="caption"
                          sx={{ color: "text.secondary", fontWeight: 700 }}
                        >
                          {data.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails key={id}>
                                <Stack
                                  justifyContent="space-between"
                                  alignItems="center"
                                  direction="row"
                                  spacing={2}
                                >
                                  
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "#afb1b3", fontWeight: 800 }}
                                    >
                                      {data.summary}
                                    </Typography>
                                  
                                </Stack>
                              
                          </AccordionDetails>
                          

                      
                          
                        
                  <Grid container sx = {{mt:1,mb:2}}>
                      <Grid align="right" item xs={6} sm={6} md={6} lg={6} >
                        <Link
                        to={{ pathname: '/coursepreview', state: { courseid:courseId } }}
                          style={{ textDecoration: "none", color: "#ffffff" }}
                        >
                          <Button sx={{ mr: 1 }}>Cancel</Button>
                        </Link>
                      </Grid>
                      <Grid  item xs={6} sm={6} md={6} lg={6} >
                        <Button sx={{ 
                            ml:1,
                            color: assignmentStatus ? 'gray':'', 
                            backgroundColor: assignmentStatus ? 'lightgray' : '',
                            '&:hover': {
                              backgroundColor: assignmentStatus ? 'lightgray' : '',
                            },
                          }}
                        
                          onClick={() => handlestartquiz(data.id,data.title,data.score)}
                          >
                          Start
                        </Button>
                      </Grid>
                    </Grid>
                    </Accordion>
                  </div>
                </Grid>
              ))}


          </Grid>

          

        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Pagination
              count={Math.ceil(filteredData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
        </div>
      </div>
    </main>
  );
}

export default Viewquiz;
