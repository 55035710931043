import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import Typography from '@mui/material/Typography';
import { Card, Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getstudentevents } from '../../store/action';
import Moment from 'moment';
import {
  Box,
  Grid
} from "@mui/material";


export default function UserTimeline(email) {
  const user_id = email
  console.log("eventsmaik", user_id.email)
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);

  useEffect(() => {
    dispatch(getstudentevents(user_id.email));
  }, [user_id,dispatch]);

  const events = useSelector((state) => state.Stulist.studentevents);
  const pageCount = Math.ceil(events.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const slicedEvents = events.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Card style={{ borderRadius: ".5rem", border: "1px grey" }}>
        <Timeline>
          {slicedEvents && slicedEvents.map((item, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent sx={{ py: '12px', px: 2 }}>
                <Typography sx={{ mt: 1.5 }}>{Moment(item.date).format('DD-MM-YYYY')}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{ bgcolor: "#f95877" }}>
                  <LaptopMacIcon sx={{ bgcolor: "#f95877" }} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{ m: 'auto 0', fontSize: '1rem' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {item.label}
                <Typography>{item.createdBy}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Card>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}>
          <Pagination
            count={pageCount}
            page={page + 1}
            onChange={handleChangePage}
            color="primary" />
        </Box>
      </Grid>
    </>
  );
}
