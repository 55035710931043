import { Grid } from "@mui/material";
import React from 'react';
import { styled } from '@mui/material/styles';
import StudentHome from './Status';
import ChatIcon from '@mui/icons-material/Chat';
import Fab from '@mui/material/Fab';








const ChatFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: '#4caf50',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#45a049',
  },
}));


const StudentDashboard = () => {

  
  
  var userDetails  = sessionStorage.getItem("userDetails");
  console.log("details",userDetails);
  return (
    <><Grid container columns={12} sx={{ mb: 3 }}>
      <Grid item xs={2} sm={2} md={1} lg={1}></Grid>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        
          <StudentHome />
      </Grid>
      </Grid>
      <ChatFab>
        <ChatIcon />
      </ChatFab>
      </>

  ) 
}

export default StudentDashboard;